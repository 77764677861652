import styled from 'styled-components'

export default styled.div`
  min-height: calc(100% - 4rem);
  margin-left: 3rem;
  margin-right: ${props => props.rightMargin && '3rem'};
  @media (max-width: 768px) {
    margin: 0;
  }
  font-size: 0.85rem;
  line-height: 1.2rem;
  > p {
    margin-bottom: 0.5rem;
    text-align: justify;
  }
`
