import React, {Component} from 'react'
import {connect} from 'react-redux'
import {common} from 'redux/modules'
import PageHeader from 'components/PageHeader'
import Page from 'components/SimplePage'
import {internalRepertoireColor, darkInternalRepertoireColor, red, gray} from '../../../styles/colors'
import styled from 'styled-components'
import {REPERTOIRE_UPLOADER, hasPermission} from 'lib/roles'
import TextInput from 'components/TextInput'
import Button from 'components/Button'
import {TrashIcon} from 'components/Icons'
import moment from 'moment'
import {MobileOnly} from 'components/MediaSwitch'
import {List, ListRow, ListCell, ListCellLink, ListHeader, RowGroup} from 'components/List'

const HeadArea = styled.div`
  display: flex;
  justify-content: space-between;
`

const NewRepertoire = styled.div`
  display: flex;
  margin-top: 1.5rem;
  @media (max-width: 768px) {
    display: block;
  }
  > div:first-child {
    min-width: 20rem;
    padding: 0.5rem;
    box-sizing: border-box;
    @media (max-width: 768px) {
      min-width: 0;
      width: 100%;
    }
  }
`

class RepertoireList extends Component {
  state = {
    newRepertoire: ''
  }

  componentWillMount () {
    if (!this.props.repertoires) {
      this.props.loadRepertoires()
    }
  }

  onAddRepertoire = () => {
    this.props.addRepertoire(this.state.newRepertoire)
    this.setState({newRepertoire: ''})
  }

  onRemoveRepertoire = id => {
    if (window.confirm('Repertoire mit allen dazugehörigen Dateien löschen?')) {
      this.props.removeRepertoire(id)
    }
  }

  render () {
    const {repertoires, permissions, showArchived} = this.props
    const isManager = hasPermission([REPERTOIRE_UPLOADER], permissions)
    return (
      <Page marginRight>
        <HeadArea>
          <PageHeader color={internalRepertoireColor}>Repertoire</PageHeader>
          <Button onClick={this.props.toggleShowArchived}>Archiv {showArchived ? 'aus' : 'ein'}blenden</Button>
        </HeadArea>
        <List>
          <ListHeader>
            <div>Name</div>
            <div>Dateien</div>
            <div>Erstellt</div>
            <div />
          </ListHeader>
          <RowGroup>
            {(repertoires || []).map(repertoire => {
              const color = repertoire.archived ? gray : darkInternalRepertoireColor
              return <ListRow key={repertoire._id}>
                <ListCellLink to={`/internal/repertoire/directory/${repertoire._id}`} title color={color}>
                  {repertoire.name}
                </ListCellLink>
                <ListCellLink to={`/internal/repertoire/directory/${repertoire._id}`} color={color} align='center'>
                  <MobileOnly>Dateien:</MobileOnly><span>{repertoire.files.length}</span>
                </ListCellLink>
                <ListCellLink to={`/internal/repertoire/directory/${repertoire._id}`} color={color} align='center'>
                  <MobileOnly>Erstellt:</MobileOnly><span>{moment(repertoire.created).format('MM / YYYY')}</span>
                </ListCellLink>
                {isManager && <ListCell controls align='right' color={color}>
                  <TrashIcon size='1.5rem' fill={red} onClick={() => this.onRemoveRepertoire(repertoire._id)} />
                </ListCell>}
              </ListRow>
            })}
          </RowGroup>
        </List>
        {isManager &&
          <NewRepertoire>
            <TextInput
              value={this.state.newRepertoire}
              onChange={e => this.setState({newRepertoire: e.target.value})}
              plain
              placeholder='Neue Repertoire-Gruppe' />
            <Button
              disabled={!this.state.newRepertoire.trim()}
              onClick={this.onAddRepertoire}>Anlegen</Button>
          </NewRepertoire>
        }
      </Page>
    )
  }
}
export default connect(
  state => ({
    repertoires: state.common.repertoires && state.common.repertoires.filter(r => state.common.showArchived || !r.archived),
    permissions: state.common.authenticated.roles,
    showArchived: state.common.showArchived
  }),
  dispatch => ({
    loadRepertoires: () => dispatch(common.actions.loadRepertoires()),
    loadUsers: () => dispatch(common.actions.loadUsers()),
    addRepertoire: name => dispatch(common.actions.addRepertoire(name)),
    removeRepertoire: id => dispatch(common.actions.removeRepertoire(id)),
    toggleShowArchived: () => dispatch(common.actions.toggleShowArchived())
  })
)(RepertoireList)
