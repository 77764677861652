import React, {Component} from 'react'
import {connect} from 'react-redux'
import {TransitionMotion, spring} from 'react-motion'
import styled from 'styled-components'
import Button from 'components/Button'
import {actions} from 'redux/ducks/dialog'

const Container = styled.div`
  position:absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2000;
  overflow: hidden;
`

const MotionWrap = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Content = styled.div`
  background: white;
  border-radius: 2rem;
  box-sizing: border-box;
  max-width: 380px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
`

const Message = styled.div`
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 1rem;
`

const Buttons = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  > div {
    min-width: 10rem;
  }
`

class Dialog extends Component {
  willEnter = () => ({
    opacity: 0,
    bgAlpha: 0
  })

  willLeave = () => ({
    opacity: spring(0),
    bgAlpha: spring(0)
  })

  render () {
    const {dialog, lastDialog, dismiss} = this.props
    const data = dialog || lastDialog
    return (
      <TransitionMotion
        willEnter={this.willEnter}
        willLeave={this.willLeave}
        styles={dialog ? [{key: 'transition', style: {opacity: spring(1), bgAlpha: spring(0.4)}}] : []}>
        {interpolatedStyles => {
          if (!interpolatedStyles.length) return null
          const hasButtons = data && (data.get('reload') || data.get('dismiss'))
          return (
            <Container style={{backgroundColor: `rgba(0, 0, 0, ${interpolatedStyles[0].style.bgAlpha})`}}>
              <MotionWrap style={{opacity: interpolatedStyles[0].style.opacity}} onClick={() => data.get('dismiss') && dismiss()}>
                <Content onClick={e => e.stopPropagation()}>
                  <Message>{data && data.get('message')}</Message>
                  {hasButtons &&
                    <Buttons>
                      {data.get('reload') && <Button borderColor='black' onClick={() => window.location.reload()}>Neu laden</Button>}
                      {data.get('dismiss') && <Button borderColor='black' onClick={dismiss}>Okay</Button>}
                    </Buttons>
                  }
                </Content>
              </MotionWrap>
            </Container>
          )
        }}
      </TransitionMotion>
    )
  }
}

export default connect(
  state => ({
    dialog: state.dialog.data,
    lastDialog: state.dialog.bup
  }),
  dispatch => ({
    dismiss: () => dispatch(actions.dismiss())
  })
)(Dialog)
