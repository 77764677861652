import {createSelector} from 'reselect'

const getUsers = state => state.common.users
const getChatRooms = state => state.chat.chatRooms
const getActiveRoomId = state => state.chat.activeRoomId

export default createSelector(
  [getUsers, getChatRooms, getActiveRoomId],
  (users, chatRooms, id) => {
    const room = chatRooms.get(id)
    if (!users || !room) return []
    return [
      'alle',
      ...users
        .filter(u => u.chatName && (room.get('type') === 'public' || room.get('users').includes(u._id)))
        .map(u => u.chatName)
        .sort()
    ]
  }
)
