import React from 'react'
import styled from 'styled-components'
import Page from 'components/SimplePage'
import PageHeader from 'components/PageHeader'
import ChatConnect from 'components/ChatConnect'
import Text from 'components/Text'
import {homeColor} from 'styles/colors'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {Map} from 'immutable'
import moment from 'moment'

const MailLink = styled.a`
  color: ${homeColor};
`

const ChatUnread = styled.div`
  margin: 0.2rem 0;
`

const ChatLink = styled(Link)`
  color: ${homeColor};
  text-decoration: underline;
`

const getEventTypeLabel = type => {
  if (type === 'trip') return 'Chorfahrten / Ausflüge'
  if (type === 'concert') return 'Konzerte / Sonderproben'
  return 'Proben'
}

const Internal = ({connected, chatRooms, undecidedEvents}) => {
  const chatLoaded = connected && chatRooms.size
  const unread = chatRooms.valueSeq().map(room => Map({unread: room.get('unread') || 0, id: room.get('_id'), name: room.get('name')})).filter(room => room.get('unread'))
  const countUnread = unread.reduce((sum, val) => sum + val.get('unread'), 0)
  return <Page>
    <PageHeader color={homeColor}>Nur für Mitglieder</PageHeader>
    <Text>
      Willkommen im internen geschützten Bereich der Moving-Colours Webseite.
    </Text>
    <div>
      <PageHeader color={homeColor}>Fehlende Termin-Eintragungen</PageHeader>
      <Text>
        {undecidedEvents.length === 0 && '--- keine ---'}
        {undecidedEvents.map(event => <div key={event._id}>
          {moment(event.date).format('DD. MM. YYYY')} {event.title} →&nbsp;
          <ChatLink to={`/internal/events/${event.type}s`}>{getEventTypeLabel(event.type)}</ChatLink>
        </div>)}
      </Text>
    </div>
    <div>
      <PageHeader color={homeColor}>Chat-Übersicht</PageHeader>
      <Text>
        {
          chatLoaded
            ? countUnread > 0
              ? unread.map(room => <ChatUnread key={room.get('id')}><ChatLink to={`/internal/choir/chat?initRoom=${room.get('name')}`}>{room.get('unread')} ungelesene {room.get('unread') === 1 ? 'Nachricht' : 'Nachrichten'}</ChatLink> im Chat '{room.get('name')}'</ChatUnread>)
              : <span>Keine neuen Nachrichten im <ChatLink to='/internal/choir/chat'>Chat</ChatLink></span>
            : <ChatConnect>Bitte warten...</ChatConnect>
        }
      </Text>
    </div>
    <PageHeader color={homeColor}>Hilfe</PageHeader>
    <Text>
      Bei Problemen, Anregungen und Hinweisen zu Bugs, einfach Mail an <MailLink href='mailto:mc@probst.space'>mc@probst.space</MailLink>
    </Text>
  </Page>
}

export default connect(
  state => ({
    connected: state.chat.connected,
    chatRooms: state.chat.chatRooms,
    permissions: state.common.authenticated.roles,
    undecidedEvents: state.common.undecidedEvents
  })
)(Internal)
