import React, {Component} from 'react'
import Slider from 'react-slick'
import constant from 'lodash/constant'

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1
}

export default class ImageSlider extends Component {
  shouldComponentUpdate = constant(false)

  render () {
    return <Slider {...settings} beforeChange={this.props.beforeChange}>
      <div>
        <img
          width='100%'
          src={require('img/Q1000731_640.jpg')}
          srcSet={`${require('img/Q1000731_1024.jpg')} 1024w,${require('img/Q1000731_640.jpg')} 640w,${require('img/Q1000731_320.jpg')} 320w`}
          sizes='(min-width: 769px) 40vw,80vw'
          alt='' />
      </div>
      <div>
        <img
          width='100%'
          src={require('img/britzer_garten_640.jpg')}
          srcSet={`${require('img/britzer_garten_1024.jpg')} 1024w,${require('img/britzer_garten_640.jpg')} 640w,${require('img/britzer_garten_320.jpg')} 320w`}
          sizes='(min-width: 769px) 40vw,80vw'
          alt='' />
      </div>
      <div>
        <img
          width='100%'
          src={require('img/finger_pointing_640.jpg')}
          srcSet={`${require('img/finger_pointing_1024.jpg')} 1024w,${require('img/finger_pointing_640.jpg')} 640w,${require('img/finger_pointing_320.jpg')} 320w`}
          sizes='(min-width: 769px) 40vw,80vw'
          alt='' />
      </div>
      <div>
        <img
          width='100%'
          src={require('img/017_640.jpg')}
          srcSet={`${require('img/017_1024.jpg')} 1024w,${require('img/017_640.jpg')} 640w,${require('img/017_320.jpg')} 320w`}
          sizes='(min-width: 769px) 40vw,80vw'
          alt='' />
      </div>
      <div>
        <img
          width='100%'
          src={require('img/094_640.jpg')}
          srcSet={`${require('img/094_1024.jpg')} 1024w,${require('img/094_640.jpg')} 640w,${require('img/094_320.jpg')} 320w`}
          sizes='(min-width: 769px) 40vw,80vw'
          alt='' />
      </div>
      <div>
        <img
          width='100%'
          src={require('img/098_640.jpg')}
          srcSet={`${require('img/098_1024.jpg')} 1024w,${require('img/098_640.jpg')} 640w,${require('img/098_320.jpg')} 320w`}
          sizes='(min-width: 769px) 40vw,80vw'
          alt='' />
      </div>
      <div>
        <img
          width='100%'
          src={require('img/111_640.jpg')}
          srcSet={`${require('img/111_1024.jpg')} 1024w,${require('img/111_640.jpg')} 640w,${require('img/111_320.jpg')} 320w`}
          sizes='(min-width: 769px) 40vw,80vw'
          alt='' />
      </div>
      <div>
        <img
          width='100%'
          src={require('img/148_640.jpg')}
          srcSet={`${require('img/148_1024.jpg')} 1024w,${require('img/148_640.jpg')} 640w,${require('img/148_320.jpg')} 320w`}
          sizes='(min-width: 769px) 40vw,80vw'
          alt='' />
      </div>
      <div>
        <img
          width='100%'
          src={require('img/154_640.jpg')}
          srcSet={`${require('img/154_1024.jpg')} 1024w,${require('img/154_640.jpg')} 640w,${require('img/154_320.jpg')} 320w`}
          sizes='(min-width: 769px) 40vw,80vw'
          alt='' />
      </div>
      <div>
        <img
          width='100%'
          src={require('img/image_640.jpg')}
          srcSet={`${require('img/image_1024.jpg')} 1024w,${require('img/image_640.jpg')} 640w,${require('img/image_320.jpg')} 320w`}
          sizes='(min-width: 769px) 40vw,80vw'
          alt='' />
      </div>
    </Slider>
  }
}
