import React from 'react'
import {KeyIcon, MailIcon, UserIcon, UsersIcon, UserAddIcon, SpeechIcon, FilesIcon, TeachMeIcon,
  ScoreIcon, NewspaperIcon, CalendarIcon, EuroIcon} from 'components/Icons'
import {homeColor, ensembleColor, managementColor, mediaColor, contactColor, fontColor,
  internalHomeColor, internalChoirColor, internalRepertoireColor, internalUserColor, internalEventsColor} from 'styles/colors'
import {USER_MANAGER, TICKET_MANAGER, FINANCE_MANAGER} from 'lib/roles'
import Color from 'color'

export const externalMenu = [
  {
    key: 'home',
    label: 'Home',
    links: [],
    color: Color(homeColor).object()
  }, {
    key: 'ensemble',
    label: 'Ensemble',
    color: Color(ensembleColor).object(),
    links: [{
      route: '/ensemble/singers',
      label: 'Sänger:innen',
      imgSrc: require('img/link_ensemble_singers.jpg')
    }, {
      route: '/ensemble/events',
      label: 'Events',
      imgSrc: require('img/link_ensemble_events.jpg')
    }]
  }, {
    key: 'management',
    label: 'Leitung',
    color: Color(managementColor).object(),
    links: [{
      route: '/management/conductor',
      label: 'Künstlerische Leitung',
      imgSrc: require('img/link_management_conductor.jpg')
    }, {
      route: '/management/board',
      label: 'Vorstand',
      imgSrc: require('img/link_management_board.jpg')
    }]
  }, {
    key: 'media',
    label: 'Medien',
    color: Color(mediaColor).object(),
    links: [{
      route: '/media/see',
      label: 'Sehen',
      imgSrc: require('img/link_media_see.jpg')
    }, {
      route: '/media/hear',
      label: 'Hören',
      imgSrc: require('img/link_media_hear.jpg')
    }]
  }, {
    key: 'contact',
    label: 'Kontakt',
    color: Color(contactColor).object(),
    links: [{
      route: '/contact/membership',
      label: 'Mitglied werden',
      imgSrc: require('img/link_contact_membership.jpg')
    }, {
      route: '/contact/board',
      label: 'Kontakt',
      imgSrc: require('img/link_contact_board.jpg')
    }, {
      route: '/contact/imprint',
      label: 'Impressum',
      icon: <NewspaperIcon fill={fontColor} size='1.5rem' />
    }]
  }
]

export const internalMenu = [
  {
    key: 'home',
    label: '',
    links: [],
    color: Color(internalHomeColor).object()
  }, {
    key: 'user',
    label: 'Profil',
    links: [{
      route: '/internal/user/account/self',
      label: 'E-Mail / Passwort ändern',
      icon: <KeyIcon fill={fontColor} size='1.5rem' />
    }, {
      route: '/internal/user/profile/self',
      label: 'Profil bearbeiten',
      icon: <UserIcon fill={fontColor} size='1.5rem' />
    }, {
      route: '/internal/user/contribution_account',
      label: 'Beitragskonto',
      icon: <EuroIcon fill={fontColor} size='1.5rem' />
    }],
    color: Color(internalUserColor).object()
  }, {
    key: 'choir',
    label: 'Chor',
    links: [{
      route: '/internal/choir/users',
      label: 'Chorliste',
      icon: <UsersIcon fill={fontColor} size='1.5rem' />
    }, {
      requiredRoles: [USER_MANAGER],
      route: '/internal/choir/users/add',
      label: 'Benutzer anlegen',
      icon: <UserAddIcon fill={fontColor} size='1.5rem' />
    }, {
      route: '/internal/choir/communication',
      label: 'Verteiler',
      icon: <MailIcon fill={fontColor} size='1.5rem' />
    }, {
      route: '/internal/choir/chat',
      label: 'Chat',
      icon: <SpeechIcon fill={fontColor} size='1.5rem' />
    }, {
      requiredRoles: [FINANCE_MANAGER],
      route: '/internal/choir/accounts',
      label: 'Mitgliedskonten',
      icon: <EuroIcon fill={fontColor} size='1.5rem' />
    }],
    color: Color(internalChoirColor).object()
  }, {
    key: 'repertoire',
    label: 'Repertoire',
    links: [{
      route: '/internal/repertoire/directory',
      label: 'Verzeichnis',
      icon: <FilesIcon fill={fontColor} size='1.5rem' />
    }, {
      route: '/internal/repertoire/teachme',
      label: 'Teach-Me Tracks',
      icon: <TeachMeIcon fill={fontColor} size='1.5rem' />
    }, {
      route: '/internal/repertoire/scores',
      label: 'Noten',
      icon: <ScoreIcon fill={fontColor} size='1.5rem' />
    }],
    color: Color(internalRepertoireColor).object()
  }, {
    key: 'events',
    label: 'Termine',
    links: [{
      route: '/internal/events/current',
      label: 'Nächster Termin',
      icon: <CalendarIcon fill={fontColor} size='1.5rem' />
    }, {
      route: '/internal/events/rehearsals',
      label: 'Probentermine',
      icon: <CalendarIcon fill={fontColor} size='1.5rem' />
    }, {
      route: '/internal/events/concerts',
      label: 'Konzerttermine / Sonderproben',
      icon: <CalendarIcon fill={fontColor} size='1.5rem' />
    }, {
      route: '/internal/events/trips',
      label: 'Chorfahrten / Ausflüge',
      icon: <CalendarIcon fill={fontColor} size='1.5rem' />
    }, {
      requiredRoles: [TICKET_MANAGER],
      route: '/internal/tickets',
      label: 'Kartenverkäufe',
      icon: <CalendarIcon fill={fontColor} size='1.5rem' />
    }],
    color: Color(internalEventsColor).object()
  }
]
