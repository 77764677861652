import React, {Component} from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import includes from 'lodash/includes'
import without from 'lodash/without'
import LabelledTextInput from 'components/LabelledTextInput'
import Button from 'components/Button'
import {red, darkGreen} from 'styles/colors'
import {ADMIN, AUTH_USER, USER_MANAGER, EVENT_MANAGER, EVENT_SUPERVISOR, FINANCE_MANAGER, TICKET_MANAGER, REPERTOIRE_UPLOADER, hasPermission} from 'lib/roles'

const Cols = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: block;
  }
  @media (min-width: 769px) {
    > div:first-child {
      min-width: 24rem;
    }
    > div:last-child {
      min-width: 20rem;
    }
  }
`

const SubLabel = styled.div`
  margin: 1rem 0;
`

const Validation = styled.div`
  color: ${red};
  font-size: 0.7rem;
  height: 1rem;
  text-align: center;
`

const Controls = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Roles = styled.div`
  @media (min-width: 769px) {
    min-width: 20rem;
  }
`

const Text = styled.div`
  @media (min-width: 769px) {
    max-width: 30rem;
  }
`

class AccountForm extends Component {
  state = {
    roles: [AUTH_USER],
    googleLogin: '',
    modified: false
  }

  componentWillMount () {
    this.setState({...pick(this.props.user, ['email', 'googleLogin', '_id', 'roles']), modified: false})
  }

  componentWillReceiveProps (nextProps) {
    this.setState({...pick(nextProps.user, ['email', 'googleLogin', '_id', 'roles']), modified: false})
  }

  getError = () => {
    if (!this.state.email) return 'E-Mail fehlt'
  }

  toggleRole = (role) => {
    if (includes(this.state.roles, role)) {
      this.setState({roles: without(this.state.roles, role), modified: true})
    } else {
      this.setState({roles: this.state.roles.concat(role), modified: true})
    }
  }

  onSubmit = () => {
    const data = pickBy({
      roles: this.state.roles,
      email: this.state.email
    })
    data.googleLogin = this.state.googleLogin || null
    this.props.onSubmit(data)
  }

  onDelete = () => {
    if (!window.confirm('Benutzer mit all seinen Daten dauerhaft löschen?')) return
    this.props.onDelete()
  }

  render () {
    const error = this.getError()
    const {email, googleLogin, roles, modified} = this.state
    const isAdmin = hasPermission([ADMIN], this.props.permissions)
    return (
      <div>
        <Cols>
          <div>
            <SubLabel>Zugangsdaten</SubLabel>
            <LabelledTextInput
              label='E-Mail'
              type='text'
              change={e => this.setState({email: e.target.value, modified: true})}
              value={email} />
            <Text>
              Wenn du dein Passwort ändern möchtest, logge Dich bitte aus und folge dem 'Passwort vergessen' Link unterhalb der Anmeldemaske.
            </Text>
            <LabelledTextInput
              label='Google Login'
              type='text'
              change={e => this.setState({googleLogin: e.target.value, modified: true})}
              value={googleLogin} />
            <Text>Falls du eine Gmail-Adresse besitzt, kannst du sie hier zusätzlich unter 'Google Login' eintragen und brauchst dann
            fortan kein Passwort mehr, weil du den "Mit Google anmelden" Button zur Anmeldung benutzen kannst.</Text>
          </div>
          {isAdmin &&
            <div>
              <Roles>
                <SubLabel>Spezielle Befugnisse</SubLabel>
                {isAdmin && <Button onClick={() => this.toggleRole(ADMIN)} bgColor={includes(roles, ADMIN) && darkGreen}>Administrator</Button>}
                <Button
                  onClick={() => this.toggleRole(USER_MANAGER)}
                  bgColor={includes(roles, USER_MANAGER) && darkGreen}
                  disabled={includes(roles, ADMIN)}>
                  Benuzerverwaltung
                </Button>
                <Button
                  onClick={() => this.toggleRole(EVENT_MANAGER)}
                  bgColor={includes(roles, EVENT_MANAGER) && darkGreen}
                  disabled={includes(roles, ADMIN)}>
                  Terminverwaltung
                </Button>
                <Button
                  onClick={() => this.toggleRole(EVENT_SUPERVISOR)}
                  bgColor={includes(roles, EVENT_SUPERVISOR) && darkGreen}
                  disabled={includes(roles, ADMIN)}>
                  Anwesenheitsbestätigung
                </Button>
                <Button
                  onClick={() => this.toggleRole(FINANCE_MANAGER)}
                  bgColor={includes(roles, FINANCE_MANAGER) && darkGreen}
                  disabled={includes(roles, ADMIN)}>
                  Beitragsverwaltung
                </Button>
                <Button
                  onClick={() => this.toggleRole(TICKET_MANAGER)}
                  bgColor={includes(roles, TICKET_MANAGER) && darkGreen}
                  disabled={includes(roles, ADMIN)}>
                  Kartenverkauf
                </Button>
                <Button
                  onClick={() => this.toggleRole(REPERTOIRE_UPLOADER)}
                  bgColor={includes(roles, REPERTOIRE_UPLOADER) && darkGreen}
                  disabled={includes(roles, ADMIN)}>
                  Repertoire Upload
                </Button>
              </Roles>
            </div>
          }
        </Cols>
        <Controls>
          <Validation>{error}</Validation>
          <Button
            onClick={this.onSubmit}
            disabled={error || !modified}>
            Speichern
          </Button>
          {hasPermission([USER_MANAGER], this.props.permissions) &&
            <Button
              onClick={this.onDelete}>
              Löschen
            </Button>}
        </Controls>
      </div>
    )
  }
}
export default connect(
  state => ({
    permissions: state.common.authenticated.roles
  })
)(AccountForm)
