import React, {Component} from 'react'
import {connect} from 'react-redux'
import {common} from 'redux/modules'
import styled from 'styled-components'
import Page, {Row} from 'components/FourFieldPage'
import Logo from 'components/Logo'
import ImageSlider from './ImageSlider'
import PageHeader from 'components/PageHeader'
import take from 'lodash/take'
import delay from 'lodash/delay'
import sortBy from 'lodash/sortBy'
import {homeColor, fontColor} from 'styles/colors'
import {PlayIcon, PauseIcon, StopIcon, YoutubeIcon, InstagramIcon} from 'components/Icons'
import SmallEventLink from 'components/SmallEventLink'
import moment from 'moment'
import 'moment-duration-format'
import Taglines from 'components/Taglines'

const placeholderImageSrc320 = require('img/Q1000731_320.jpg')
const placeholderImageSrc640 = require('img/Q1000731_640.jpg')
const placeholderImageSrc1024 = require('img/Q1000731_1024.jpg')

const taglineTexts = [
  'Shake it out!',
  'Send me on my way',
  'I’m only human after all',
  'Don’t be afraid of the dark',
  'Gimme, gimme',
  'I’ve got no roots',
  'I bless the rain down in Africa',
  'Don’t put the blame on me',
  'This ain’t Texas',
  'Travel like gypsies in the night',
  'I’m still fact she’s fiction',
  'I know that it’s wrong but I want to have fun',
  'And nothing else matters',
  'I can buy myself flowers',
  'Don’t just walk away',
  'I like digging holes',
  'Wenn ich ein Vöglein wär',
  'Life is ours, we live it our way',
  'Words, a letter and a letter on a string'
]

const taglineStack = sortBy(taglineTexts, Math.random)

const getRandomTagline = () => {
  if (!taglineStack.length) taglineStack.push.apply(taglineStack, sortBy(taglineTexts, Math.random))
  return taglineStack.shift()
}

const StyledLogo = styled(Logo)`
  max-width: 22rem;
  min-height: 10rem;
`
const LogoWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > svg {
    flex: 1;
  }
`
const SliderWrap = styled.div`
  padding: 1.5rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 1rem 0;
  }
  div {
    width: 420px;
    height: 280px;
  }
`
const PlaceholderImage = styled.img`
  display: block;
`
const AudioPlayer = styled.div`
  display: flex;
  margin: 1rem;
`
const AudioButton = styled.div`
  margin: 0 0.3rem;
  opacity: ${props => props.disabled ? '0.5' : '1'};
  pointer-events: ${props => props.disabled ? 'none' : 'all'};
`
const AudioInfo = styled.div`
  font-size: 1.1rem;
  line-height: 2rem;
  margin: 0 0.5rem;
`
const InfoText = styled.div`
  font-style: italic;
`

const SocialMedia = styled.div`
  text-align: right;
  > * {
    margin-left: 8px;
  }
`

class Home extends Component {
  state = {
    imagePlaceholder: true,
    audio1pos: '--:--',
    audio1state: 'stopped',
    audio2pos: '--:--',
    audio2state: 'stopped',
    taglines: [
      {text: getRandomTagline(), initial: true}
    ]
  }

  componentWillMount () {
    if (!this.props.publicEvents) this.props.getPublicEvents()
  }

  componentDidMount () {
    delay(() => this.setState({imagePlaceholder: false}), 1000)
  }

  beforeImageChange = () => {
    this.setState({taglines: [{text: getRandomTagline(this.state.taglines[0].text)}]})
    return true
  }

  onPlayButton = id => {
    if (!this[id]) return
    if (this[id].paused) {
      this[id].play()
      this[id === 'audio1' ? 'audio2' : 'audio1'].pause()
    } else {
      this[id].pause()
    }
  }

  onStopButton = id => {
    if (!this[id]) return
    if (!this[id].paused) this[id].pause()
    this[id].currentTime = 0
    this.setState({[`${id}state`]: 'stopped'})
  }

  onPause = id => {
    if (this.state[`${id}state`] === 'stopped') return
    this.setState({[`${id}state`]: 'paused'})
  }

  onAudioReady = id => {
    if (!this[id]) return
    this.setState({
      [`${id}pos`]: moment.duration(this[id].duration, 's').format('mm:ss', {trim: false})
    })
  }

  updateAudioPos = id => {
    if (!this[id] || this.state[`${id}state`] === 'stopped' || this[id].currentTime < 1) return
    this.setState({[`${id}pos`]: moment.duration(this[id].currentTime, 's').format('mm:ss', {trim: false})})
  }

  render () {
    return (
      <Page>
        <Row>
          <LogoWrap>
            <StyledLogo />
            <Taglines>
              {this.state.taglines.map(tagline =>
                <PageHeader key={tagline.text} noEnter={tagline.initial} color={homeColor} italic centered>{tagline.text}</PageHeader>
              )}
            </Taglines>
          </LogoWrap>
          <SliderWrap>
            {this.state.imagePlaceholder
              ? <PlaceholderImage
                width='100%'
                src={placeholderImageSrc640}
                srcSet={`${placeholderImageSrc1024} 1024w,${placeholderImageSrc640} 640w,${placeholderImageSrc320} 320w`}
                sizes='(min-width: 769px) 40vw,80vw'
                alt='' />
              : <ImageSlider beforeChange={this.beforeImageChange} />
            }
          </SliderWrap>
        </Row>
        <Row grow>
          <div>
            <PageHeader color={homeColor}>On record</PageHeader>
            <AudioPlayer>
              <audio src='/iwlyb.mp3'
                preload='none'
                ref={c => { this.audio1 = c }}
                onPlay={() => this.setState({audio1state: 'playing'})}
                onPause={() => this.onPause('audio1')}
                onCanPlay={() => this.onAudioReady('audio1')}
                onTimeUpdate={() => this.updateAudioPos('audio1')}
              >
                Dein Browser unterstützt leider kein HTML5 audio.
              </audio>
              {['init', 'stopped', 'paused'].includes(this.state.audio1state) &&
                <AudioButton disabled={this.state.audio1state === 'init'} onClick={() => { this.onPlayButton('audio1') }}><PlayIcon fill={fontColor} /></AudioButton>
              }
              {this.state.audio1state === 'playing' &&
                <AudioButton onClick={() => { this.onPlayButton('audio1') }}><PauseIcon clickable fill={fontColor} /></AudioButton>
              }
              <AudioButton onClick={() => this.onStopButton('audio1')} disabled={this.state.audio1state === 'stopped'}><StopIcon clickable fill={fontColor} /></AudioButton>
              <AudioInfo>{this.state.audio1pos}</AudioInfo>
              <AudioInfo>I will love you better (Ausschnitt)</AudioInfo>
            </AudioPlayer>
            <AudioPlayer>
              <audio src='/nem.mp3'
                preload='none'
                ref={c => { this.audio2 = c }}
                onPlay={() => this.setState({audio2state: 'playing'})}
                onPause={() => this.onPause('audio2')}
                onCanPlay={() => this.onAudioReady('audio2')}
                onTimeUpdate={() => this.updateAudioPos('audio2')}
              >
                Dein Browser unterstützt leider kein HTML5 audio.
              </audio>
              {['init', 'stopped', 'paused'].includes(this.state.audio2state) &&
              <AudioButton disabled={this.state.audio2state === 'init'} onClick={() => { this.onPlayButton('audio2') }}><PlayIcon fill={fontColor} /></AudioButton>
              }
              {this.state.audio2state === 'playing' &&
              <AudioButton onClick={() => { this.onPlayButton('audio2') }}><PauseIcon clickable fill={fontColor} /></AudioButton>
              }
              <AudioButton onClick={() => this.onStopButton('audio2')} disabled={this.state.audio2state === 'stopped'}><StopIcon clickable fill={fontColor} /></AudioButton>
              <AudioInfo>{this.state.audio2pos}</AudioInfo>
              <AudioInfo>Nothing else matters (Ausschnitt)</AudioInfo>
            </AudioPlayer>
          </div>
          <div>
            <PageHeader color={homeColor}>Live</PageHeader>
            {this.props.publicEvents && !this.props.publicEvents.length && <InfoText>Neue Konzerte sind in der Planung...</InfoText>}
            {this.props.publicEvents && take(this.props.publicEvents, 2).map(event =>
              <SmallEventLink key={event._id} to='/ensemble/events' event={event} onClick={() => this.props.setCurrentLocation('/ensemble/events')} />
            )}
          </div>
        </Row>
        <Row>
          <div />
          <SocialMedia>
            <a href='https://www.youtube.com/channel/UCRvihmnRnmeC5jnLHIMxEIw'><YoutubeIcon fill={fontColor} /></a>
            <a href='https://www.instagram.com/moving_colours_chor/?hl=de'><InstagramIcon fill={fontColor} /></a>
          </SocialMedia>
        </Row>
      </Page>
    )
  }
}

export default connect(
  state => ({
    publicEvents: state.common.publicEvents
  }),
  dispatch => ({
    setCurrentLocation: path => dispatch(common.actions.setCurrentLocation(path)),
    getPublicEvents: () => dispatch(common.actions.getPublicEvents())
  })
)(Home)
