import React, {Component} from 'react'
import styled from 'styled-components'
import {fontColor} from 'styles/colors'
import {SendIcon, SmileyIcon} from 'components/Icons'
import TextareaAutosize from 'react-textarea-autosize'
import EmojiInput from 'components/EmojiInput'
import MentionSelect from 'components/MentionSelect'
import emojione from 'emojione'
import ImageUpload from 'components/ImageUpload'
import ChatConnect from 'components/ChatConnect'
import get from 'lodash/get'

const Container = styled.div`
  display: flex;
  align-items: center;
  ${props => props.disconnected && `
    background-color: rgba(255, 255, 255, 0.3);
    pointer-events: none;
  `};
`
const MessageInput = styled(TextareaAutosize)`
  flex: 1;
  border: none;
  resize: none;
  font-size: 0.9rem;
  border-bottom: 1px solid ${fontColor};
  background-color: transparent;
  outline: none;
  margin: 0.5rem 1.5rem 0 1rem;
  color: ${fontColor};
`

const setCursor = (el, start, end) => {
  if (el.setSelectionRange !== undefined) {
    el.setSelectionRange(start, end)
  } else {
    el.value = el.value + ''
  }
}

class ChatInput extends Component {
  state = {
    message: '',
    showEmojiInput: false,
    mentions: []
  }

  onSubmit = () => {
    const message = this.state.message
    this.setState({message: ''})
    this.props.onSubmit(message.trim())
  }

  insertEmoji = (shortname) => {
    this.setState({message: this.state.message + shortname + ' ', showEmojiInput: false})
    this.inputRef.focus()
  }

  onChange = e => {
    const message = e.target.value
    const beforeCursor = message.substring(0, this.inputRef.selectionStart)
    const possibleMention = /@(\w*)$/.exec(beforeCursor)
    let mentions = []
    if (possibleMention) {
      mentions = this.props.mentions.filter(m => m.toLowerCase().startsWith(possibleMention[1].toLowerCase()) && m !== possibleMention[1])
    }
    this.setState({message: emojione.shortnameToUnicode(message), mentions, mentionStart: get(possibleMention, 'index'), mentionLength: get(possibleMention, '1.length')})
  }

  insertMention = (mention, suffix) => {
    const {message, mentionStart, mentionLength} = this.state
    const newMessage = `${message.substring(0, mentionStart)}@${mention}${suffix || ''} ${message.substring(mentionStart + mentionLength + 1)}`
    this.setState({mentions: [], message: newMessage})
    setTimeout(() => {
      this.inputRef.focus()
      const cursor = this.inputRef.selectionEnd = mentionStart + mention.length + 2 + (suffix || '').length
      setCursor(this.inputRef, cursor, cursor)
    })
  }

  onKeyDown = e => {
    if (!this.state.mentions.length || (e.key !== 'Tab' && e.key !== 'Enter' && !e.key.match(/^\W$/))) return
    if (!this.state.message.substring(0, this.inputRef.selectionStart).match(/@(\w*)$/)) this.resetMentions()
    e.preventDefault()
    e.stopPropagation()
    this.insertMention(this.state.mentions[0], e.key.match(/^\W$/) && e.key)
  }

  resetMentions = () => this.setState({mentions: []})

  render () {
    return <div>
      {!this.props.connected && <ChatConnect />}
      <Container disconnected={!this.props.connected}>
        {this.state.showEmojiInput && <EmojiInput onClose={() => this.setState({showEmojiInput: false})} onSelect={this.insertEmoji} />}
        <SmileyIcon fill={fontColor} size='1.5rem' onClick={() => this.setState({showEmojiInput: true})} />
        <ImageUpload onImage={this.props.onChatImageUpload} />
        <MessageInput disabled={!this.props.connected} onKeyDown={this.onKeyDown} inputRef={ref => { this.inputRef = ref }} placeholder='Nachricht schreiben' value={this.state.message} onChange={this.onChange} onBlur={this.resetMentions} onFocus={this.resetMentions} />
        <SendIcon onClick={this.onSubmit} disabled={!this.state.message.trim()} fill={fontColor} />
      </Container>
      {this.state.mentions.length > 0 && <MentionSelect mentions={this.state.mentions} onSelect={this.insertMention} />}
    </div>
  }
}
export default ChatInput
