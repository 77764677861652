import styled from 'styled-components'

export default styled.div`
  display: inline-block;
  min-width: 8rem;
  color: ${props => props.color || 'inherit'};
  @media (max-width: 768px) {
    display: block;
  }
`
