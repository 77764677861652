import React from 'react'
import {connect} from 'react-redux'
import {common} from 'redux/modules'
import {Redirect} from 'react-router-dom'
import moment from 'moment'

const CurrentEvent = ({redirectEvent, loadEvents}) => {
  if (!redirectEvent) {
    loadEvents()
    return <div>Bitte warten...</div>
  }
  return <Redirect to={`/internal/events/${redirectEvent._id}`} />
}
export default connect(
  state => {
    const twoHoursPast = moment().subtract(2, 'hours').toISOString()
    return {
      redirectEvent: state.common.events.find(e => e.date > twoHoursPast)
    }
  },
  dispatch => ({
    loadEvents: () => dispatch(common.actions.loadEvents())
  })
)(CurrentEvent)
