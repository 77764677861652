import styled from 'styled-components'
import {white} from 'styles/colors'

export default styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem 4rem;
  @media (max-width: 768px) {
    padding: 1rem 2rem;
  }
  background-color: black;
  color: ${white};
  overflow: ${props => props.noScroll ? 'hidden' : 'scroll'};
`
