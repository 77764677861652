import React from 'react'
import {connect} from 'react-redux'
import {Route, Redirect} from 'react-router-dom'

const WithAuthentication = ({component, authenticated, authenticating, ...rest}) => {
  return <Route {...rest} render={props => {
    if (authenticated) return React.createElement(component, props)
    if (authenticating) return <div>Please wait for login...</div>
    return <Redirect to={{
      pathname: '/login',
      state: { from: props.location }
    }} />
  }} />
}

export default connect(
  state => ({
    authenticated: state.common.authenticated,
    authenticating: state.common.authenticating
  })
)(WithAuthentication)
