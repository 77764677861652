import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import PageHeader from 'components/PageHeader'
import {ensembleColor, gray} from 'styles/colors'
import moment from 'moment'
import {Link} from 'react-router-dom'

const Event = styled.div`
  border-left: 4px solid ${gray};
  padding: 0.5rem 0 0.5rem 2rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  > h1 {
    margin: 0;
  }
`
const SubTitle = styled.div`
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
  font-style: italic;
`
const PlaceTime = styled.div`
  font-size: 1rem;
  color: ${ensembleColor};
  font-style: ${props => props.italic ? 'italic' : 'inherit'};
`
const TicketSale = styled.div`
  font-size: 0.9rem;
  color: ${ensembleColor};
  font-style: italic;
  text-decoration: underline;
`
const Text = styled(ReactMarkdown)`
  margin-top: 0.5rem;
  font-size: 0.85rem;
  @media (min-width: 769px) {
    max-width: 32rem;
  }
  a {
    color: ${ensembleColor};
    text-decoration: underline;
  }
  p {
    text-align: justify;
  }
`

export default ({event}) =>
  <Event>
    <PageHeader small color={ensembleColor}>{event.title}</PageHeader>
    <SubTitle>{event.subtitle}</SubTitle>
    {event.place && <PlaceTime>{event.place}</PlaceTime>}
    {!event.place && <PlaceTime italic>Ort wird noch bekannt gegeben</PlaceTime>}
    <PlaceTime>{moment(event.date).format('dddd, Do MMMM ’YY [um] HH:mm [Uhr]')}</PlaceTime>
    {event.text && <Text source={event.text} escapeHtml />}
    {event.ticketSale && <TicketSale><Link to={`/ensemble/tickets/${event._id}`}>Zum Kartenvorverkauf</Link></TicketSale>}
  </Event>
