import React from 'react'
import {render} from 'react-snapshot'
import App from './App'
import './index.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import store from './redux/store'
import {Provider} from 'react-redux'
import {common} from 'redux/modules'
import apiService from 'lib/apiService'
import moment from 'moment'
import 'moment/locale/de'
moment.locale('de')

store.dispatch(common.actions.init())

const onVisibilityChange = () => {
  store.dispatch(common.actions.onVisibilityChange(document.hidden))
}
document.addEventListener('visibilitychange', onVisibilityChange, false)

apiService.init(store)

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
