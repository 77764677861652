import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import omit from 'lodash/omit'
import {common} from 'redux/modules'
import PageHeader from 'components/PageHeader'
import Page from 'components/SimplePage'
import AccountForm from 'components/AccountForm'
import {purple} from 'styles/colors'
import {ADMIN, hasPermission} from 'lib/roles'

class AddUser extends Component {
  state = {}

  onSubmit = async (userData) => {
    const isAdmin = hasPermission([ADMIN], this.props.permissions)
    const newUser = await this.props.createUser(isAdmin ? userData : omit(userData, 'roles'))
    this.setState({redirect: newUser._id})
  }

  render () {
    return <Page rightMargin>
      <PageHeader color={purple}>Benutzer hinzufügen</PageHeader>
      <AccountForm onSubmit={this.onSubmit} />
      {this.state.redirect && <Redirect to={`/internal/user/profile/${this.state.redirect}`} />}
    </Page>
  }
}
export default connect(
  state => ({
    permissions: state.common.authenticated.roles
  }),
  dispatch => ({
    createUser: userData => dispatch(common.actions.createUser(userData))
  })
)(AddUser)
