import React, {Component} from 'react'
import styled from 'styled-components'
import SubHeader from 'components/PageHeader'
import {contactColor} from 'styles/colors'
import Page from 'components/SimplePage'
import LabelledTextInput from 'components/LabelledTextInput'
import LabelledTextArea from 'components/LabelledTextArea'
import Button from 'components/Button'
import ReCAPTCHA from 'react-google-recaptcha'
import apiService from 'lib/apiService'
import pick from 'lodash/pick'

const Form = styled.div`
  max-width: 30rem;
`

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 8rem;
  @media (max-width: 768px) {
    display: block;
    margin: 0;
  }
`

export default class Board extends Component {
  state = {
    name: '',
    contact: '',
    subject: '',
    message: '',
    pending: false,
    valid: false
  }

  onChange = (e, propName, maxLength) => {
    const cropped = e.target.value.substr(0, maxLength)
    const verify = Object.assign({}, pick(this.state, ['name', 'contact', 'message', 'subject']), {[propName]: cropped})
    this.setState({
      [propName]: cropped,
      valid: verify.name.trim() && verify.contact.trim() && verify.subject.trim() && verify.message.trim()
    })
  }

  onCaptcha = async (token) => {
    this.setState({
      pending: true
    })
    apiService.req('contact', 'contactBoard', {message: {
      reCaptcha: token,
      name: this.state.name,
      contact: this.state.contact,
      subject: this.state.subject,
      message: this.state.message
    }}).then(() => {
      this.setState({
        success: true
      })
    }).catch(err => {
      console.log(err)
      this.setState({
        success: false,
        pending: false,
        error: `Ein Fehler ist aufgetreten! (${err.statusText})`
      })
    })
  }

  render () {
    return (
      <Page>
        <SubHeader color={contactColor}>Anfrage an den Vorstand</SubHeader>
        <Form>
          <LabelledTextInput width='100%' label='Name' change={e => this.onChange(e, 'name', 40)} value={this.state.name} />
          <LabelledTextInput width='100%' label='Telefon / Email' change={e => this.onChange(e, 'contact', 64)} value={this.state.contact} />
          <LabelledTextInput width='100%' label='Betreff' change={e => this.onChange(e, 'subject', 80)} value={this.state.subject} />
          <LabelledTextArea height='12rem' label='Nachricht' change={e => this.onChange(e, 'message', 2000)} value={this.state.message} />
          <ReCAPTCHA
            ref={c => { this.captcha = c }}
            size='invisible'
            sitekey='6LfSZB8UAAAAAHMJA1SVPnV9I4YQ0Oh4L-9ygdj8'
            onChange={this.onCaptcha} />
          <ButtonWrap>
            <Button disabled={this.state.pending || !this.state.valid} onClick={() => this.captcha.execute()}>Abschicken</Button>
            {this.state.success && <div>Ihre Anfrage wurde erfolgreich verschickt.</div>}
            {this.state.error && <div>{this.state.error}</div>}
          </ButtonWrap>
        </Form>
      </Page>
    )
  }
}
