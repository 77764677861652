export default [
  {id: 'sopran', text: 'Sopran'},
  {id: 'mezzo', text: 'Mezzo'},
  {id: 'alt1', text: 'Alt 1'},
  {id: 'alt2', text: 'Alt 2'},
  {id: 'tenor1', text: 'Tenor 1'},
  {id: 'tenor2', text: 'Tenor 2'},
  {id: 'bass1', text: 'Bass 1'},
  {id: 'bass2', text: 'Bass 2'},
  {id: 'none', text: 'nicht aktiv / keine Stimmgruppe'}
]
