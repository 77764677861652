import styled from 'styled-components'

export default styled.div`
  min-height: calc(100% - 4rem);
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-left: 0;
  }
  justify-content: center;
  align-items: center;
`
