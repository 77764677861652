import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import {internalEventsColor, darkInternalEventsColor} from 'styles/colors'

const Container = styled(ReactMarkdown)`
  margin-bottom: 1.5rem;
  p {
    text-align: justify;
  }
  a {
    color: ${internalEventsColor};
    text-decoration: underline;
  }
  code {
    background-color: ${darkInternalEventsColor};
  }
`

const markdownHelp = `
# Markdown Kurzreferenz
In den beiden großen Textfeldern kann der Text mit Markdown formatiert werden. Markdown übersetzt spezielle Zeichen in
entsprechende Formatierungen, damit der Text nicht so langweilig aussieht. Das Ergebnis siehst du sofort in der entsprechenden
Vorschau. Hier eine kleine Übersicht über die gängigsten Formatierungen:
## Überschriften
Überschriften werden durch Gatterzeichen \\# am Zeilenanfang markiert. Die Anzahl der Gatter bestimmt die Schriftgröße in der
Überschriften-Hierarchie.

\`# Sehr große Überschrift\`  
\`## Große Überschrift\`  
\`### Kleine Überschrift\`  
\`#### Sehr kleine Überschrift\`

wird formatiert als:
# Sehr große Überschrift
## Große Überschrift
### Kleine Überschrift
#### Sehr kleine Überschrift

## Absätze
Normale Zeilenumbrüche führen automatisch zu einem neuen Absatz. Mehrere Zeilenumbrüche hintereinander werden zwar wie ein einzelner
behandelt, können aber hilfreich sein, um den Absatz auch im Quelltext zu kennzeichnen.
Eine *neue Zeile innerhalb eines Absatzes* erhält man,  
wenn man vor dem Zeilenumbruch am Zeilenende  
zwei Leerzeichen eintippt.

## Hervorheben von Textteilen
Textabschnitte mit einfachen bzw. doppelten Sternchen \\* umschlossen werden, werden *kursiv* bzw. **fett** dargestellt.

\`Eine Zeile mit *einem kursiven Abschnitt* und **zwei fetten** Wörtern.\`  
wird formatiert als:  
Eine Zeile mit *einem kursiven Abschnitt* und **zwei fetten** Wörtern.
## Listen
Eine Liste mit Unterpunkten erhält man durch ein Sternchen \\* am Anfang der Zeile.

\`* Punkt 1\`  
\`* Punkt 2\`  
\`* etc.\`

erzeugt folgende Liste:
* Punkt 1
* Punkt 2
* Punkt 3

## Externe Links
Auch Links auf externe Seiten sind möglich. Hierzu wird zunächst der Anzeigetext in eckige Klammern eingeschlossen und direkt
danach das eigentliche Ziel des Links in runden Klammern angegeben.

\`[Auf Google suchen](http://www.google.de)\`  
erzeugt folgenden Link: [Auf Google suchen](http://www.google.de)
`

export default () => <Container source={markdownHelp} />
