import React from 'react'
import SubHeader from 'components/PageHeader'
import Page from 'components/SimplePage'
import FadeIn from 'components/FadeIn'
import {mediaColor} from 'styles/colors'

export default () =>
  <Page>
    <SubHeader color={mediaColor}>On record</SubHeader>
    <p>
      Die Moving Colours erlebt man am besten live. Für die Zeit bis zum nächsten Konzert gibt
      es hier ein paar Hörproben.
    </p>
    <FadeIn delay={800} placeholderHeight='332px'>
      <iframe title='Hörbeispiel 1' width='100%' height='166' scrolling='no' frameBorder='no' src='https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/317674332&amp;color=ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false' />
      <iframe title='Hörbeispiel 2' width='100%' height='166' scrolling='no' frameBorder='no' src='https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/317674105&amp;color=ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false' />
    </FadeIn>
  </Page>
