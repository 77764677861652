import React, {Component} from 'react'
import {connect} from 'react-redux'
import {common} from 'redux/modules'
import styled from 'styled-components'
import PageHeader from 'components/PageHeader'
import Page from 'components/SimplePage'
import find from 'lodash/find'
import round from 'lodash/round'
import map from 'lodash/map'
import get from 'lodash/get'
import {REPERTOIRE_UPLOADER, hasPermission} from 'lib/roles'
import Dropzone from 'react-dropzone'
import {internalRepertoireColor, darkInternalRepertoireColor, red, gray, fontColor} from 'styles/colors'
import LabelledTextInput from 'components/LabelledTextInput'
import InputLabel from 'components/InputLabel'
import {List, ListRow, ListCell, ListCellExternalLink, ListHeader, RowGroup} from 'components/List'
import {MobileOnly} from 'components/MediaSwitch'
import Button from 'components/Button'
import {TrashIcon} from 'components/Icons'
import filesize from 'filesize'
import apiService from 'lib/apiService'
import MultiPillSelect from 'components/MultiPillSelect'

const FileDropzone = styled(Dropzone)`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 2px dashed ${gray};
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Error = styled.div`
  color: ${red};
  text-align: center;
`
const TeachMeSelector = styled.div`
  margin-bottom: 1rem;
`

const ArchiveButton = styled(Button)`
  width: 12rem;
`

const teachMeOptions = [
  {key: 'sopran', value: 'S'},
  {key: 'mezzo', value: 'M'},
  {key: 'alt1', value: 'A'},
  {key: 'alt2', value: 'A'},
  {key: 'tenor1', value: 'T'},
  {key: 'tenor2', value: 'T'},
  {key: 'bass1', value: 'B'},
  {key: 'bass2', value: 'B'}
]

const selectionAssistant = [
  {regex: /sopran.?2|mezzo/i, selection: ['mezzo']},
  {regex: /sopran/i, selection: ['sopran']},
  {regex: /alt.?1/i, selection: ['alt1']},
  {regex: /alt.?2/i, selection: ['alt2']},
  {regex: /alt/i, selection: ['alt1', 'alt2']},
  {regex: /tenor.?1/i, selection: ['tenor1']},
  {regex: /tenor.?2/i, selection: ['tenor2']},
  {regex: /tenor/i, selection: ['tenor1', 'tenor2']},
  {regex: /bass.?1/i, selection: ['bass1']},
  {regex: /bass.?2/i, selection: ['bass2']},
  {regex: /bass/i, selection: ['bass1', 'bass2']},
  {regex: /noten|alle/i, selection: map(teachMeOptions, 'key')}
]

class RepertoireDetails extends Component {
  state = {
    assistSelection: true,
    name: '',
    teachMeSelection: []
  }

  componentWillMount () {
    if (!this.props.repertoires) {
      this.props.loadRepertoires()
    }
  }

  onNameChanged = e => {
    if (this.state.assistSelection) {
      const teachMeSelection = get(find(selectionAssistant, item => e.target.value.match(item.regex)), 'selection') || []
      this.setState({name: e.target.value, error: null, teachMeSelection})
    } else {
      this.setState({name: e.target.value, error: null})
    }
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const {uploadProgress, uploadError, uploadRepertoireFile, match, resetUploadError} = this.props
    if (uploadError) resetUploadError()
    if (!this.state.name.trim()) {
      this.setState({error: 'Bitte erst einen Namen eingeben'})
      return
    }
    if (rejectedFiles.length) {
      if (!acceptedFiles.length) {
        this.setState({error: `Dateityp ${rejectedFiles[0].type} ist nicht erlaubt.`})
      } else {
        this.setState({error: `Dateien können nur einzeln hochgeladen werden!`})
      }
      return
    }
    if (uploadProgress) return
    const name = this.state.name.trim()
    const teachMeSelection = this.state.teachMeSelection
    this.setState({name: '', teachMeSelection: [], assistSelection: true})
    if (acceptedFiles[0]) {
      const file = acceptedFiles[0]
      if (file.size > 20971520) return console.error('image too large')
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        uploadRepertoireFile(match.params.id, {
          data: e.currentTarget.result.substr(e.currentTarget.result.indexOf('base64,') + 7),
          type: acceptedFiles[0].type,
          teachMe: teachMeSelection,
          name
        })
      }
    }
  }
  onRemoveFile = (e, file) => {
    const {removeRepertoireFile, match} = this.props
    if (window.confirm(`Möchtest du '${file.name}' löschen?`)) removeRepertoireFile(match.params.id, file.path)
    e.stopPropagation()
  }

  render () {
    const {repertoire, permissions, archive, unarchive} = this.props
    if (!repertoire) return null
    const isManager = hasPermission([REPERTOIRE_UPLOADER], permissions)
    return (
      <Page>
        <PageHeader color={internalRepertoireColor}>{repertoire.name}</PageHeader>
        {isManager && <ArchiveButton
          onClick={() => repertoire.archived ? unarchive(repertoire._id) : archive(repertoire._id)}
        >{repertoire.archived ? 'Wiederherstellen' : 'Archivieren'}</ArchiveButton>}
        <List>
          <ListHeader>
            <div>Datei</div>
            <div>Größe</div>
            <div>Stimmgruppen</div>
            <div>Typ / Player</div>
            {isManager && <div />}
          </ListHeader>
          <RowGroup>
            {repertoire.files.map(file =>
              <ListRow key={file.path}>
                <ListCellExternalLink href={`/files/repertoire/${file.path}?access=${apiService.getAuthToken()}`} title color={darkInternalRepertoireColor}>
                  {file.name}
                </ListCellExternalLink>
                <ListCellExternalLink href={`/files/repertoire/${file.path}?access=${apiService.getAuthToken()}`} color={darkInternalRepertoireColor} align='center'>
                  <MobileOnly>Größe:</MobileOnly><span>{filesize(file.size, {standard: 'iec'})}</span>
                </ListCellExternalLink>
                <ListCellExternalLink single href={`/files/repertoire/${file.path}?access=${apiService.getAuthToken()}`} color={darkInternalRepertoireColor} align='center'>
                  <MultiPillSelect
                    options={teachMeOptions}
                    selection={file.teachMe}
                    activeColor={darkInternalRepertoireColor}
                    activeBackgroundColor={fontColor}
                  />
                </ListCellExternalLink>
                {file.type === 'audio/mp3'
                  ? <ListCell color={darkInternalRepertoireColor} align='center' onClick={e => e.stopPropagation()}>
                    <audio src={`/files/repertoire/${file.path}?access=${apiService.getAuthToken()}`} controls preload='none' />
                  </ListCell>
                  : <ListCellExternalLink href={`/files/repertoire/${file.path}?access=${apiService.getAuthToken()}`} color={darkInternalRepertoireColor} align='center'>
                    <MobileOnly>Typ:</MobileOnly><span>{file.type}</span>
                  </ListCellExternalLink>
                }
                {isManager && <ListCell controls align='right' color={darkInternalRepertoireColor}>
                  <TrashIcon size='1.5rem' fill={red} onClick={e => this.onRemoveFile(e, file)} />
                </ListCell>}
              </ListRow>
            )}
          </RowGroup>
        </List>
        {repertoire.files.length === 0 && <div>Noch keine Einträge.</div>}
        {isManager &&
          <div>
            <PageHeader small color={internalRepertoireColor}>Repertoire Upload</PageHeader>
            <LabelledTextInput
              label='Name'
              placeholder='z.B. Noten, Teach-Me Tenor'
              value={this.state.name}
              change={this.onNameChanged}
            />
            <TeachMeSelector>
              <InputLabel>Stimmgruppen</InputLabel>
              <MultiPillSelect
                onChange={teachMeSelection => this.setState({teachMeSelection, assistSelection: false})}
                selection={this.state.teachMeSelection}
                options={teachMeOptions}
                activeColor={darkInternalRepertoireColor}
                activeBackgroundColor={fontColor} />
            </TeachMeSelector>
            <FileDropzone accept='audio/mp3,audio/mpeg,audio/midi,application/pdf,image/jpeg,image/png,text/plain' multiple={false} onDrop={this.onDrop}>
              {this.props.uploadProgress
                ? <div>{round(this.props.uploadProgress * 100)} %</div>
                : <div>
                  <div>Ziehe eine Datei (mp3, txt, jpg, png oder pdf) hierhin oder klicke um eine auszuwählen.</div>
                  {(this.props.uploadError || this.state.error) && <Error>{this.state.error || `Upload Fehler: ${this.props.uploadError}`}</Error>}
                </div>
              }
            </FileDropzone>
          </div>
        }
      </Page>
    )
  }
}

export default connect(
  (state, props) => ({
    repertoires: state.common.repertoires,
    repertoire: find(state.common.repertoires, {_id: props.match.params.id}),
    permissions: state.common.authenticated.roles,
    uploadProgress: state.common.uploadProgress,
    uploadError: state.common.uploadError
  }),
  dispatch => ({
    loadRepertoires: () => dispatch(common.actions.loadRepertoires()),
    archive: id => dispatch(common.actions.archive(id)),
    unarchive: id => dispatch(common.actions.unarchive(id)),
    uploadRepertoireFile: (id, file) => dispatch(common.actions.uploadRepertoireFile(id, file)),
    removeRepertoireFile: (id, path) => dispatch(common.actions.removeRepertoireFile(id, path)),
    resetUploadError: () => dispatch(common.actions.resetUploadError())
  })
)(RepertoireDetails)
