import React from 'react'
import styled from 'styled-components'

const FontPath = styled.path`
  fill: #f4f4f4;
  stroke: none;
`
const InitialsPath = styled.path`
  fill: #00bfa5;
`
const PinPath = styled.path`
  fill: ${props => props.fill || 'none'};
  stroke: none;
`
export default (props) =>
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    id='svg2'
    version='1.1'
    viewBox='0 0 2501 1200'
    height='100%'
    width='100%'>
    <FontPath
      d='m 939.58639,1017.6361 q -29.3333,0 -54.39994,-11.2 -24.5333,-11.73328 -42.66661,-31.46659 -18.13331,-19.73331 -28.26664,-45.86662 -10.13332,-26.1333 -10.13332,-54.93326 0,-29.3333 10.13332,-55.46661 10.66666,-26.1333 28.79997,-45.86661 18.13331,-19.73331 42.66662,-30.93329 25.06663,-11.73332 53.8666,-11.73332 28.79997,0 53.33327,11.73332 24.53334,11.19998 42.66664,30.93329 18.6666,19.73331 28.7999,45.86661 10.6667,26.13331 10.6667,55.46661 0,28.79996 -10.6667,54.93326 -10.1333,26.13331 -28.2666,45.86662 -18.1333,19.73331 -43.19994,31.46659 -24.5333,11.2 -53.33327,11.2 z M 830.78652,875.23629 q 0,24.53331 8.53332,46.39995 8.53332,21.3333 22.93331,37.33329 14.93331,15.99998 34.66662,25.59996 19.73331,9.06666 42.13329,9.06666 22.39997,0 42.13328,-9.06666 19.73336,-9.59998 34.66666,-26.1333 14.9333,-16.53331 23.4666,-37.86662 8.5333,-21.86664 8.5333,-46.93328 0,-24.5333 -8.5333,-45.86661 -8.5333,-21.86664 -23.4666,-37.86662 -14.9333,-16.53332 -34.66666,-26.1333 -19.19998,-9.59999 -41.59995,-9.59999 -22.39997,0 -42.13328,9.59999 -19.73331,9.59998 -34.66663,26.1333 -14.39998,16.53331 -23.46664,38.93329 -8.53332,21.86664 -8.53332,46.39994 z'
      id='path4192' />
    <FontPath
      d='m 1141.0778,622.96992 27.2,0 0,334.39961 q 0,15.46665 8.5333,24.5333 9.0667,9.06666 24.5333,9.06666 5.8667,0 13.8667,-1.06667 8.5333,-1.6 15.4666,-4.26666 l 5.3333,21.33334 q -9.0666,3.7333 -22.3999,5.8666 -13.3333,2.6667 -22.9333,2.6667 -22.4,0 -36.2667,-13.3333 -13.3333,-13.86668 -13.3333,-36.79998 l 0,-342.3996 z'
      id='path4194' />
    <FontPath
      d='m 1393.7525,1017.6361 q -29.3333,0 -54.3999,-11.2 -24.5333,-11.73328 -42.6666,-31.46659 -18.1333,-19.73331 -28.2667,-45.86662 -10.1333,-26.1333 -10.1333,-54.93326 0,-29.3333 10.1333,-55.46661 10.6667,-26.1333 28.8,-45.86661 18.1333,-19.73331 42.6666,-30.93329 25.0667,-11.73332 53.8666,-11.73332 28.8,0 53.3333,11.73332 24.5333,11.19998 42.6666,30.93329 18.6667,19.73331 28.8,45.86661 10.6666,26.13331 10.6666,55.46661 0,28.79996 -10.6666,54.93326 -10.1333,26.13331 -28.2667,45.86662 -18.1333,19.73331 -43.1999,31.46659 -24.5333,11.2 -53.3333,11.2 z M 1284.9527,875.23629 q 0,24.53331 8.5333,46.39995 8.5333,21.3333 22.9333,37.33329 14.9333,15.99998 34.6666,25.59996 19.7333,9.06666 42.1333,9.06666 22.4,0 42.1333,-9.06666 19.7333,-9.59998 34.6666,-26.1333 14.9333,-16.53331 23.4666,-37.86662 8.5334,-21.86664 8.5334,-46.93328 0,-24.5333 -8.5334,-45.86661 -8.5333,-21.86664 -23.4666,-37.86662 -14.9333,-16.53332 -34.6666,-26.1333 -19.2,-9.59999 -41.6,-9.59999 -22.3999,0 -42.1333,9.59999 -19.7333,9.59998 -34.6666,26.1333 -14.4,16.53331 -23.4666,38.93329 -8.5333,21.86664 -8.5333,46.39994 z'
      id='path4196' />
    <FontPath
      d='m 1678.9772,1017.6361 q -88.5332,0 -88.5332,-122.6665 l 0,-159.99981 26.6666,0 0,156.26648 q 0,102.39988 69.8666,102.39988 17.6,0 34.1333,-5.86666 16.5333,-6.39999 30.9333,-17.06665 14.4,-11.19998 25.0666,-26.66663 11.2,-15.46665 17.6,-34.13329 l 0,-174.93313 26.6666,0 0,239.99972 q 0,13.33331 11.2,13.33331 l 0,23.99998 q -2.6666,0.5333 -4.8,0.5333 -2.1333,0 -3.2,0 -10.6666,0 -19.2,-6.4 -7.9999,-6.93329 -7.9999,-18.13328 l 0,-41.59995 q -18.1334,33.59996 -50.1333,52.26661 -31.4666,18.66662 -68.2666,18.66662 z'
      id='path4198' />
    <FontPath
      d='m 2037.8517,758.96976 q -37.3333,1.06667 -65.5999,22.39998 -27.7333,20.79997 -38.9333,57.59993 l 0,173.33313 -26.6666,0 0,-277.33301 25.6,0 0,68.26659 q 14.3999,-29.3333 37.8666,-47.46661 24,-18.66665 51.1999,-21.33331 5.3333,-0.53334 9.6,-0.53334 4.2667,0 6.9333,0 l 0,25.06664 z'
      id='path4200' />
    <FontPath
      d='m 2176.9433,1017.6361 q -32,0 -61.3333,-10.6666 -29.3333,-10.66669 -51.1999,-31.99999 l 13.3333,-18.13332 q 22.4,20.26665 46.4,29.86664 23.9999,9.59998 52.7999,9.59998 36.2666,0 58.1333,-14.93331 22.3999,-15.46665 22.3999,-42.66662 0,-12.79998 -5.8666,-21.33331 -5.3334,-9.06665 -16.5333,-14.93331 -11.2,-6.39999 -27.7333,-11.19999 -16.5334,-4.79999 -38.4,-10.13332 -23.4666,-5.33333 -41.0666,-10.66665 -17.0667,-5.33333 -28.2667,-12.79999 -11.1999,-7.46666 -17.0666,-18.13331 -5.3333,-10.66665 -5.3333,-27.19997 0,-20.79997 8,-36.26662 8,-15.46665 21.8666,-25.59997 14.4,-10.13332 32.5333,-14.93332 18.6666,-5.33332 39.4666,-5.33332 32.5333,0 57.6,11.19998 25.0666,10.66666 38.9332,27.7333 l -14.9333,14.39999 q -13.8666,-15.99999 -36.2666,-23.99998 -21.8666,-7.99999 -46.9333,-7.99999 -15.4666,0 -29.3333,3.2 -13.3333,3.2 -23.4666,10.13332 -10.1333,6.93333 -16,18.13331 -5.8667,10.66666 -5.8667,25.59997 0,12.26666 3.7334,19.73331 4.2666,7.46666 12.8,12.79999 9.0666,5.33332 22.9333,9.59999 13.8666,3.73333 33.0666,8.53332 26.1333,6.39999 46.3999,12.26665 20.2667,5.86666 33.6,14.39998 13.8666,8.53333 20.8,20.26665 7.4666,11.73332 7.4666,29.86663 0,37.33329 -29.3333,59.73326 -28.7999,21.86662 -77.3332,21.86662 z'
      id='path4202' />
    <FontPath
      id='path4160'
      d='m 1095.7486,569.65879 0,-327.46629 -149.33315,255.46638 -8.53332,-35.7333 157.86647,-270.93302 27.2,0 0,378.66623 z' />
    <FontPath
      id='path4162'
      d='m 1326.6826,574.99212 q -29.3333,0 -54.3999,-11.19998 -24.5332,-11.73333 -42.6666,-31.46664 -18.1333,-19.73331 -28.2667,-45.86662 -10.1333,-26.13329 -10.1333,-54.93327 0,-29.33329 10.1333,-55.4666 10.6667,-26.13329 28.8001,-45.86662 18.1333,-19.73331 42.6665,-30.93329 25.0666,-11.73332 53.8666,-11.73332 28.8001,0 53.3333,11.73332 24.5332,11.19998 42.6666,30.93329 18.6668,19.73333 28.8,45.86662 10.6667,26.13331 10.6667,55.4666 0,28.79998 -10.6667,54.93327 -10.1332,26.13331 -28.2667,45.86662 -18.1333,19.73331 -43.1999,31.46664 -24.5332,11.19998 -53.3333,11.19998 z M 1217.8828,432.59228 q 0,24.53331 8.5332,46.39995 8.5334,21.33331 22.9334,37.33329 14.9334,15.99998 34.6667,25.59996 19.7333,9.06668 42.1332,9.06668 22.3999,0 42.1332,-9.06668 19.7335,-9.59998 34.6667,-26.13329 14.9333,-16.53332 23.4667,-37.86663 8.5333,-21.86664 8.5333,-46.93328 0,-24.5333 -8.5333,-45.86661 -8.5334,-21.86664 -23.4667,-37.86662 -14.9332,-16.53331 -34.6667,-26.13331 -19.1999,-9.59998 -41.5999,-9.59998 -22.3999,0 -42.1332,9.59998 -19.7333,9.6 -34.6666,26.13331 -14.4,16.53331 -23.4668,38.93329 -8.5332,21.86664 -8.5332,46.39994 z' />
    <FontPath
      id='path4164'
      d='m 1601.3513,569.65879 -115.7333,-277.33301 27.7334,0 102.3999,250.66638 102.9331,-250.66638 26.1334,0 -115.7333,277.33301 -27.7332,0 z' />
    <FontPath
      id='path4166'
      d='m 1797.2677,569.65879 0,-277.33301 26.6667,0 0,277.33301 -26.6667,0 z m 0,-340.26627 0,-49.06662 26.6667,0 0,49.06662 -26.6667,0 z' />
    <FontPath
      id='path4168'
      d='m 2134.3508,569.65879 -26.6668,0 0,-154.66649 q 0,-53.8666 -15.4666,-78.39991 -14.9334,-24.53331 -47.9999,-24.53331 -17.5999,0 -35.1999,6.4 -17.0667,6.39999 -32,18.13331 -14.9334,11.73333 -26.1334,27.73331 -11.1999,15.46664 -16.5333,33.59996 l 0,171.73313 -26.6666,0 0,-277.33301 25.0666,0 0,66.13325 q 8.5334,-15.99998 20.8001,-28.79996 12.7999,-13.33331 28.2665,-22.39997 16,-9.6 33.6,-14.4 17.6,-5.33332 36.2666,-5.33332 45.3334,0 64,31.99996 18.6667,31.46664 18.6667,90.66656 l 0,159.46649 z' />
    <FontPath
      id='path4170'
      d='m 2326.9755,573.92545 q -28.8,0 -52.7999,-11.73331 -24.0001,-12.26666 -41.6001,-31.99997 -17.0666,-20.26665 -27.1999,-45.86661 -9.6,-25.59997 -9.6,-52.79995 0,-28.26662 9.0667,-54.39993 9.5999,-26.1333 26.6666,-45.86661 17.0666,-20.26664 40.5332,-31.99997 24.0001,-11.73332 52.8,-11.73332 36.2666,0 62.9333,19.19998 27.1999,18.66665 45.8667,45.86661 l 0,-60.26659 24.5332,0 0,282.66634 q 0,28.26664 -10.6667,49.59995 -10.6666,21.3333 -28.7999,35.19993 -17.5999,14.40001 -41.6,21.33327 -23.4666,7.46675 -49.5998,7.46675 -24.0001,0 -43.2001,-4.79995 -19.1999,-4.26678 -34.1333,-12.80003 -14.4,-8.00008 -26.1332,-20.26667 -11.2001,-11.73332 -19.7333,-26.1333 l 18.1333,-12.26666 q 16.5333,28.26664 44.7999,41.06662 28.2667,13.33339 60.2667,13.33339 21.3332,0 39.9999,-5.86677 19.1999,-5.8666 33.0666,-17.59995 14.3999,-11.19998 22.4,-28.26663 8.5332,-17.06664 8.5332,-39.99995 l 0,-60.79993 q -17.0666,27.19997 -45.3332,43.73328 -28.2667,15.99998 -59.1999,15.99998 z m 6.4,-22.93329 q 17.6,0 34.1333,-6.4 17.0666,-6.93333 30.4,-17.59999 13.8665,-10.66665 22.3999,-23.99996 9.0666,-13.33333 11.1999,-26.66664 l 0,-91.1999 q -6.3999,-15.99999 -17.0666,-29.3333 -10.6666,-13.86665 -24.5333,-23.46663 -13.3333,-9.6 -29.3333,-14.93333 -15.4666,-5.86665 -31.4666,-5.86665 -25.0667,0 -44.8,10.66665 -19.7333,10.66666 -33.6,27.73331 -13.3332,17.06664 -20.7999,38.93327 -6.9333,21.33331 -6.9333,43.19997 0,23.99996 8.5333,45.33327 8.5334,21.33331 23.4667,37.86662 14.9332,16.53332 35.1998,26.13331 20.2668,9.6 43.2001,9.6 z' />
    <g transform='matrix(2,0,0,2,-1244.756,-4833.16)' id='g31'>
      <clipPath id='_clip3'>
        <InitialsPath
          d='m 1259.07,2316.47 0,666.17 -732.461,0 0,-666.17 732.461,0 z m -199.28,464.25 -113.248,0 0,76.3 113.248,0 0,-76.3 z'
          id='path34' />
      </clipPath>
      <g clipPath='url(#_clip3)' id='g36'>
        <g id='path10999' transform='matrix(-13.3741,0,0,-17.8623,4137.69,6806.54)'>
          <InitialsPath
            d='m 237.685,218.184 c -3.396,1.82 -5.205,5.114 -4.092,7.592 1.187,2.644 5.267,3.39 9.106,1.666 3.84,-1.724 5.992,-5.27 4.805,-7.913 -1.187,-2.644 -5.267,-3.39 -9.106,-1.666 -0.24,0.108 -0.487,0.199 -0.713,0.321 z m -0.531,1.86 c 0.368,-0.224 0.696,-0.597 2.399,-1.25 3.396,-1.303 6.534,-0.962 7.156,0.423 0.622,1.385 -1.21,5.563 -4.576,7.011 -3.31,1.423 -7.064,1.081 -7.686,-0.304 -0.574,-1.277 0.025,-4.249 2.707,-5.88 z'
            id='path39' />
        </g>
        <g id='path12116' transform='matrix(7.17589,0,0,7.52156,-2144.43,2242.01)'>
          <InitialsPath
            d='m 441.182,35.994 c -0.562,6.313 4.843,8.574 7.5,11.5 2.526,2.782 3.449,5.99 3.346,8.996 -0.028,0.802 -0.246,4.28 -2.615,7.767 3.168,-8.748 0.219,-12.259 -2.663,-15.052 -3.489,-3.384 -6.086,-6.572 -5.568,-13.211 z'
            id='path42' />
        </g>
        <g id='path11001' transform='matrix(-13.3741,0,0,-8.00126,4142.69,4577.43)'>
          <rect
            x='233.383'
            y='224.552'
            width='1.339'
            height='33.551998'
            style={{fill: '#00bfa5'}}
            id='rect45' />
        </g>
      </g>
    </g>
    <PinPath
      id='path4165'
      fill='#009688'
      d='M 665.28873,577.43297 582.67164,246.63718 c -6.74932,-27.02403 5.20504,-48.73624 22.24147,-63.15479 17.09135,-14.46504 45.32821,-19.70285 66.17525,-11.53261 22.07206,8.65035 42.70798,26.92683 43.11,56.56472 l 4.94255,364.37571 c -16.16133,-8.16218 -34.32722,-13.50482 -53.85218,-15.45724 z' />
    <PinPath
      id='path4167'
      fill='#009688'
      d='m 623.22473,37.134876 c -32.49851,2.993409 -58.61186,34.167441 -54.4197,69.063144 3.88337,32.32533 27.39963,57.18113 66.64745,54.7281 l -0.95273,-11.01187 c -7.56833,0.4314 -14.07169,-14.93917 -15.03466,-24.4614 -0.91203,-9.01855 0.3288,-24.34306 9.31273,-25.549319 9.12326,-1.224972 14.7746,13.404319 15.9662,22.531999 1.26542,9.69314 -0.76864,26.91581 -10.24427,27.47872 l 0.95273,11.01187 c 29.05923,-0.55785 60.26975,-27.33666 57.54158,-67.533618 C 690.79442,60.9828 659.66377,33.778508 623.22473,37.134876 Z' />
    <PinPath
      id='path4167-2'
      fill='#1de9b6'
      d='m 387.22623,28.229045 c -33.03573,12.641072 -51.15561,52.818841 -36.5616,88.004995 13.51904,32.59439 45.33759,51.64463 85.57451,37.59086 l -4.21908,-11.21193 c -7.77125,2.66661 -19.05893,-11.4681 -22.85245,-21.12259 -3.59286,-9.14381 -6.78595,-25.49841 2.23558,-29.38816 9.16142,-3.95008 19.34293,9.66062 23.25948,18.83644 4.15918,9.74426 7.08042,28.3119 -2.64261,31.67431 l 4.21908,11.21193 c 30.16011,-9.09232 54.88608,-46.1764 40.26724,-87.323186 C 464.71962,33.326193 424.26763,14.055208 387.22623,28.229045 Z' />
    <PinPath
      id='path4167-2-1'
      fill='#000000'
      d='m 146.93358,109.4712 c -30.6216,24.47615 -36.21782,73.00151 -8.95524,105.23569 25.25446,29.85985 65.26731,39.25448 103.01625,10.86077 l -8.23179,-10.41658 c -7.3068,5.43353 -23.9865,-5.68769 -31.24429,-14.60324 -6.87389,-8.44395 -15.7549,-24.63597 -7.54008,-31.78213 8.3422,-7.25698 23.67744,3.68364 30.90387,12.05233 7.67411,8.88714 17.01364,27.50734 7.8805,34.33304 l 8.23179,10.41658 C 269.77554,205.80715 283.3889,158.32271 254.09205,119.80346 230.47081,88.746451 181.26814,82.027244 146.93358,109.4712 Z' />
    <PinPath
      id='path4167-2-7'
      fill='#ff8f00'
      d='m 41.401123,336.51653 c -21.608417,28.00416 -16.136763,71.73794 14.654803,94.16405 28.523503,20.77423 65.607574,20.45265 92.625994,-12.5098 l -9.44355,-7.37071 c -5.24312,6.32557 -22.24274,0.14377 -30.51148,-6.11945 -7.83137,-5.93191 -19.079112,-18.2265 -13.406272,-26.24754 5.760802,-8.14543 21.543242,-1.83444 29.667562,3.95612 8.62761,6.14929 20.79656,20.47436 14.25019,28.41087 l 9.44355,7.37071 c 21.00337,-23.47681 22.7821,-68.01263 -11.13677,-95.51315 -27.34785,-22.17292 -71.915524,-17.54082 -96.144027,13.8589 z' />
    <PinPath
      id='path4167-8'
      fill='#d81b60'
      d='m 21.298023,569.15023 c -11.4017096,28.48059 3.514726,63.67641 34.514624,75.10065 28.716486,10.58276 59.582833,1.53735 74.325033,-32.36083 l -9.62409,-3.91491 c -2.87764,6.51892 -18.52501,5.3844 -26.906715,2.11552 -7.938362,-3.09597 -20.233225,-10.69229 -17.398314,-18.72757 2.878864,-8.15992 17.54122,-6.62951 25.690549,-3.72082 8.65417,3.08888 22.19825,12.16136 18.61448,20.33287 l 9.62409,3.91491 C 142.10607,587.33022 133.04942,549.74916 98.239125,534.83107 70.172554,522.803 34.082237,537.2163 21.298023,569.15023 Z' />
    <PinPath
      id='path4293'
      fill='#1de9b6'
      d='m 652.66177,575.75976 c -21.44512,-0.70588 -40.64405,2.18215 -58.65171,6.9762 L 407.71982,261.57262 c -12.77721,-26.22787 -10.36799,-52.30396 2.84216,-72.6041 13.97069,-21.46886 43.51744,-36.56835 68.98681,-33.84746 25.54571,2.72905 51.75071,20.61826 60.46036,47.79985 z' />
    <PinPath
      id='path4295'
      fill='#ffd740'
      d='m 581.34956,586.61162 c -22.70977,6.95672 -43.52018,17.33228 -62.78575,30.48856 L 242.09979,350.45451 c -20.24372,-22.8518 -25.56216,-49.6372 -20.15344,-74.92949 6.01499,-28.12738 32.33718,-53.90064 62.01061,-58.9101 30.86395,-5.21044 61.13106,4.45897 78.54679,28.93829 z' />
    <PinPath
      id='path4297'
      fill='#ff8f00'
      d='m 507.19519,624.8515 c -18.64072,13.24077 -36.30225,28.1951 -52.19227,46.24959 L 174.14649,519.43345 c -22.84205,-16.34729 -31.56695,-39.88639 -29.71342,-63.04413 1.99819,-24.96506 18.56259,-49.09099 38.75664,-58.65171 20.02575,-9.48104 45.50752,-14.02707 75.44625,6.20106 z' />
    <PinPath
      id='path4299'
      fill='#d81b60'
      d='M 445.95971,680.91944 405.6528,740.34628 179.31404,691.51292 c -24.06085,-7.86049 -37.34872,-26.1466 -42.37392,-46.50796 -4.49984,-18.23271 1.2643,-40.91366 14.21077,-54.51767 15.24807,-16.02251 36.26424,-26.33818 63.3025,-19.89508 z' />
    <PinPath
      id='path4167-2-7-0'
      fill='#ffd740'
      d='m 146.43226,111.27865 c -30.26087,24.72774 -35.31724,73.15891 -7.79462,104.99724 25.49536,29.49317 65.48197,38.42743 102.80535,9.71794 l -8.31767,-10.29486 c -7.2251,5.49484 -23.97137,-5.41155 -31.30198,-14.22067 -6.94287,-8.34313 -15.96982,-24.38808 -7.85801,-31.59989 8.23758,-7.32364 23.64173,3.41718 30.93521,11.68151 7.74531,8.77631 17.25544,27.2368 8.22478,34.13905 l 8.31767,10.29486 c 28.4768,-20.00726 41.53602,-67.4871 11.91797,-105.56861 -23.88021,-30.704073 -72.99863,-36.872576 -106.9287,-9.14657 z' />
  </svg>
