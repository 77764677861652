import {createStore, applyMiddleware, compose} from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import {reducer, saga} from './reducer'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [
  sagaMiddleware,
  thunk
]

const composeEnhancers = global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const enhancer = composeEnhancers(applyMiddleware(...middlewares))

export default createStore(
  reducer,
  {},
  enhancer
)

console.log('running saga middleware')
sagaMiddleware.run(saga)
