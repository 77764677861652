import React, {Component} from 'react'
import {connect} from 'react-redux'
import {common} from 'redux/modules'
import pick from 'lodash/pick'
import omit from 'lodash/omit'
import PageHeader from 'components/PageHeader'
import Page from 'components/SimplePage'
import {ADMIN, USER_MANAGER, hasPermission} from 'lib/roles'
import {internalUserColor} from 'styles/colors'
import AccountForm from 'components/AccountForm'

class AccountEdit extends Component {
  state = {
    deleted: false
  }

  updateUser ({match, currentUser, loadUser, permissions}) {
    if (this.state.deleted) return
    if (match.params.id && match.params.id !== 'self' && match.params.id !== currentUser._id) {
      loadUser(match.params.id)
    }
    if (match.params.id && match.params.id !== 'self' && !hasPermission([USER_MANAGER], permissions)) {
      alert('Netter Versuch, aber ohne Aussicht auf Erfolg...')
    }
    this.user = {...pick(currentUser, ['_id', 'email', 'googleLogin', 'roles'])}
  }

  componentDidMount () {
    this.updateUser(this.props)
  }

  componentWillReceiveProps (nextProps) {
    this.updateUser(nextProps)
  }

  onSubmit = (data) => {
    const {permissions, updateUser, currentUser} = this.props
    const isManager = hasPermission([USER_MANAGER], permissions)
    const userUpdate = hasPermission([ADMIN], permissions) ? data : omit(data, ['roles'])
    updateUser(isManager ? currentUser._id : 'self', userUpdate)
  }

  onDelete = () => {
    const {deleteUser, currentUser} = this.props
    deleteUser(currentUser._id)
    this.setState(({ deleted: true }))
  }

  render () {
    return <Page rightMargin>
      <PageHeader color={internalUserColor}>Zugangsdaten ändern</PageHeader>
      {this.state.deleted
        ? <div>Benutzer wurde gelöscht.</div>
        : <AccountForm onSubmit={this.onSubmit} user={this.props.currentUser} onDelete={this.onDelete} />
      }
    </Page>
  }
}

export default connect(
  (state, props) => ({
    currentUser: props.match.params.id && props.match.params.id !== 'self' ? state.common.user : state.common.authenticated,
    permissions: state.common.authenticated.roles
  }),
  dispatch => ({
    updateUser: (id, update) => dispatch(common.actions.updateUser(id, update)),
    deleteUser: id => dispatch(common.actions.deleteUser(id)),
    loadUser: id => dispatch(common.actions.loadUser(id))
  })
)(AccountEdit)
