import React from 'react'
import {connect} from 'react-redux'

const ChatConnect = ({connected, children}) => <div>
  {connected && <span><span>Verbunden</span>{children && <span> - {children}</span>}</span>}
  {!connected && <span>Nicht verbunden - bitte warten...</span>}
</div>

export default connect(
  state => ({
    connected: state.chat.connected
  })
)(ChatConnect)
