import React, {Component} from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {common} from 'redux/modules'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import debounce from 'lodash/debounce'
import last from 'lodash/last'
import round from 'lodash/round'
import find from 'lodash/find'
import PageHeader from 'components/PageHeader'
import Page from 'components/SimplePage'
import InputLabel from 'components/InputLabel'
import LabelledTextInput from 'components/LabelledTextInput'
import {USER_MANAGER, hasPermission} from 'lib/roles'
import Dropzone from 'react-dropzone'
import {TrashIcon} from 'components/Icons'
import {red, gray, internalUserColor} from 'styles/colors'
import {RIESelect} from 'riek'
import groups from 'lib/groups'

const ProfileImage = styled.div`
  text-align: center;
  height: 17rem;
  @media (min-width: 769px) {
    position: absolute;
    right: 7rem;
    width: 12rem;
  }
  > img {
    height: 100%;
  }
`

const ImageWrap = styled.div`
  overflow: hidden;
  border-radius: 1rem;
  height: 15rem;
  border: 1px solid ${gray};
`

const FormWrap = styled.div`
  max-width: 22rem;
`

const ProfileImageDrop = styled(Dropzone)`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 2px dashed ${gray};
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const RemoveImage = styled.div`
  height: 1rem;
  cursor: pointer;
  text-align: center;
  margin-top: 0.5rem;
`

class ProfileEdit extends Component {
  state = {
    profile: {}
  }

  updateUser ({match, currentUser, loadUser, roles}) {
    if (match.params.id && match.params.id !== 'self' && match.params.id !== currentUser._id) {
      loadUser(match.params.id)
    }
    if (match.params.id && match.params.id !== 'self' && !hasPermission([USER_MANAGER], roles)) {
      alert('Netter Versuch, aber ohne Aussicht auf Erfolg...')
    }
    this.setState({image: null, profile: {}, ...pick(currentUser, ['profile', 'image']), group: find(groups, {id: currentUser.group}) || last(groups)})
  }

  componentDidMount () {
    this.updateUser(this.props)
  }

  componentWillReceiveProps (nextProps) {
    this.updateUser(nextProps)
  }

  saveChanges = debounce(() => {
    const {updateUser, roles, currentUser} = this.props
    const isManager = hasPermission([USER_MANAGER], roles)
    const userUpdate = pickBy({
      group: isManager ? this.state.group.id : null,
      profile: this.state.profile
    })
    updateUser(isManager ? currentUser._id : 'self', userUpdate)
  }, 500)

  onChange = (prop, value) => {
    this.setState({profile: pickBy({...this.state.profile, ...{[prop]: value}})})
  }

  onGroupChange = (value) => {
    this.setState(value, this.saveChanges)
  }

  onFocus = () => {
    this.saveChanges.cancel()
  }

  onBlur = () => {
    this.saveChanges()
  }

  onDrop = (acceptedFiles) => {
    if (this.props.uploadProgress) return
    if (acceptedFiles[0]) {
      const file = acceptedFiles[0]
      if (file.size > 8192000) return console.error('image too large')
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        const {updateUser, roles, currentUser} = this.props
        updateUser(hasPermission([USER_MANAGER], roles) ? currentUser._id : 'self', {
          image: e.currentTarget.result
        })
      }
    }
  }

  onRemoveImage = () => {
    if (window.confirm('Willst du das Profilbild löschen?')) {
      const {updateUser, roles, currentUser} = this.props
      updateUser(hasPermission([USER_MANAGER], roles) ? currentUser._id : 'self', {
        image: null
      })
    }
  }

  render () {
    const isManager = hasPermission([USER_MANAGER], this.props.roles)
    return (
      <Page>
        <ProfileImage>
          {this.state.image
            ? <ImageWrap><img src={this.state.image} alt='profile' /></ImageWrap>
            : <ProfileImageDrop accept='image/png,image/jpeg' multiple={false} onDrop={this.onDrop}>
              {this.props.uploadProgress
                ? <div>{round(this.props.uploadProgress * 100)} %</div>
                : <div>Zieh ein Bild hierhin oder klicke, um eins auszuwählen.</div>
              }
            </ProfileImageDrop>
          }
          {this.state.image && <RemoveImage onClick={this.onRemoveImage}><TrashIcon size='1rem' fill={red} /></RemoveImage>}
        </ProfileImage>
        <PageHeader color={internalUserColor}>Stimmgruppe</PageHeader>
        {isManager
          ? <RIESelect
            options={groups}
            propName='group'
            change={this.onGroupChange}
            value={this.state.group || last(groups)}
          />
          : <InputLabel>{(this.state.group && this.state.group.text) || 'nicht aktiv'}</InputLabel>
        }

        <PageHeader color={internalUserColor}>Dein Profil</PageHeader>
        <FormWrap>
          <LabelledTextInput
            label='Vorname'
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            disabled={!isManager}
            change={(e) => this.onChange('prename', e.target.value)}
            value={this.state.profile.prename || ''} />
          <LabelledTextInput
            label='Name'
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            disabled={!isManager}
            change={(e) => this.onChange('name', e.target.value)}
            value={this.state.profile.name || ''} />
          <LabelledTextInput
            label='Straße'
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            change={(e) => this.onChange('street', e.target.value)}
            value={this.state.profile.street || ''} />
          <LabelledTextInput
            label='Postleitzahl'
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            change={(e) => this.onChange('zip', e.target.value)}
            value={this.state.profile.zip || ''} />
          <LabelledTextInput
            label='Stadt'
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            change={(e) => this.onChange('city', e.target.value)}
            value={this.state.profile.city || ''} />
          <LabelledTextInput
            label='Telefon'
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            change={(e) => this.onChange('phone', e.target.value)}
            value={this.state.profile.phone || ''} />
          <LabelledTextInput
            label='Handy'
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            change={(e) => this.onChange('mobile', e.target.value)}
            value={this.state.profile.mobile || ''} />
        </FormWrap>
      </Page>
    )
  }
}

export default connect(
  (state, props) => ({
    uploadProgress: state.common.uploadProgress,
    currentUser: props.match.params.id && props.match.params.id !== 'self' ? state.common.user : state.common.authenticated,
    roles: state.common.authenticated.roles
  }),
  dispatch => ({
    updateUser: (id, update) => dispatch(common.actions.updateUser(id, update)),
    loadUser: id => dispatch(common.actions.loadUser(id))
  })
)(ProfileEdit)
