import React, {Component} from 'react'
import styled from 'styled-components'
import {TransitionMotion, spring} from 'react-motion'
import isArray from 'lodash/isArray'

const Wrap = styled.div`
  position: relative;
  align-self: stretch;
  height: 4rem;
  overflow: hidden;
`

const MotionWrap = styled.div` 
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    margin: 0;
  }
`

class Taglines extends Component {
  willEnter = (c) => {
    return {
      x: c.data.props.noEnter ? 0 : 100
    }
  }

  willLeave = () => ({
    x: spring(-100, {stiffness: 140})
  })

  mapChildrenToStyles = () => {
    if (!this.props.children) return []
    const elemArr = isArray(this.props.children) ? this.props.children : [this.props.children]
    return elemArr.map(c => ({
      key: c.key,
      style: {x: spring(0, {stiffness: 140, damping: 12})},
      data: c
    }))
  }

  render () {
    return (
      <TransitionMotion
        willEnter={this.willEnter}
        willLeave={this.willLeave}
        defaultStyles={[]}
        styles={this.mapChildrenToStyles()}>
        {values => <Wrap>
          {values.map((val) =>
            <MotionWrap key={val.key} style={{transform: `translateX(${val.style.x}%)`}}>
              {val.data}
            </MotionWrap>
          )}
        </Wrap>
        }
      </TransitionMotion>
    )
  }
}
export default Taglines
