import React, {PureComponent} from 'react'
import styled from 'styled-components'
import Color from 'color'
import moment from 'moment'
import omit from 'lodash/omit'
import {fontColor, red, gray} from 'styles/colors'
const inputWarnColor = Color(red).fade(0.5).string()

const Container = styled.div`
  display: inline-block;
  margin: 0.2rem 0rem;
  width: ${props => props.width || '12rem'};
  > :last-child {
    color: ${props => props.disabled ? gray : fontColor};
    outline: none;
    line-height: 2rem;
    height: 2rem;
    border: none;
    margin: 0;
    ${props =>
    props.plain
      ? `border-bottom: 1px solid ${props.disabled ? 'transparent' : fontColor};
             font-size: 1rem;
             padding: 0 0.3rem;
             width: 100%;`
      : `border: 1px solid ${props.disabled ? gray : fontColor};
             padding: 0rem 0.7rem;
             border-radius: 0.5rem;
             box-sizing: border-box;
             width: 100%;`}
    text-align: ${props => props.align || 'left'};
    background-color: ${props => props.warn ? inputWarnColor : props.bgColor || 'transparent'};
  }
`

const RegularInput = styled.input`
  outline: none;
  border: none;
  width: 100%;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  padding: 0 0.2rem;
  color: ${fontColor};
`
const Label = styled.div`
  border: none;
  width: 100%;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  padding: 0 0.2rem;
  color: ${fontColor};
`
const FakeInput = styled.input`
  display: none;
`

const asIsoString = str => str !== '' && moment(str, 'D.M.YYYY HH:mm').isValid ? moment(str, 'D.M.YYYY HH:mm').toISOString() : null
const formatDate = (isoStr, noTime) => isoStr != null ? moment(isoStr).format(noTime ? 'DD. MM. YYYY' : 'DD. MM. YYYY HH:mm [Uhr]') : ''

const setCursor = (el, start, end) => {
  if (el.setSelectionRange !== undefined) {
    el.setSelectionRange(start, end)
  } else {
    el.value = el.value + ''
  }
}

export default class NumberInput extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      focus: false,
      value: formatDate(props.value, props.noTime)
    }
  }

  componentWillReceiveProps (newProps) {
    this.setState({value: formatDate(newProps.value, newProps.noTime)})
  }

  onBlur = () => {
    if (this.state.dirty) this.props.onChange(asIsoString(this.state.value))
    this.setState({focus: false, dirty: false})
  }

  renderInput = () => {
    if (this.state.focus) {
      return <RegularInput
        warn={this.props.warn}
        placeholder={this.props.placeholder}
        label
        autoFocus
        value={this.state.value}
        onFocus={e => setCursor(e.target, 0, e.target.value.length)}
        onChange={e => this.setState({value: e.target.value, dirty: true})}
        onBlur={this.onBlur}
      />
    }
    return <Label onClick={() => this.setState({focus: true})}>{this.props.placeholder && `${this.props.placeholder}: `}{formatDate(this.props.value, this.props.noTime) || '--'}</Label>
  }

  render () {
    const {noAutoFill} = this.props
    return <Container {...omit(this.props, ['onChange', 'onFocus', 'onBlur'])}>
      {noAutoFill && <FakeInput size='0' {...omit(this.props, ['plain', 'align'])} />}
      {this.renderInput()}
    </Container>
  }
}
