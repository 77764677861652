import styled from 'styled-components'

export const Row = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 768px) {
    display: block;
  }
  flex-direction: row;
  flex-grow: ${props => props.grow ? 1 : 0};
  align-items: flex-start;
  > div {
    flex: 1;
  }
`

export default styled.div`
  min-height: calc(100% - 4rem);
  display: flex;
  @media (max-width: 768px) {
    display: block;
  }
  flex-direction: column;
`
