import React, {Component} from 'react'
import {connect} from 'react-redux'
import {common} from 'redux/modules'
import {Motion, spring} from 'react-motion'
import find from 'lodash/find'
import round from 'lodash/round'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'
import styled from 'styled-components'
import {BurgerIcon, LogoutIcon, HomeIcon} from 'components/Icons'
import {gray, fontColor} from 'styles/colors'
import apiService from 'lib/apiService'
import {DesktopOnly, MobileOnly} from 'components/MediaSwitch'

const Container = styled.div`
  height: 4rem;
  color: ${gray};
`

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    cursor: pointer;
    padding: 0.5rem 0;
    @media (min-width: 769px) {
      padding: 0.5rem 1.5rem;
    }
    font-size: 1.3rem;
  }
`

const Body = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 4rem;
  bottom: 0;
  z-index: 1000;
  @media (max-width: 768px) {
    overflow: scroll;
  }
`

const Content = styled.div`
  border-top-style: solid;
  border-top-width: 1px;
  overflow: hidden;
  margin: 0 4rem;
  @media (max-width: 768px) {
    margin: 0 2rem;
  }
`
const DesktopLogout = styled(DesktopOnly)`
  display: flex;
  align-items: flex-end;
`

const Section = styled.div`
  display: ${props => props.selected ? 'block' : 'none'};
  padding: 1.5rem 0;
  @media (max-width: 768px) {
    display: block;
    padding: 0;
  }
`

const Entry = styled(Link)`
  display: flex;
  flex-direction: row;
  height: 3.5rem;
  @media (max-width: 768px) {
    height: 2.5rem;
  }
  padding: 0.5rem 1.5rem;
  > div:first-child {
    width: 3.5rem;
    align-self: stretch;
    border-radius: 1rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      width: 2.5rem;
      border-radius: 0.5rem;
    }
  }
  > div:last-child {
    align-self: center;
    font-size: 1.2rem;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
    font-variant: small-caps;
    color: ${fontColor};
    padding-left: 1.5rem;
    flex: 1;
  }
`

class Menu extends Component {
  lastSelected = this.props.selected

  updateLocation = () => {
    this.props.setCurrentLocation(document.location.pathname)
  }

  componentWillMount () {
    const {setCurrentLocation, location} = this.props
    setCurrentLocation(location.pathname)
    window.onpopstate = this.updateLocation
  }

  componentWillReceiveProps (newProps) {
    const {setCurrentLocation, location} = this.props
    this.lastSelected = newProps.selected || this.lastSelected
    if (newProps.location.pathname !== location.pathname) {
      setCurrentLocation(newProps.location.pathname)
    }
  }

  render () {
    const {selected, openMenu, currentLocation, toggleMenu, internalMenu, currentMenu, mobileMenuHeight, authenticated} = this.props
    const currentRoute = currentLocation.match(/^\/(?:internal\/)?(\w*)/)[1] || 'home'
    const selectedMenuRoute = selected || currentRoute
    const routeMenu = find(currentMenu, {key: currentRoute}) || currentMenu[0]
    const selectedMenu = find(currentMenu, {key: selectedMenuRoute}) || routeMenu
    if (!selectedMenu) return null
    const tabStyle = {color: `rgba(${routeMenu.color.r},${routeMenu.color.g},${routeMenu.color.b},1`}
    let height
    if (selected === 'mobile') {
      height = mobileMenuHeight
    } else {
      height = selected ? selectedMenu.links.length * 4.5 + 3 : 0
    }
    return (
      <Container>
        <Tabs>
          {authenticated && <DesktopLogout onClick={apiService.logout}><LogoutIcon fill={gray} size='1.5rem' /></DesktopLogout>}
          <MobileOnly onClick={() => openMenu('mobile')}><BurgerIcon color={gray} fill={gray} /></MobileOnly>
          {currentMenu.map(section => section.key === 'home'
            ? <DesktopOnly
              style={{display: !section.label && 'none'}}
              key={section.key}
              onClick={() => openMenu()}>
              <Link to='/'>{section.label}</Link>
            </DesktopOnly>
            : <DesktopOnly
              key={section.key}
              onClick={() => openMenu(section.key)}
              style={{display: !section.label && 'none', ...(section.key === routeMenu.key ? tabStyle : {})}}>
              {section.label}
            </DesktopOnly>
          )}
          <div style={{flex: 1, cursor: 'inherit'}} />
          <div onClick={toggleMenu}>
            {internalMenu
              ? <Link to='/'>Extern</Link>
              : <Link to='/internal'>Intern</Link>
            }
          </div>
        </Tabs>
        <Motion
          defaultStyle={{height: 0, bgAlpha: 0, r: 0, g: 0, b: 0}}
          style={{
            height: spring(height),
            bgAlpha: spring(selected ? 0.9 : 0),
            r: spring(selectedMenu.color.r),
            g: spring(selectedMenu.color.g),
            b: spring(selectedMenu.color.b)
          }}>
          {is => {
            const color = `${round(is.r)},${round(is.g)},${round(is.b)}`
            return (
              <Body
                style={{
                  backgroundColor: `rgba(0,0,0,${is.bgAlpha})`,
                  pointerEvents: selected ? 'all' : 'none'
                }}
                onClick={() => openMenu('')}>
                <Content style={{
                  height: `${is.height}rem`,
                  backgroundColor: `rgba(${color},0.3)`,
                  borderTopColor: `rgba(${color},1)`
                }}>
                  <MobileOnly>
                    <Entry to={internalMenu ? '/internal' : '/'}>
                      <div><HomeIcon fill={fontColor} size='1.5rem' /></div>
                      <div>Home</div>
                    </Entry>
                  </MobileOnly>
                  {currentMenu.map(section =>
                    <Section key={section.key} selected={this.lastSelected === section.key}>
                      {section.links.map(link =>
                        <Entry key={link.route} to={link.route}>
                          <div>
                            {link.icon}
                            {link.imgSrc && <img height='100%' width='100%' src={link.imgSrc} alt={link.label} />}
                          </div>
                          <div>{link.label}</div>
                        </Entry>
                      )}
                    </Section>
                  )}
                  {authenticated &&
                    <MobileOnly onClick={apiService.logout}>
                      <Entry to='/'>
                        <div><LogoutIcon fill={fontColor} size='1.5rem' /></div>
                        <div>Logout</div>
                      </Entry>
                    </MobileOnly>}
                </Content>
              </Body>
            )
          }}
        </Motion>
      </Container>
    )
  }
}

export default connect(
  state => ({
    currentMenu: state.common.menuItems,
    currentLocation: state.common.currentLocation || '/',
    mobileMenuHeight: state.common.mobileMenuHeight,
    selected: state.common.showMenuBody,
    internalMenu: state.common.internalMenu,
    authenticated: state.common.authenticated
  }),
  dispatch => ({
    setCurrentLocation: path => dispatch(common.actions.setCurrentLocation(path)),
    openMenu: id => dispatch(common.actions.toggleMenuBody(id)),
    toggleMenu: () => dispatch(common.actions.toggleMenu()),
    setMenu: internal => dispatch(common.actions.setMenu(internal))
  })
)(withRouter(Menu))
