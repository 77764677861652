import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`

const Content = styled.div`
  position: absolute;
  z-index: 1000;
  margin-top: -2rem;
  box-shadow: 0px 0px 17px 8px black;
  padding: 0.5rem;
  max-height: 5rem;
  overflow-y: scroll;
  background-color: white;
  border-radius: 0.5rem;
  color: black;
  min-width: 10rem;
`

const Item = styled.div`
  cursor: pointer;
  padding: 0.3rem 0;
  &:hover {
    background-color: gray;
  }
`

export default ({mentions, onSelect}) =>
  <Container>
    <Content>
      {mentions.map((str) => <Item key={str} onMouseDown={() => onSelect(str)}>@{str}</Item>)}
    </Content>
  </Container>
