import React from 'react'
import styled from 'styled-components'
import InlineSvg from 'react-inlinesvg'
import omit from 'lodash/omit'
import pick from 'lodash/pick'

const StyledIcon = styled(InlineSvg)`
  height: ${props => props.size || '2rem'};
  width: ${props => props.size || '2rem'};
  display: inline-block;
  svg {
    stroke: ${props => props.color || 'none'};
    stroke-width: 0.05rem;
    fill: ${props => props.fill || 'none'};
    height: 100%;
    width: 100%;
    path {
      vector-effect: non-scaling-stroke;
    }
  }
`

const Label = styled.span`
  margin-left: 0.3rem;
  color: ${props => props.textColor || 'inherit'};
  font-size: 0.9rem;
  font-weight: bold;
  align-self: center;
`

const Container = styled.div`
  display: inline-flex;
  cursor: ${props => props.onClick ? 'pointer' : 'inherit'};
  pointer-events: ${props => props.disabled ? 'none' : 'all'};
`

const createIcon = (src) => (props) =>
  <Container {...omit(props, ['color', 'fill', 'textColor', 'size'])}>
    <StyledIcon {...pick(props, ['size', 'color', 'fill'])} src={src} cacheGetRequests />
    <Label textColor={props.textColor}>{props.children}</Label>
  </Container>

export const TrashIcon = createIcon(require('icons/bin2.svg'))
export const EditIcon = createIcon(require('icons/pencil.svg'))
export const KeyIcon = createIcon(require('icons/key.svg'))
export const BurgerIcon = createIcon(require('icons/burger.svg'))
export const MailIcon = createIcon(require('icons/envelop.svg'))
export const MusicIcon = createIcon(require('icons/music.svg'))
export const UserIcon = createIcon(require('icons/user.svg'))
export const UsersIcon = createIcon(require('icons/users.svg'))
export const UserAddIcon = createIcon(require('icons/user-plus.svg'))
export const LogoutIcon = createIcon(require('icons/enter.svg'))
export const PlayIcon = createIcon(require('icons/play2.svg'))
export const PauseIcon = createIcon(require('icons/pause.svg'))
export const StopIcon = createIcon(require('icons/stop.svg'))
export const HomeIcon = createIcon(require('icons/home3.svg'))
export const FilesIcon = createIcon(require('icons/files-empty.svg'))
export const ScoreIcon = createIcon(require('icons/file-music.svg'))
export const TeachMeIcon = createIcon(require('icons/file-play.svg'))
export const SpeechIcon = createIcon(require('icons/bubbles4.svg'))
export const CommentIcon = createIcon(require('icons/bubble2.svg'))
export const ImageIcon = createIcon(require('icons/image.svg'))
export const NewspaperIcon = createIcon(require('icons/newspaper.svg'))
export const CalendarIcon = createIcon(require('icons/calendar.svg'))
export const CheckedIcon = createIcon(require('icons/checkbox-checked.svg'))
export const UncheckedIcon = createIcon(require('icons/checkbox-unchecked.svg'))
export const InfoIcon = createIcon(require('icons/info.svg'))
export const SendIcon = createIcon(require('icons/circle-right.svg'))
export const ChangeIcon = createIcon(require('icons/embed2.svg'))
export const CloseIcon = createIcon(require('icons/cross.svg'))
export const SmileyIcon = createIcon(require('icons/smile.svg'))
export const DownIcon = createIcon(require('icons/down.svg'))
export const UpIcon = createIcon(require('icons/up.svg'))
export const EyeIcon = createIcon(require('icons/eye.svg'))
export const EyeCrossedIcon = createIcon(require('icons/eye-blocked.svg'))
export const EuroIcon = createIcon(require('icons/coin-euro.svg'))
export const FacebookIcon = createIcon(require('icons/facebook-official.svg'))
export const YoutubeIcon = createIcon(require('icons/youtube.svg'))
export const InstagramIcon = createIcon(require('icons/instagram.svg'))
