import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {DesktopOnly} from './MediaSwitch'

export const List = styled.div`
  display: table;
  width: 100%;
  border-spacing: 0 0.5rem;
  @media (max-width: 768px) {
    display: block;
  }
`

export const ListRow = styled.div`
  display: table-row;
  cursor: ${props => props.onClick ? 'pointer' : 'inherit'};
  opacity: ${props => props.faded ? 0.5 : 1};
  @media (max-width: 768px) {
    border-radius: 1rem;
    overflow: hidden;
    display: block;
    text-align: center;
    margin-bottom: 0.5rem;
  }
`
const ListCellMixin = props => `
  display: table-cell;
  white-space: ${props.nowrap ? 'nowrap' : 'inherit'};
  padding: 0.5rem 1rem;
  vertical-align: middle;
  background-color: ${props.color || 'transparent'};
  text-align: ${props.align || 'left'};
  font-style: ${props.italic ? 'italic' : 'inherit'};
  @media (max-width: 768px) {
    display: flex;
    justify-content: ${props.controls || props.single ? 'space-around' : 'space-between'};
    padding: 0.2rem 1rem;
    ${props.title ? `
      padding-bottom: 0.3rem;
      font-size: 1.2rem;
      font-variant: small-caps;
    ` : ''}
    ${props.controls ? 'padding-top: 0.5rem;' : ''}
  }
  &:first-child {
    @media (min-width: 769px) {
      border-radius: 1rem 0 0 1rem;
    } 
    @media (max-width: 768px) {
      padding-top: 0.5rem;
    }
  }
  &:last-child {
    @media (min-width: 769px) {
      border-radius: 0 1rem 1rem 0;
    }
    @media (max-width: 768px) {
      padding-bottom: 0.5rem;
    }
  }
`

export const ListCell = styled.div`
  ${ListCellMixin}
  > audio {
    display: flex;
    margin: auto;
  }
`

export const ListCellLink = styled(Link)`
  ${ListCellMixin}
`

export const ListCellExternalLink = styled.a`
  ${ListCellMixin}
`

export const ListHeader = styled(DesktopOnly)`
  display: table-header-group;
  > div {
    display: table-cell;
    text-align: center;
    font-variant: small-caps;
  }
`

export const RowGroup = styled.div`
  display: table-row-group;
  @media (max-width: 768px) {
    display: block;
  }
`
