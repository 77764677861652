import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import styled from 'styled-components'
import {connect} from 'react-redux'
import {common} from 'redux/modules'
import {darkInternalEventsColor, red, green, gray, fontColor} from 'styles/colors'
import moment from 'moment'
import {EVENT_MANAGER, hasPermission} from 'lib/roles'
import round from 'lodash/round'
import filter from 'lodash/filter'
import reject from 'lodash/reject'
import nth from 'lodash/nth'
import debounce from 'lodash/debounce'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import keyBy from 'lodash/keyBy'
import {MobileOnly} from 'components/MediaSwitch'
import {SpeechIcon, CheckedIcon, UncheckedIcon, CommentIcon} from 'components/Icons'
import TextInput from 'components/TextInput'
import Button from 'components/Button'
import InputLabel from 'components/InputLabel'
import TextareaAutosize from 'react-textarea-autosize'
import {List, ListRow, ListCell, ListHeader, RowGroup} from 'components/List'

const states = {
  absent: {
    label: 'Ich kann nicht kommen',
    color: red
  },
  present: {
    label: 'Ich komme',
    color: green
  },
  undecided: {
    label: 'Noch nicht entschieden',
    color: gray
  },
  confirmed: {
    label: 'Ich war da',
    color: green
  },
  notConfirmed: {
    label: 'Ich war nicht da',
    color: red
  }
}

const newEventPrefill = {
  rehearsal: 'Probe',
  concert: 'Konzert',
  trip: 'Chorfahrt'
}

const stateToggleSequence = [
  'absent', 'undecided', 'present'
]

const OwnState = styled.span`
  display: inline-block;
  background-color: ${props => (states[props.state] && states[props.state].color) || 'black'};
  padding: 0rem 0.5rem;
  border-radius: 0.7rem;
  cursor: pointer;
  pointer-events: ${props => props.disabled ? 'none' : 'all'};
`

const Comment = styled(TextareaAutosize)`
  background-color: ${fontColor};
  border-radius: 0.5rem 0.5rem 0.5rem 0;
  padding: 0.2rem;
  color: black;
  outline: none;
  resize: none;
  border: none;
  text-align: center;
  width: calc(100% - 0.4rem);
  max-width: 15rem;
  box-shadow: 3px 3px 7px rgba(255, 255, 255, 0.4);
`
const Comments = styled.span`
  margin-left: 0.2rem;
`

const NewEvent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin-top: 1.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  > div:first-child {
    min-width: 20rem;
    padding: 0.5rem;
    box-sizing: border-box;
  }
  > div:last-child {
    @media (max-width: 768px) {
      width: 80%;
    }
  }
  > * {
    @media (max-width: 768px) {
      min-width: 0;
      max-width: 80%;
    }
  }
`

const LabelledCheckbox = styled.div`
  display: flex;
  align-items: center;
  > *:first-child {
    margin-right: 1rem;
  }
`

const sanitizeEvents = (events, users) => {
  if (!users) return events
  const usersById = keyBy(reject(users, {group: 'none'}), '_id')
  return events.map(e => ({
    ...e,
    entries: pickBy(e.entries, (val, id) => usersById[id])
  }))
}

class EventList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      newEventPresencePoll: true,
      newEventDate: props.type === 'trip'
        ? `${moment().minutes(0).format('DD.MM.YYYY')} - ${moment().minutes(0).add(1, 'day').format('DD.MM.YYYY')}`
        : moment().minutes(0).format('DD. MM. YYYY HH:mm'),
      newEventDeadline: '',
      newEventModified: false,
      events: sanitizeEvents(props.events, props.users),
      newEventTitle: newEventPrefill[props.type]
    }
  }

  componentWillMount () {
    if (!this.props.users) {
      this.props.loadUsers()
    }
  }

  componentWillReceiveProps (newProps) {
    if (newProps.events !== this.props.events || newProps.users !== this.props.users) {
      this.setState({events: sanitizeEvents(newProps.events, newProps.users)})
    }
  }

  instantlySaveOwnState = (eventId, entry) => {
    entry.pendingUpdate = true
    this.props.upsertEntry(eventId, pick(entry, ['state', 'comment', 'userId']))
    this.forceUpdate()
  }

  saveOwnState = debounce(this.instantlySaveOwnState, 2000)

  onChangeComment = (event, userId, comment) => {
    if (event.entries[userId].pendingUpdate) return
    event.entries[userId].comment = comment
    this.saveOwnState(event._id, event.entries[userId])
    this.forceUpdate()
  }

  onClickState = (event, userId) => {
    if (event.deadline && moment(event.deadline).isBefore(moment())) return
    if (event.entries[userId] && event.entries[userId].pendingUpdate) return
    event.entries[userId] = event.entries[userId] || {userId, state: 'absent'}
    const nextStateIndex = stateToggleSequence.indexOf(event.entries[userId].state) - 1
    event.entries[userId].state = nth(stateToggleSequence, nextStateIndex)
    this.instantlySaveOwnState(event._id, event.entries[userId])
  }

  addComment = (event, userId) => {
    event.entries[userId] = event.entries[userId] || {userId, state: 'absent'}
    event.entries[userId].selected = true
    this.forceUpdate()
  }

  onBlurredComment = (event, userId) => {
    delete event.entries[userId].selected
    this.forceUpdate()
  }

  onAddEvent = () => {
    let date = false
    let endDate = false
    let deadline = false
    if (this.props.type === 'trip') {
      date = moment(this.state.newEventDate.split('-')[0], 'D.M.YYYY')
      endDate = moment(this.state.newEventDate.split('-')[1], 'D.M.YYYY')
    } else {
      date = moment(this.state.newEventDate, 'D.M.YYYY HH:mm')
    }
    if (this.state.newEventDeadline) deadline = moment(this.state.newEventDeadline, 'D.M.YYYY HH:mm')
    if (!date.isValid() || (endDate && !endDate.isValid()) || (deadline && !deadline.isValid())) {
      return this.setState({newEventDate: '', deadline: ''})
    }
    if (this.props.type === 'trip') {
      date = date.format('YYYY-MM-DD')
      endDate = endDate.format('YYYY-MM-DD')
    }
    this.props.createEvent(pickBy({
      title: this.state.newEventTitle,
      type: this.props.type,
      date,
      endDate,
      deadline,
      withoutPresencePoll: !this.state.newEventPresencePoll
    }))
    this.setState({newEventModified: false})
  }

  getDeadlineValue (deadline) {
    if (!deadline) return '---'
    if (moment(deadline).isBefore(moment())) return 'vorbei - Mail an Karin'
    return moment(deadline).fromNow()
  }

  render () {
    const {users, user, type} = this.props
    const userId = user._id
    const isManager = hasPermission([EVENT_MANAGER], user.roles)
    const isParticipant = user.group !== 'none'
    if (!users || !this.state.events) return null
    if (this.state.redirect) return <Redirect push to={`/internal/events/${this.state.redirect}`} />
    return (
      <div>
        <List>
          <ListHeader>
            <div>Datum</div>
            <div>Eintragen bis</div>
            <div>Name</div>
            <div>Anwesenheit</div>
            <div>Status</div>
            <div>Kommentar</div>
          </ListHeader>
          <RowGroup>
            {this.state.events.map(event => {
              const totalUsers = filter(this.props.users, user => {
                return user.group !== 'none' && user.created < event.date
              }).length
              const pastRehearsal = event.date < moment().toISOString()
              const presentUsers = pastRehearsal
                ? filter(event.entries, 'confirmed').length
                : filter(event.entries, {state: 'present'}).length
              const countComments = filter(event.entries, 'comment').length
              const ownState = pastRehearsal
                ? (event.entries[userId] && event.entries[userId].confirmed && 'confirmed') || 'notConfirmed'
                : (event.entries[userId] && event.entries[userId].state) || 'absent'
              return <ListRow key={event._id} faded={pastRehearsal} onClick={() => this.setState({redirect: event._id})}>
                <ListCell title color={darkInternalEventsColor} align='center'>
                  {type === 'trip'
                    ? `${moment(event.date).format('DD. MM. YYYY')} – ${moment(event.endDate).format('DD. MM. YYYY')}`
                    : moment(event.date).format('DD. MM. YYYY HH:mm [Uhr]')
                  }
                </ListCell>
                <ListCell color={darkInternalEventsColor} align='center'>
                  <MobileOnly>Eintragen bis:</MobileOnly>
                  {this.getDeadlineValue(event.deadline)}
                </ListCell>
                <ListCell color={darkInternalEventsColor}>
                  {event.title}
                </ListCell>
                {(!event.withoutPresencePoll &&
                  <ListCell color={darkInternalEventsColor} align='center'>
                    <MobileOnly>Anwesenheit:</MobileOnly>
                    <div>
                      <span>{round((presentUsers / totalUsers) * 100)} %</span>
                      {countComments > 0 && <Comments><SpeechIcon fill={fontColor} size='0.8rem' />: {countComments}</Comments>}
                    </div>
                  </ListCell>) || <ListCell color={darkInternalEventsColor} align='center'>---</ListCell>
                }
                {(isParticipant && !event.withoutPresencePoll &&
                  <ListCell color={darkInternalEventsColor} align='center' onClick={e => e.stopPropagation()}>
                    <OwnState
                      state={ownState}
                      onClick={() => this.onClickState(event, userId)}
                      disabled={pastRehearsal}>{states[ownState] && states[ownState].label}</OwnState>
                  </ListCell>) || <ListCell color={darkInternalEventsColor} align='center'>---</ListCell>
                }
                {(isParticipant && !event.withoutPresencePoll &&
                  <ListCell color={darkInternalEventsColor} align='center' onClick={e => e.stopPropagation()}>
                    {event.entries[userId] && (event.entries[userId].comment || event.entries[userId].selected)
                      ? <Comment
                        value={event.entries[userId].comment}
                        disabled={pastRehearsal}
                        autoFocus={event.entries[userId].selected}
                        onBlur={() => this.onBlurredComment(event, userId)}
                        onChange={(e) => this.onChangeComment(event, userId, e.target.value)} />
                      : <CommentIcon
                        fill={fontColor} size='1rem'
                        disabled={pastRehearsal}
                        onClick={() => this.addComment(event, userId)} />
                    }
                  </ListCell>) || <ListCell color={darkInternalEventsColor} align='center'>---</ListCell>
                }
              </ListRow>
            })}
          </RowGroup>
        </List>
        {isManager &&
          <NewEvent>
            <TextInput
              value={this.state.newEventTitle}
              onChange={e => this.setState({newEventTitle: e.target.value})}
              plain
              placeholder='Neuen Termin anlegen' />
            <TextInput
              value={this.state.newEventDate}
              onChange={e => this.setState({newEventDate: e.target.value, newEventModified: true})}
              placeholder={type === 'trip' ? 'TT.MM.YYYY - TT.MM.YYYY' : 'TT.MM.YYYY hh:mm'}
              align='center'
              plain />
            <TextInput
              value={this.state.newEventDeadline}
              onChange={e => this.setState({newEventDeadline: e.target.value, newEventModified: true})}
              placeholder='Deadline'
              align='center'
              plain />
            <LabelledCheckbox>
              <InputLabel>Anwesenheitsabfrage</InputLabel>
              {this.state.newEventPresencePoll
                ? <CheckedIcon size='1.5rem' fill={fontColor} onClick={() => this.setState({newEventPresencePoll: false})} />
                : <UncheckedIcon size='1.5rem' fill={gray} onClick={() => this.setState({newEventPresencePoll: true})} />
              }
            </LabelledCheckbox>
            <Button
              disabled={!this.state.newEventModified || !this.state.newEventTitle.trim() || !this.state.newEventDate.trim()}
              onClick={this.onAddEvent}>Anlegen</Button>
          </NewEvent>
        }
      </div>
    )
  }
}
export default connect(
  state => ({
    user: state.common.authenticated,
    users: state.common.users
  }),
  dispatch => ({
    loadUsers: () => dispatch(common.actions.loadUsers()),
    upsertEntry: (eventId, entry) => dispatch(common.actions.upsertEventEntry(eventId, entry)),
    createEvent: event => dispatch(common.actions.createEvent(event))
  })
)(EventList)
