import React from 'react'
import styled from 'styled-components'
import {CloseIcon} from 'components/Icons'

const availableEmojis = ['😀', '😬', '😁', '😂', '😃', '😄', '😅', '😆', '😇', '😉', '😊', '🙂', '🙃', '😋', '😌', '😍', '😘', '😗', '😙', '😚', '😜', '😝', '😛', '🤑', '🤓', '😎', '🤗', '😏', '😶', '😐', '😑', '😒', '🙄', '🤔', '😳', '😞', '😟', '😠', '😡', '😔', '😕', '🙁', '😣', '😖', '😫', '😩', '😤', '😮', '😱', '😨', '😰', '😯', '😦', '😧', '😢', '😥', '😪', '😓', '😭', '😵', '😲', '🤐', '😷', '🤒', '🤕', '😴', '💤', '💩', '😈', '👿', '👹', '👺', '💀', '👻', '👽', '🤖', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '😿', '😾', '🙌', '👏', '👋', '👍', '👊', '✊', '👌', '✋', '💪', '🙏', '👆', '👇', '👈', '👉', '🖕', '🤘', '🖖', '💅', '👄', '👅', '👂', '👃', '👁', '👀', '👤', '🗣', '👶', '👦', '👧', '👩', '👱', '👴', '👵', '👲', '👳', '👮', '👷', '💂', '🕵', '🎅', '👼', '👸', '👰', '🚶', '🏃', '💃', '👯', '👫', '👬', '👭', '🙇', '💁', '🙅', '🙆', '🙋', '🙎', '🙍', '💇', '💆', '💑', '👩', '👨', '👪', '👧', '👦', '👚', '👕', '👖', '👔', '👗', '👙', '👘', '💄', '💋', '👣', '👠', '👡', '👢', '👞', '👟', '👒', '🎩', '⛑', '🎓', '👑', '🎒', '👝', '👛', '👜', '💼', '👓', '🕶', '💍', '🌂']

const Container = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  background-color: rgba(0,0,0,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`
const Content = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
  align-self: stretch;
`
const CloseButton = styled(CloseIcon)`
  justify-content: flex-end;
  margin-bottom: 0.7rem;
`

const Emojis = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: "EmojiOne Color", sans-serif;
  font-size: 1.5rem;
  line-height: 2rem;
  color: black;
`
const Emoji = styled.div`
  cursor: pointer;
`

export default ({onClose, onSelect}) => <Container>
  <Content>
    <CloseButton fill='black' size='1rem' onClick={() => onClose()} />
    <Emojis>
      {availableEmojis.map((emoji, index) =>
        <Emoji key={index}
          onClick={() => onSelect(emoji)}
        >{emoji}</Emoji>)}
      <div style={{flex: '1'}} />
    </Emojis>
  </Content>
</Container>
