import styled from 'styled-components'

export default styled.h1`
  font-size: ${props => props.small ? '1.2rem' : '1.4rem'};
  font-weight: 800;
  letter-spacing: ${props => props.small ? '0.2rem' : '0.4rem'};
  font-variant: small-caps;
  text-align: ${props => props.centered ? 'center' : 'left'};
  margin-top: ${props => props.small ? '1rem' : '1.5rem'};
  margin-bottom: ${props => props.small ? '0.5rem' : '1rem'};
  font-style: ${props => props.italic ? 'italic' : 'inherit'};
  color: ${props => props.color || 'inherit'};
`
