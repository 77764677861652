import React, {Component} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import {common} from 'redux/modules'
import {EVENT_MANAGER, EVENT_SUPERVISOR, TICKET_MANAGER, hasPermission} from 'lib/roles'
import Page, {LeftCol, RightCol} from 'components/TwoColPage'
import CenteredPage from 'components/CenteredPage'
import PageHeader from 'components/PageHeader'
import ReactMarkdown from 'react-markdown'
import SmallEventLink from 'components/SmallEventLink'
import EventAnnouncement from 'components/EventAnnouncement'
import Button from 'components/Button'
import NumberInput from 'components/NumberInput'
import DateInput from 'components/DateInput'
import MarkdownHelp from './MarkdownHelp'
import {EditIcon, InfoIcon, CheckedIcon, UncheckedIcon, TrashIcon, ChangeIcon} from 'components/Icons'
import InputLabel from 'components/InputLabel'
import Color from 'color'
import {internalEventsColor, fontColor, red, green, gray, blue} from 'styles/colors'
import find from 'lodash/find'
import size from 'lodash/size'
import compact from 'lodash/compact'
import groupBy from 'lodash/groupBy'
import reject from 'lodash/reject'
import pickBy from 'lodash/pickBy'
import debounce from 'lodash/debounce'
import pick from 'lodash/pick'
import nth from 'lodash/nth'
import groups from 'lib/groups'
import moment from 'moment'

const SingerGroup = styled.div`
  border: 2px solid ${fontColor};
  border-radius: 1rem;
  padding: 0 1rem 1rem 1rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    border: none;
    padding: 0;
  }
`

const entryColors = {
  present: green,
  absent: red,
  undecided: gray
}

const UserEntry = styled.div`
  display: flex;
  padding: 0.5rem;
  background-color: ${props => props.state && entryColors[props.state]};
  border-radius: 0.5rem;
  margin-bottom: 0.3rem;
  min-height: 1.5rem;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    min-height: 0;
  }
`

const Comment = styled.div`
  flex: 1;
  > div {
    display: inline-block;
    background-color: ${fontColor};
    border-radius: 0.5rem 0.5rem 0.5rem 0;
    padding: 0.2rem;
    color: black;
    text-align: center;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.4);
  }
`
const Name = styled.div`
  min-width: 10rem;
  @media (max-width: 768px) {
    text-align: center;
  }
`
const inputBgColor = Color(internalEventsColor).fade(0.7).string()
const inputWarnColor = Color(red).fade(0.5).string()
const HeadlineInput = styled.input`
  font-size: 1.2rem;
  font-weight: 800;
  letter-spacing: 0.2rem;
  font-variant: small-caps;
  color: ${internalEventsColor};
  background-color: ${props => props.warn ? inputWarnColor : inputBgColor};
  outline: none;
  border: none;
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 0 0.2rem;
`
const RegularInput = styled.input`
  background-color: ${props => props.warn ? inputWarnColor : inputBgColor};
  outline: none;
  border: none;
  width: 100%;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  padding: 0 0.2rem;
  color: ${fontColor};
`
const RegularInputArea = styled.textarea`
  background-color: ${props => props.warn ? inputWarnColor : inputBgColor};
  outline: none;
  border: none;
  width: 100%;
  resize: none;
  height: 10rem;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  padding: 0 0.2rem;
  color: ${fontColor};
`
const LabelledCheckbox = styled.div`
  display: flex;
  align-items: center;
  > *:first-child {
    margin-right: 1rem;
  }
  margin-bottom: 0.3rem;
`
const Text = styled(ReactMarkdown)`
  margin-bottom: 0.5rem;
  a {
    color: ${internalEventsColor};
    text-decoration: underline;
  }
`
const Subtitle = styled.div`
  margin-bottom: 0.5rem;
  font-style: italic;
`
const Previews = styled.div`
  font-size: 0.9rem;
  @media (min-width: 769px) {
    margin-top: 6rem;
  }
`
const Controls = styled.div`
  > * {
    margin-right: 0.5rem;
  }
`
const Confirmation = styled.div`
  @media (max-width: 768px) {
    margin-top: 0.7rem;
  }
`
const UndeleteButton = styled(Button)`
  max-width: 12rem;
`
const TicketSaleNumberInput = styled(NumberInput)`
  width: 100%;
  display: block;
  margin-bottom: 0.5rem;
`

const TicketSaleDateInput = styled(DateInput)`
  width: 100%;
  display: block;
  margin-bottom: 0.5rem;
`

const stateToggleSequence = [
  'absent', 'undecided', 'present'
]

class EventDetails extends Component {
  state = {groupedUsers: {}}

  constructor (props) {
    super(props)
    this.state = {
      groupedUsers: props.users ? groupBy(props.users, 'group') : {},
      event: props.event,
      edit: false
    }
  }

  componentDidMount () {
    this.autoRefreshInterval = setInterval(this.autoRefreshEvent, 60000)
    this.autoRefreshEvent()
    if (!this.props.users) this.props.loadUsers()
    if (!this.props.events) {
      this.props.loadEvents()
    }
  }

  componentWillReceiveProps (newProps) {
    const newState = pickBy({
      groupedUsers: newProps.users !== this.props.users && groupBy(newProps.users, 'group'),
      event: newProps.event
    })
    this.setState(newState)
  }

  componentWillUnmount () {
    clearInterval(this.autoRefreshInterval)
    this.autoRefreshEvent.cancel()
    this.autoCloseEdit.cancel()
  }

  getUserState = (user, pastEvent) => {
    const event = this.state.event
    if (event.entries[user._id] && event.entries[user._id].confirmed) return 'present'
    if (pastEvent) return (event.entries[user._id] && event.entries[user._id].confirmed) ? 'present' : 'absent'
    if (!event.entries[user._id]) return 'absent'
    return event.entries[user._id].state
  }

  saveOnBlur = () => {
    this.setState(({event: {...this.state.event, pendingUpdate: true}}))
    this.props.updateEvent(this.state.event._id, pick(this.state.event, ['title', 'subtitle', 'place', 'text', 'public', 'publicNotes', 'deleted']))
  }

  autoCloseEdit = debounce(() => {
    this.setState({edit: false})
  }, 45000)

  autoRefreshEvent = debounce(() => { if (!this.state.edit) this.props.refreshEvent(this.state.event._id) }, 60000)

  onChangeEvent = (update, save = false) => {
    this.setState({event: {...this.state.event, ...update}}, () => {
      if (save) this.saveOnBlur()
    })
    this.autoCloseEdit()
  }

  onChangeTickets = (update) => {
    this.setState({event: {...this.state.event, ticketSale: update ? {...this.state.event.ticketSale, ...update} : null}}, () => {
      if (!update || size(update)) this.props.updateTicketSale(this.state.event._id, this.state.event.ticketSale)
    })
    this.autoCloseEdit()
  }

  confirmPresence = (userId, confirmationState) => {
    const event = this.state.event
    if (event.pendingUpdate) return
    event.pendingUpdate = true
    event.entries[userId] = event.entries[userId] || {userId, state: this.props.type === 'rehearsal' ? 'present' : 'undecided'}
    event.entries[userId].confirmed = confirmationState
    this.props.upsertEntry(event._id, event.entries[userId])
    this.forceUpdate()
  }
  togglePresence = (userId) => {
    const event = this.state.event
    if (event.entries[userId] && event.entries[userId].pendingUpdate) return
    event.entries[userId] = event.entries[userId] || {userId, state: event.type === 'rehearsal' ? 'present' : 'undecided'}
    const nextStateIndex = stateToggleSequence.indexOf(event.entries[userId].state) - 1
    event.entries[userId].state = nth(stateToggleSequence, nextStateIndex)
    event.entries[userId].pendingUpdate = true
    this.props.upsertEntry(this.state.event._id, pick(event.entries[userId], ['state', 'comment', 'userId']))
    this.forceUpdate()
  }

  renderDeletedView = (isManager) =>
    <CenteredPage>
      <div>Dieser Termin wurde gelöscht.</div>
      {isManager && <UndeleteButton onClick={() => this.onChangeEvent({deleted: false}, true)}>Wiederherstellen</UndeleteButton>}
    </CenteredPage>

  renderEditView = (isEventManager, isTicketManager) => {
    const event = this.state.event
    return <div>
      <PageHeader color={internalEventsColor}>{moment(event.date).format('DD. MM. YYYY HH:mm [Uhr]')}</PageHeader>
      {isEventManager &&
        <div>
          <HeadlineInput warn={!event.title} value={event.title || ''} onChange={e => this.onChangeEvent({title: e.target.value})} onBlur={this.saveOnBlur} />
          {event.type !== 'rehearsal' && <div>
            <RegularInput
              warn={event.public && !event.subtitle}
              placeholder='Untertitel' value={event.subtitle || ''}
              onChange={e => this.onChangeEvent({subtitle: e.target.value})}
              onBlur={this.saveOnBlur}
            />
            <RegularInput
              warn={event.public && !event.place}
              placeholder='Ort' value={event.place || ''}
              onChange={e => this.onChangeEvent({place: e.target.value})}
              onBlur={this.saveOnBlur}
            />
          </div>}
          <RegularInputArea
            placeholder='Interne Infos (Setliste, Probenplan etc.)'
            value={event.publicNotes || ''}
            onChange={e => this.onChangeEvent({publicNotes: e.target.value})}
            onBlur={this.saveOnBlur}
          />
        </div>
      }
      {event.type === 'concert' && isEventManager &&
        <div>
          <LabelledCheckbox>
            <InputLabel>Öffentlich sichtbar</InputLabel>
            {event.public
              ? <CheckedIcon size='1.2rem' fill={fontColor} onClick={() => this.onChangeEvent({public: false}, true)} />
              : <UncheckedIcon size='1.2rem' fill={gray} onClick={() => this.onChangeEvent({public: true}, true)} />
            }
          </LabelledCheckbox>
          {event.public &&
            <RegularInputArea
              warn={!event.text}
              placeholder='Beschreibungstext'
              value={event.text || ''}
              onChange={e => this.onChangeEvent({text: e.target.value})}
              onBlur={this.saveOnBlur}
            />
          }
        </div>
      }
      {isTicketManager &&
        <LabelledCheckbox>
          <InputLabel>Kartenverkauf</InputLabel>
          {event.ticketSale
            ? <CheckedIcon size='1.2rem' fill={fontColor} onClick={() => this.onChangeTickets()} />
            : <UncheckedIcon size='1.2rem' fill={gray} onClick={() => this.onChangeTickets({})} />
          }
        </LabelledCheckbox>
      }
      {event.ticketSale && isTicketManager &&
      <div>
        <TicketSaleNumberInput
          warn={!event.ticketSale.priceRegular && !event.ticketSale.priceDiscount}
          placeholder='Kartenpreis regulär'
          value={event.ticketSale.priceRegular}
          precision={2}
          unit='€'
          plain
          bgColor={inputBgColor}
          onChange={value => this.onChangeTickets({priceRegular: value})}
        />
        <TicketSaleNumberInput
          warn={!event.ticketSale.priceRegular && !event.ticketSale.priceDiscount}
          placeholder='Kartenpreis ermäßigt'
          value={event.ticketSale.priceDiscount}
          precision={2}
          unit='€'
          plain
          bgColor={inputBgColor}
          onChange={value => this.onChangeTickets({priceDiscount: value})}
        />
        <TicketSaleNumberInput
          placeholder='Kontingent'
          value={event.ticketSale.quota}
          unit='Karten'
          plain
          bgColor={inputBgColor}
          onChange={value => this.onChangeTickets({quota: value})}
        />
        <TicketSaleDateInput
          placeholder='Verkaufsende'
          value={event.ticketSale.deadline}
          plain
          bgColor={inputBgColor}
          onChange={value => this.onChangeTickets({deadline: value})}
        />
      </div>
      }
    </div>
  }

  renderDefaultView = (event) => {
    return <div>
      {event.type === 'trip'
        ? <PageHeader color={internalEventsColor}>{`${moment(event.date).format('DD. MM. YYYY')} – ${moment(event.endDate).format('DD. MM. YYYY')}`}</PageHeader>
        : <PageHeader color={internalEventsColor}>{moment(event.date).format('DD. MM. YYYY HH:mm [Uhr]')}</PageHeader>
      }
      <PageHeader small color={internalEventsColor}>{event.title}</PageHeader>
      {event.subtitle && <Subtitle>{event.subtitle}</Subtitle>}
      {event.place && <p>{event.place}</p>}
      {event.public && !event.place && <p>ORT FEHLT</p>}
      {event.publicNotes && <Text source={event.publicNotes} escapeHtml />}
      {event.text && <Text source={event.text} />}
      {event.public && !event.text && <p>ÖFFENTLICHE BESCHREIBUNG FEHLT</p>}
    </div>
  }

  render () {
    const isEventManager = hasPermission([EVENT_MANAGER], this.props.user.roles)
    const isTicketManager = hasPermission([TICKET_MANAGER], this.props.user.roles)
    const {event, edit, groupedUsers, showMarkdownInfo} = this.state
    if (!event) return null
    if (event.deleted) return this.renderDeletedView(isEventManager)
    const now = moment()
    const pastEvent = now.isAfter(moment(event.date).add(10, 'minutes'))
    const enableConfirmation = hasPermission([EVENT_SUPERVISOR], this.props.user.roles) &&
      now.isAfter(moment(event.date).subtract(30, 'minutes'))
    return <Page rightMargin>
      <LeftCol>
        {(isEventManager || isTicketManager) &&
          <Controls>
            <EditIcon
              size='1.5rem'
              fill={edit ? blue : gray}
              onClick={() => this.setState({edit: !edit, showMarkdownInfo: false})}
            />
            {edit && isEventManager &&
              <InfoIcon
                size='1.5rem'
                fill={showMarkdownInfo ? blue : gray}
                textColor={showMarkdownInfo ? blue : gray}
                onClick={() => this.setState({showMarkdownInfo: !showMarkdownInfo})}
              >Markdown Hilfe anzeigen</InfoIcon>
            }
            {isEventManager &&
              <TrashIcon
                size='1.5rem'
                fill={red}
                onClick={() => this.onChangeEvent({deleted: true}, true)}
              />
            }
          </Controls>
        }
        {edit ? this.renderEditView(isEventManager, isTicketManager) : this.renderDefaultView(event)}
      </LeftCol>
      {this.state.edit
        ? <RightCol>
          <Previews>
            {showMarkdownInfo && <MarkdownHelp />}
            <PageHeader small color={internalEventsColor}>Vorschau ‚Interne Infos‘</PageHeader>
            <ReactMarkdown escapeHtml source={event.publicNotes || '_keine vorhanden_'} />
            {event.public && <div>
              <PageHeader small color={internalEventsColor}>Vorschau ‚Startseite‘</PageHeader>
              <SmallEventLink event={event} />
              <PageHeader small color={internalEventsColor}>Vorschau ‚Event-Seite‘</PageHeader>
              <EventAnnouncement event={event} />
            </div>}
          </Previews>
        </RightCol>
        : <RightCol>
          {!event.withoutPresencePoll && reject(groups, {id: 'none'}).map(group => {
            if (!groupedUsers[group.id]) return null
            return <SingerGroup key={group.id}>
              <PageHeader small>{group.text}</PageHeader>
              {groupedUsers[group.id].map(user =>
                <UserEntry
                  key={user._id}
                  state={this.getUserState(user, pastEvent)}>
                  <Name>{compact([user.profile.prename, user.profile.name]).join(' ')}</Name>
                  <Comment>
                    {event.entries[user._id] && event.entries[user._id].comment &&
                    <div>{event.entries[user._id].comment}</div>}
                  </Comment>
                  {enableConfirmation &&
                    <Confirmation>
                      {event.entries[user._id] && event.entries[user._id].confirmed
                        ? <CheckedIcon
                          size='1.5rem'
                          fill={fontColor}
                          onClick={() => this.confirmPresence(user._id, false)}
                        />
                        : <UncheckedIcon
                          size='1.5rem'
                          fill={fontColor}
                          onClick={() => this.confirmPresence(user._id, true)}
                        />
                      }
                    </Confirmation>
                  }
                  {!enableConfirmation && hasPermission([EVENT_SUPERVISOR], this.props.user.roles) &&
                    <Confirmation>
                      <ChangeIcon
                        size='1.5rem'
                        fill={fontColor}
                        onClick={() => this.togglePresence(user._id)}
                      />
                    </Confirmation>
                  }
                </UserEntry>
              )}
            </SingerGroup>
          })}
        </RightCol>
      }
    </Page>
  }
}

export default connect(
  (state, props) => ({
    event: find(state.common.events, {_id: props.match.params.id}),
    users: state.common.users,
    user: state.common.authenticated
  }),
  dispatch => ({
    loadEvents: () => dispatch(common.actions.loadEvents()),
    refreshEvent: id => dispatch(common.actions.refreshEvent(id)),
    deleteEvent: id => dispatch(common.actions.deleteEvent(id)),
    loadUsers: () => dispatch(common.actions.loadUsers()),
    updateEvent: (id, update) => dispatch(common.actions.updateEvent(id, update)),
    updateTicketSale: (id, update) => dispatch(common.actions.updateTicketSale(id, update)),
    upsertEntry: (id, entry) => dispatch(common.actions.upsertEventEntry(id, entry))
  })
)(EventDetails)
