import React from 'react'
import styled from 'styled-components'
import InputLabel from './InputLabel'
import NumberInput from './NumberInput'
import {gray} from 'styles/colors'

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.6rem;
  color: ${props => props.disabled ? gray : 'inherit'};
  > div:last-child {
    flex: 1;
  }
  @media (max-width: 768px) {
    display: block;
    > * {
      width: 100%;
    }
  }
`

export default ({label, onChange, value, onFocus, onBlur, type, disabled}) =>
  <Container disabled={disabled}>
    <InputLabel>{label}</InputLabel>
    <NumberInput
      onChange={onChange}
      onPaste={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      type={type}
      disabled={disabled}
      plain
      noAutoFill
      value={value} />
  </Container>
