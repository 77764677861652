import {common} from 'redux/modules'
import Swagger from 'swagger-client'

const LS_PREFIX = 'AUTH'
const LS_REFRESH_TOKEN = `${LS_PREFIX}/REFRESH_TOKEN`

class ApiService {
  init (store) {
    this.store = store
    this.api = store.getState().common.api
    try {
      this.refreshToken = localStorage.getItem(LS_REFRESH_TOKEN)
    } catch (err) {
      console.warn('localStorage not available', err)
    }
    if (this.refreshToken) this.refresh()
  }

  _onLoginSuccess = async (resp) => {
    this.refreshToken = resp.obj.refreshToken
    this.accessToken = resp.obj.accessToken
    const api = await this.api
    api.clientAuthorizations.add('oauth', new Swagger.ApiKeyAuthorization('Authorization', `bearer ${this.accessToken}`, 'header'))
    try {
      localStorage.setItem(LS_REFRESH_TOKEN, this.refreshToken)
    } catch (err) {
      console.warn('localStorage not available', err)
    }
    this.store.dispatch(common.actions.finishAuth(resp.obj.user, resp.obj.accessToken))
    return resp
  }

  _onLoginError = (err) => {
    delete this.refreshToken
    delete this.accessToken
    try {
      localStorage.removeItem(LS_REFRESH_TOKEN)
    } catch (err) {
      console.warn('localStorage not available', err)
    }
    console.warn(`Login failed with status ${err.status}`, err)
    this.store.dispatch(common.actions.finishAuthWithError())
    throw err
  }

  _onRefreshError = () => {
    delete this.refreshToken
    delete this.accessToken
    try {
      localStorage.removeItem(LS_REFRESH_TOKEN)
    } catch (err) {
      console.warn('localStorage not available', err)
    }
    this.store.dispatch(common.actions.finishAuth(false))
  }

  refresh = async () => {
    if (!this.store) throw new Error('Api service is not initialized')
    this.store.dispatch(common.actions.startAuth())
    const api = await this.api
    return api.auth.auth({credentials: {grantType: 'refreshToken', token: this.refreshToken, clientId: 'mc-client'}})
      .then(this._onLoginSuccess)
      .catch(this._onRefreshError)
  }

  login = async (email, password) => {
    if (!this.store) throw new Error('Api service is not initialized')
    this.store.dispatch(common.actions.startAuth())
    const api = await this.api
    return api.auth.auth({credentials: {grantType: 'password', email, password, clientId: 'mc-client'}})
      .then(this._onLoginSuccess)
      .catch(this._onLoginError)
  }

  googleLogin = async (token) => {
    if (!this.store) throw new Error('Api service is not initialized')
    this.store.dispatch(common.actions.startAuth())
    const api = await this.api
    return api.auth.auth({credentials: {grantType: 'google', token, clientId: 'mc-client'}})
      .then(this._onLoginSuccess)
      .catch(this._onLoginError)
  }

  logout = () => {
    delete this.refreshToken
    delete this.accessToken
    localStorage.removeItem(LS_REFRESH_TOKEN)
    this.store.dispatch(common.actions.finishAuth(false))
  }

  resetPassword = async (email) => {
    const api = await this.api
    return api.auth.resetPassword({email})
  }

  setPassword = async (data) => {
    const api = await this.api
    return api.auth.setPassword({data})
  }

  getAuthToken = () => {
    return this.accessToken
  }

  req = async (tag, operation, payload, retry = false) => {
    if (!this.store) throw new Error('Api service is not initialized')
    const _authHandler = async (err) => {
      if (!retry && err.status === 401 && this.refreshToken && await this.refresh()) {
        return this.req(tag, operation, payload, true)
      }
      throw err
    }
    const api = await this.api
    return api[tag][operation](payload).catch(_authHandler)
  }

  reqUploadProgress = async (req, progressCb, contentType = 'application/json') => {
    const request = new XMLHttpRequest()
    request.open(req.method || 'GET', req.uri)
    request.setRequestHeader('Content-Type', contentType)
    request.setRequestHeader('Authorization', `bearer ${this.accessToken}`)
    if (progressCb) request.upload.addEventListener('progress', progressCb)
    return new Promise((resolve, reject) => {
      request.addEventListener('load', e => {
        if (progressCb) progressCb(e)
        const headers = request.getAllResponseHeaders().split('\r\n')
        const responseTypeHeader = headers.find(h => h.startsWith('content-type'))
        const responseType = responseTypeHeader ? responseTypeHeader.split(':')[1].trim() : ''
        const json = responseType.startsWith('application/json')
        if (request.status >= 200 && request.status < 300) {
          return resolve({
            ...request, obj: json && request.status !== 204 ? JSON.parse(request.responseText) : request.responseText
          })
        }
        reject(request.response)
      })
      request.send(contentType === 'application/json' ? JSON.stringify(req.body) : req.body)
    })
  }
}

export default new ApiService()
