import { handleActions } from 'redux-actions'
import * as actionTypes from './constants'
import {internalMenu, externalMenu} from './menuItems'
import filter from 'lodash/filter'
import reject from 'lodash/reject'
import difference from 'lodash/difference'
import clone from 'lodash/clone'
import flatMap from 'lodash/flatMap'
import findIndex from 'lodash/findIndex'
import sortBy from 'lodash/sortBy'
import {ADMIN} from 'lib/roles'

const calcMobileMenuHeight = (itemCount, authenticated) =>
  itemCount * 3.5 + (authenticated ? 7 : 3.5)

const initialState = {
  api: null,
  authenticated: false,
  authenticating: false,
  internalMenu: false,
  menuItems: externalMenu,
  mobileMenuHeight: calcMobileMenuHeight(flatMap(externalMenu, 'links').length, false),
  user: {},
  chatRooms: {},
  accessToken: null,
  events: [],
  undecidedEvents: [],
  tickets: [],
  showArchived: false
}

const _applyPayload = (state, {payload}) => ({...state, ...payload})

const selectInternalMenuItems = (roles = []) => {
  const items = filter(internalMenu, item => {
    if (!item.requiredRoles || roles.includes(ADMIN)) return true
    return difference(item.requiredRoles, roles).length === 0
  })
  const cloned = items.map(clone)
  cloned.forEach(item => {
    item.links = filter(item.links, link => {
      if (!link.requiredRoles || roles.includes(ADMIN)) return true
      return difference(link.requiredRoles, roles).length === 0
    })
  })
  return cloned
}

export default handleActions({
  [actionTypes.INIT]: _applyPayload,
  [actionTypes.AUTH]: (state, {payload}) => {
    const menu = {}
    if (payload.authenticated !== undefined && state.authenticated !== payload.authenticated) {
      menu.internalMenu = payload.authenticated && state.currentLocation.startsWith('/internal')
      menu.menuItems = menu.internalMenu ? selectInternalMenuItems(payload.authenticated.roles) : externalMenu
      menu.mobileMenuHeight = calcMobileMenuHeight(flatMap(menu.menuItems, 'links').length, payload.authenticated)
    }
    return {
      ...state,
      ...payload,
      ...menu
    }
  },
  [actionTypes.SET_CURRENT_LOCATION]: _applyPayload,
  [actionTypes.SET_APPLICATION_ERROR]: _applyPayload,
  [actionTypes.SET_USER_LIST]: _applyPayload,
  [actionTypes.SET_TICKETS]: _applyPayload,
  [actionTypes.SET_EVENTS]: _applyPayload,
  [actionTypes.SET_UNDECIDED_EVENTS]: _applyPayload,
  [actionTypes.SET_REPERTOIRE_LIST]: _applyPayload,
  [actionTypes.SET_USER_IMAGES]: _applyPayload,
  [actionTypes.SET_UPLOAD_PROGRESS]: _applyPayload,
  [actionTypes.CONFIRM_PAYMENT]: (state, {payload}) => {
    const tickets = state.tickets
    const eventIndex = findIndex(tickets, {_id: payload.eventId})
    if (eventIndex < 0) return state
    const ticketIndex = findIndex(tickets[eventIndex].tickets, {_id: payload.ticketId})
    if (ticketIndex < 0) return state
    tickets[eventIndex].tickets[ticketIndex].payed = true
    return {
      ...state,
      tickets: [...tickets]
    }
  },
  [actionTypes.OPEN_MENU]: (state, {payload}) => ({
    ...state,
    showMenuBody: payload.showMenuBody !== state.showMenuBody ? payload.showMenuBody : null
  }),
  [actionTypes.SET_MENU]: (state, {payload}) => {
    const menuItems = payload.internalMenu && state.authenticated ? selectInternalMenuItems(state.authenticated.roles) : externalMenu
    const internalMenu = payload.internalMenu && state.authenticated
    return {
      ...state,
      internalMenu,
      mobileMenuHeight: calcMobileMenuHeight(flatMap(menuItems, 'links').length, state.authenticated),
      menuItems
    }
  },
  [actionTypes.TOGGLE_MENU]: state => {
    const menuItems = state.internalMenu || !state.authenticated ? externalMenu : selectInternalMenuItems(state.authenticated.roles)
    const internalMenu = state.internalMenu ? false : !!state.authenticated
    return {
      ...state,
      internalMenu,
      menuItems,
      mobileMenuHeight: calcMobileMenuHeight(flatMap(menuItems, 'links').length, state.authenticated)
    }
  },
  [actionTypes.TOGGLE_ARCHIVE]: (state, {payload: {id}}) => {
    const repertoire = state.repertoires.find(r => r._id === id)
    if (!repertoire) return state
    repertoire.archived = !repertoire.archived
    const repertoires = [...state.repertoires]
    return {
      ...state,
      repertoires
    }
  },
  [actionTypes.TOGGLE_SHOW_ARCHIVED]: state => {
    return {
      ...state,
      showArchived: !state.showArchived
    }
  },
  [actionTypes.UPDATE_USER]: (state, {payload}) => {
    const users = clone(state.users)
    if (state.users) {
      const userIndex = findIndex(state.users, {_id: payload.user._id})
      if (userIndex > -1) users.splice(userIndex, 1, payload.user)
      else users.push(payload.user)
    }
    return {
      ...state,
      authenticated: payload.user._id === state.authenticated._id ? {...payload.user} : state.authenticated,
      user: payload.user,
      users
    }
  },
  [actionTypes.DELETE_USER]: (state, {payload}) => {
    const users = clone(state.users)
    if (state.users) {
      const userIndex = findIndex(state.users, {_id: payload.id})
      if (userIndex > -1) users.splice(userIndex, 1)
    }
    return {
      ...state,
      user: {},
      users
    }
  },
  [actionTypes.ADD_REPERTOIRE]: (state, {payload}) => {
    let repertoires = clone(state.repertoires)
    repertoires.push(payload.repertoire)
    repertoires = sortBy(repertoires, 'name')
    return {
      ...state,
      repertoires
    }
  },
  [actionTypes.UPDATE_REPERTOIRE]: (state, {payload}) => {
    const repertoires = clone(state.repertoires)
    const index = findIndex(repertoires, {_id: payload.repertoire._id})
    if (index < 0) return state
    repertoires[index] = payload.repertoire
    return {
      ...state,
      repertoires
    }
  },
  [actionTypes.REMOVE_REPERTOIRE]: (state, {payload}) => {
    const repertoires = reject(state.repertoires, {_id: payload.id})
    return {
      ...state,
      repertoires
    }
  },
  [actionTypes.ADD_EVENT]: (state, {payload}) => {
    const insertIndex = findIndex(state.events, e => e.date > payload.event.date)
    const updatedEvents = clone(state.events)
    if (insertIndex < 0) updatedEvents.push(payload.event)
    else updatedEvents.splice(insertIndex, 0, payload.event)
    return {
      ...state,
      events: updatedEvents
    }
  },
  [actionTypes.UPDATE_EVENT]: (state, {payload}) => {
    const eventIndex = findIndex(state.events, {_id: payload.event._id})
    if (eventIndex < 0) return state
    const updatedEvents = clone(state.events)
    updatedEvents[eventIndex] = payload.event
    return {
      ...state,
      events: updatedEvents,
      publicEvents: null
    }
  },
  [actionTypes.UPDATE_EVENT_ENTRY]: (state, {payload}) => {
    const eventIndex = findIndex(state.events, {_id: payload.id})
    if (eventIndex < 0) return state
    const updatedEvents = clone(state.events)
    updatedEvents[eventIndex].entries[payload.entry.userId] = payload.entry
    delete updatedEvents[eventIndex].pendingUpdate
    return {
      ...state,
      events: updatedEvents
    }
  }
}, initialState)
