import Color from 'color'

const blueColor = Color('#3F9FFF')
const yellowColor = Color('#e4d47b')
const greenColor = Color('#37c48b')
const purpleColor = Color('#be81d2')
const redColor = Color('#FD5E78')
const grayColor = Color('#9a9898')
const whiteColor = Color('#f4f4f4')

export const blue = blueColor.string()
export const yellow = yellowColor.string()
export const green = greenColor.string()
export const purple = purpleColor.string()
export const red = redColor.string()
export const gray = grayColor.string()
export const white = whiteColor.string()

export const darkRed = redColor.mix(Color('black'), 0.4).rgb().string()
export const darkGray = grayColor.mix(Color('black'), 0.5).rgb().string()
export const darkGreen = greenColor.mix(Color('black'), 0.3).rgb().string()

export const homeColor = '#1de9b6'
export const ensembleColor = '#d81b60'
export const darkEnsembleColor = '#6e1639'
export const managementColor = '#3F9FFF'
export const darkManagementColor = '#1a4066'
export const mediaColor = '#ffd740'
export const contactColor = '#b769d1'
export const darkContactColor = '#4e3b5c'

export const internalHomeColor = homeColor
export const internalUserColor = contactColor
export const internalChoirColor = ensembleColor
export const darkInternalChoirColor = darkEnsembleColor
export const internalRepertoireColor = managementColor
export const darkInternalRepertoireColor = darkManagementColor
export const internalEventsColor = contactColor
export const darkInternalEventsColor = darkContactColor

export const fontColor = '#f4f4f4'
