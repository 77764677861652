import React from 'react'
import styled from 'styled-components'
import Page from 'components/SimplePage'
import PageHeader from 'components/PageHeader'
import {internalChoirColor} from 'styles/colors'

const MailLink = styled.a`
  color: ${internalChoirColor};
`
export default () =>
  <Page>
    <PageHeader color={internalChoirColor}>Kommunikation per E-Mail</PageHeader>
    <p>
      Wenn du deinen Kollegen eine Mail schreiben willst, dann benutze einfach die folgenden selbsterklärenden Adressen.
      Einzige Voraussetzung ist, dass der Absender mit der hier in deinem Profil eingetragenen E-Mail Adresse übereinstimmt.
    </p>
    <p>
      <MailLink href='mailto:alle@moving-colours.de'>alle@moving-colours.de</MailLink><br />
      <MailLink href='mailto:sopran@moving-colours.de'>sopran@moving-colours.de</MailLink><br />
      <MailLink href='mailto:mezzo@moving-colours.de'>mezzo@moving-colours.de</MailLink><br />
      <MailLink href='mailto:alt@moving-colours.de'>alt@moving-colours.de</MailLink><br />
      <MailLink href='mailto:tenor@moving-colours.de'>tenor@moving-colours.de</MailLink><br />
      <MailLink href='mailto:bass@moving-colours.de'>bass@moving-colours.de</MailLink>
    </p>
    <p>
      Solltest du Fragen oder Probleme haben, dann wende dich einfach an den Admin deines Vertrauens
      unter <MailLink href='mailto:mc@probst.space'>mc@probst.space</MailLink>.
      Ich helfe dann gerne.
    </p>
  </Page>
