import React from 'react'
import styled from 'styled-components'
import omit from 'lodash/omit'
import {gray, fontColor} from 'styles/colors'

const Container = styled.div`
  display: inline-block;
  margin: 0.2rem 0rem;
  width: ${props => props.width || '12rem'};
  > input {
    color: ${props => props.disabled ? gray : fontColor};
    outline: none;
    line-height: 2rem;
    height: 2rem;
    border: none;
    font-size: 1rem;
    ${props =>
    props.plain
      ? `border-bottom: 1px solid ${props.disabled ? 'transparent' : fontColor};
         padding: 0 0.3rem;
         width: 100%;`
      : `border: 1px solid ${props.disabled ? gray : fontColor};
         padding: 0rem 0.7rem;
         border-radius: 0.5rem;
         box-sizing: border-box;
         width: 100%;`}
    text-align: ${props => props.align || 'left'};
    background-color: transparent;
  }
`

const FakeInput = styled.input`
  display: none;
`

export default (props) => <Container plain={props.plain} align={props.align} disabled={props.disabled} width={props.width}>
  {props.noAutoFill && <FakeInput size='0' {...omit(props, ['plain', 'align'])} />}
  <input size='0' {...omit(props, ['plain', 'align', 'noAutoFill'])} />
</Container>
