import React from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'
import Page from 'components/SimplePage'
import PageHeader from 'components/PageHeader'
import get from 'lodash/get'
import {internalUserColor, darkRed, darkGreen, red} from 'styles/colors'
import {List, ListRow, ListCell, ListHeader, RowGroup} from 'components/List'
import {MobileOnly} from 'components/MediaSwitch'
import {FINANCE_MANAGER, hasPermission} from 'lib/roles'
import Button from 'components/Button'
import TextInput from 'components/TextInput'
import DateInput from 'components/DateInput'
import moment from 'moment'
import {actions as accountsActions} from 'redux/ducks/accounts'
import {RIESelect} from 'riek'
import {TrashIcon} from '../../../components/Icons'

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${internalUserColor};
  margin-bottom: 15px;
`

const TransactionInput = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > * {
    margin: 1rem 0.5rem;
    :nth-child(2) {
      flex-grow: 1;
    }
  }
`

const StyledSelect = styled(RIESelect)`
  font-style: italic;
`

const Subtitle = styled.div`
  font-weight: bold;
`

const Fee = styled.div`
  text-align: right;
  font-style: italic;
`

const ShrinkingListCell = styled(ListCell)`
  @media (min-width: 769px) {
     width: 10%;
  }
  white-space: nowrap;
`

const feeOptions = [
  {id: 'free', value: 0, text: 'befreit'},
  {id: 'reduced', value: 15, text: 'Ermäßigt (15 €)'},
  {id: 'regular', value: 40, text: 'Regulär (40 €)'}
]

const typeOptions = [
  {id: 'debit', text: 'Forderung'},
  {id: 'credit', text: 'Gutschrift'}
]

class ContributionAccount extends React.PureComponent {
  state = {
    type: typeOptions[1],
    date: moment().toISOString(),
    amount: '0',
    text: ''
  }

  componentWillMount () {
    this.props.fetchTransactions(this.props.match.params.mid)
  }

  onChangeAmount = e => {
    if (!e.target.value.match(/^\d*(?:[,.]\d{0,2})?$/)) return
    this.setState({amount: e.target.value})
  }

  onChangeFee = (obj) => {
    const {mid, updateFee} = this.props
    updateFee(mid, obj.fee.value)
  }

  onSaveTransaction = () => {
    const {type, date, amount, text} = this.state
    const {mid} = this.props
    this.props.createTransaction({
      type: type.id,
      date,
      amount: parseFloat(amount.replace(',', '.')) * (type.id === 'credit' ? 1 : -1),
      text,
      mid: parseInt(mid, 10)
    })
  }

  onDeleteTransaction = id => {
    const isImported = id.match(/_i\d+$/)
    if (window.confirm('Wirklich löschen?')) {
      if (isImported) this.props.assignTransaction(id, 0)
      else this.props.deleteTransaction(id)
    }
  }

  render () {
    const {balance, name, transactions, roles, fee, mcode} = this.props
    const {type, amount, text, date} = this.state
    const isManager = hasPermission([FINANCE_MANAGER], roles)
    if (!transactions) return null
    return <Page marginRight>
      <PageHeader color={internalUserColor}>Beitragskonto {name} | Saldo: {balance.toFixed(2)} €</PageHeader>
      {mcode && <Subtitle>Bei Überweisung bitte angeben: {mcode}</Subtitle>}
      {isManager
        ? <Controls>
          <div>Beitragssatz: <StyledSelect options={feeOptions} propName='fee' value={feeOptions.find(fo => fo.value === fee)} change={this.onChangeFee} /></div>
          <TransactionInput>
            <DateInput width='8rem' onChange={date => this.setState({date})} noTime value={date} />
            <TextInput width='18rem' value={text} onChange={e => this.setState({text: e.target.value})} />
            <TextInput width='5rem' align='right' value={amount} onChange={this.onChangeAmount} /> €
            <StyledSelect options={typeOptions} propName='type' value={type} change={e => this.setState(e)} />
            <Button disabled={!text.trim() || !amount || parseFloat(amount) === 0} onClick={this.onSaveTransaction}>Buchung erfassen</Button>
          </TransactionInput>
        </Controls>
        : <Fee>Beitragssatz: {get(feeOptions.find(fo => fo.value === fee), 'text', feeOptions[0].text)}</Fee>
      }
      <List>
        <ListHeader>
          <div>Datum</div>
          <div>Beschreibung</div>
          <div>Betrag</div>
          {isManager && <div />}
        </ListHeader>
        <RowGroup>
          {transactions.map(transaction => {
            const color = transaction.get('amount', 0) < 0 ? darkRed : darkGreen
            return <ListRow key={transaction.get('_id')}>
              <ShrinkingListCell color={color}><MobileOnly>Datum:</MobileOnly>{moment(transaction.get('date')).format('DD.MM.YYYY')}</ShrinkingListCell>
              <ListCell color={color}><MobileOnly>Beschreibung:</MobileOnly><div>{transaction.get('text')}</div></ListCell>
              <ShrinkingListCell color={color} align='center'>
                <MobileOnly>Betrag:</MobileOnly>{transaction.get('amount', 0).toFixed(2)} €
              </ShrinkingListCell>
              {isManager && <ShrinkingListCell color={color} align='center'>
                <TrashIcon size='1rem' fill={red} onClick={() => this.onDeleteTransaction(transaction.get('_id'))} />
              </ShrinkingListCell>}
            </ListRow>
          })}
        </RowGroup>
      </List>
    </Page>
  }
}

export default connect(
  state => ({
    transactions: state.accounts.userTransactions,
    balance: state.accounts.userBalance,
    name: state.accounts.userName,
    mid: state.accounts.userMid,
    mcode: !state.accounts.userMid && state.common.authenticated.mcode,
    fee: state.accounts.userFee || 0,
    roles: state.common.authenticated.roles
  }),
  dispatch => ({
    fetchTransactions: mid => dispatch(accountsActions.fetchUserTransactions({mid})),
    updateFee: (mid, fee) => dispatch(accountsActions.updateFee({mid, fee})),
    createTransaction: transaction => dispatch(accountsActions.createTransaction({transaction})),
    assignTransaction: (transactionId, mid) => dispatch(accountsActions.assignTransaction({transactionId, mid})),
    deleteTransaction: id => dispatch(accountsActions.deleteTransaction({id}))
  })
)(ContributionAccount)
