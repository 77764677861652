import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import {connect} from 'react-redux'
import Helmet from 'react-helmet'
import Home from 'views/home/Home'
import Singers from 'views/ensemble/Singers'
import Events from 'views/ensemble/Events'
import TicketSale from 'views/ensemble/TicketSale'
import Chronicle from 'views/ensemble/Chronicle'
import Conductor from 'views/management/Conductor'
import Board from 'views/management/Board'
import See from 'views/media/See'
import Hear from 'views/media/Hear'
import Membership from 'views/contact/Membership'
import ContactBoard from 'views/contact/Board'
import Imprint from 'views/contact/Imprint'
import NoMatch from 'views/NoMatch'
import Internal from 'views/internal/Internal'
import ProfileEdit from 'views/internal/user/ProfileEdit'
import AccountEdit from 'views/internal/user/AccountEdit'
import AddUser from 'views/internal/choir/AddUser'
import ChoirList from 'views/internal/choir/ChoirList'
import Communication from 'views/internal/choir/Communication'
import Chat from 'views/internal/choir/Chat'
import AccountOverview from 'views/internal/choir/AccountOverview'
import ContributionAccount from 'views/internal/user/ContributionAccount'
import RepertoireList from 'views/internal/repertoire/RepertoireList'
import RepertoireDetails from 'views/internal/repertoire/RepertoireDetails'
import RehearsalList from 'views/internal/events/RehearsalList'
import ConcertList from 'views/internal/events/ConcertList'
import TripList from 'views/internal/events/TripList'
import EventDetails from 'views/internal/events/EventDetails'
import CurrentEvent from 'views/internal/events/CurrentEvent'
import TeachMe from 'views/internal/repertoire/TeachMe'
import Scores from 'views/internal/repertoire/Scores'
import Login from 'views/internal/Login'
import Menu from 'components/Menu'
import Root from 'components/Root'
import Sandbox from 'sandbox/Sandbox'
import WithAuthentication from 'views/WithAuthentication'
import TicketManagement from 'views/internal/events/TicketManagement'
import Dialog from 'components/Dialog'
import SetPassword from 'views/internal/SetPassword'

const title = 'Moving Colours e.V.'
const routes = [
  {
    title: 'Home',
    path: '/',
    component: Home,
    exact: true
  }, {
    title: 'Ensemble',
    path: '/ensemble/singers',
    component: Singers,
    exact: true
  }, {
    title: 'Ensemble',
    path: '/ensemble/events',
    component: Events,
    exact: true
  }, {
    title: 'Ensemble',
    path: '/ensemble/tickets/:eventId',
    component: TicketSale,
    exact: true
  }, {
    title: 'Ensemble',
    path: '/ensemble/chronicle',
    component: Chronicle,
    exact: true
  }, {
    title: 'Leitung',
    path: '/management/conductor',
    component: Conductor,
    exact: true
  }, {
    title: 'Leitung',
    path: '/management/board',
    component: Board,
    exact: true
  }, {
    title: 'Medien',
    path: '/media/see',
    component: See,
    exact: true
  }, {
    title: 'Medien',
    path: '/media/hear',
    component: Hear,
    exact: true
  }, {
    title: 'Kontakt',
    path: '/contact/membership',
    component: Membership,
    exact: true
  }, {
    title: 'Kontakt',
    path: '/contact/board',
    component: ContactBoard,
    exact: true
  }, {
    title: 'Kontakt',
    path: '/contact/imprint',
    component: Imprint,
    exact: true
  }, {
    title: 'Login',
    path: '/login',
    component: Login,
    exact: true
  }, {
    title: 'Intern',
    path: '/internal',
    component: Internal,
    exact: true,
    internal: true
  }, {
    title: 'Profil',
    path: '/internal/user/profile/:id',
    component: ProfileEdit,
    internal: true
  }, {
    title: 'Zugangsdaten',
    path: '/internal/user/account/:id',
    component: AccountEdit,
    internal: true
  }, {
    title: 'Beitragskonto',
    path: '/internal/user/contribution_account/:mid',
    component: ContributionAccount,
    internal: true
  }, {
    title: 'Beitragskonto',
    path: '/internal/user/contribution_account',
    component: ContributionAccount,
    internal: true
  }, {
    title: 'Chor',
    path: '/internal/choir/users/add',
    component: AddUser,
    exact: true,
    internal: true
  }, {
    title: 'Chor',
    path: '/internal/choir/users',
    component: ChoirList,
    exact: true,
    internal: true
  }, {
    title: 'Chor',
    path: '/internal/choir/communication',
    component: Communication,
    exact: true,
    internal: true
  }, {
    title: 'Chor',
    path: '/internal/choir/chat',
    component: Chat,
    exact: true,
    internal: true
  }, {
    title: 'Mitgliedskonten',
    path: '/internal/choir/accounts',
    component: AccountOverview,
    exact: true,
    internal: true
  }, {
    title: 'Repertoire',
    path: '/internal/repertoire/directory',
    component: RepertoireList,
    exact: true,
    internal: true
  }, {
    title: 'Repertoire',
    path: '/internal/repertoire/directory/:id',
    component: RepertoireDetails,
    exact: true,
    internal: true
  }, {
    title: 'Repertoire',
    path: '/internal/repertoire/teachme',
    component: TeachMe,
    exact: true,
    internal: true
  }, {
    title: 'Repertoire',
    path: '/internal/repertoire/scores',
    component: Scores,
    exact: true,
    internal: true
  }, {
    title: 'Termime',
    path: '/internal/events/rehearsals',
    component: RehearsalList,
    exact: true,
    internal: true
  }, {
    title: 'Termine',
    path: '/internal/events/concerts',
    component: ConcertList,
    exact: true,
    internal: true
  }, {
    title: 'Termine',
    path: '/internal/events/trips',
    component: TripList,
    exact: true,
    internal: true
  }, {
    title: 'Termine',
    path: '/internal/events/current',
    component: CurrentEvent,
    exact: true,
    internal: true
  }, {
    title: 'Kartenverkäufe',
    path: '/internal/tickets',
    component: TicketManagement,
    exact: true,
    internal: true
  }, {
    title: 'Termine',
    path: '/internal/events/:id',
    component: EventDetails,
    exact: true,
    internal: true
  }, {
    title: 'Passwort erstellen',
    path: '/internal/set_password/:email/:token',
    component: SetPassword,
    exact: true
  }, {
    title: 'Sandbox',
    path: '/sandbox',
    component: Sandbox,
    exact: true
  }
]

class App extends Component {
  render () {
    return (
      <Router>
        <Root noScroll={this.props.noScroll}>
          <Helmet titleTemplate={`%s - ${title}`} />
          <Menu />
          <Switch>
            {routes.map((route, i) => {
              if (route.internal) return <WithAuthentication key={i} {...route} />
              return <Route key={i} {...route} />
            })}
            <Route component={NoMatch} />
          </Switch>
          <Dialog />
        </Root>
      </Router>
    )
  }
}

export default connect(
  state => ({
    noScroll: state.common.showMenuBody
  })
)(App)
