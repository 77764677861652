import styled from 'styled-components'

export default styled.div`
  background-color: red;
  color: white;
  border-radius: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
`
