import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {homeColor} from 'styles/colors'
import omit from 'lodash/omit'
import moment from 'moment'

const Event = styled(Link)`
  display: block;
  border-left: 3px solid gray;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  padding-left: 0.5rem;
  font-size: 0.9rem;
`

const EventTitle = styled.h1`
  margin: 0 0 0.2rem 0;
  font-variant: small-caps;
  font-size: 0.8rem;
  color: ${homeColor};
`

const TicketSaleInfo = styled.h1`
  margin: 0 0 0.2rem 0;
  font-variant: small-caps;
  font-size: 0.8rem;
`

export default (props) =>
  <Event to={props.to || '#'} {...omit(props, ['event'])}>
    <EventTitle>{props.event.title}</EventTitle>
    <div>{props.event.subtitle}</div>
    <div>{moment(props.event.date).format('dddd, Do MMMM ’YY [um] HH:mm [Uhr]')}</div>
    {props.event.ticketSale && (!props.event.ticketSale.deadline || moment().isBefore(props.event.ticketSale.deadline)) && <TicketSaleInfo>Online Kartenvorverkauf</TicketSaleInfo>}
  </Event>
