import styled from 'styled-components'

export default styled.div`
  margin: 1rem 0;
  line-height: 2rem;
  height: 2rem;
  padding: 0rem 0.7rem;
  border: 1px solid ${props => props.borderColor || 'white'};
  border-radius: 0.5rem;
  cursor: pointer;
  font-variant: small-caps;
  min-width: 12rem;
  box-sizing: border-box;
  text-align: center;
  background-color: ${props => props.bgColor || 'inherit'};
  ${props => props.disabled && `
    opacity: 0.5;
    pointer-events: none;
  `}
`
