import React, {Component} from 'react'
import {connect} from 'react-redux'
import {common} from 'redux/modules'
import PageHeader from 'components/PageHeader'
import Page from 'components/SimplePage'
import {internalRepertoireColor, darkInternalRepertoireColor} from 'styles/colors'
import {List, ListRow, ListCellExternalLink, RowGroup} from 'components/List'
import filter from 'lodash/filter'
import flatMap from 'lodash/flatMap'
import includes from 'lodash/includes'
import apiService from 'lib/apiService'

class RepertoireList extends Component {
  componentWillMount () {
    if (!this.props.repertoires) {
      this.props.loadRepertoires()
    }
  }

  render () {
    const {scores} = this.props
    return (
      <Page marginRight>
        <PageHeader color={internalRepertoireColor}>Noten</PageHeader>
        <List>
          <RowGroup>
            {(scores || []).map(item =>
              <ListRow key={item.path}>
                <ListCellExternalLink href={`/files/repertoire/${item.path}?access=${apiService.getAuthToken()}`} title color={darkInternalRepertoireColor}>
                  {item.name}
                </ListCellExternalLink>
                <ListCellExternalLink href={`/files/repertoire/${item.path}?access=${apiService.getAuthToken()}`} color={darkInternalRepertoireColor} align='center' italic>
                  Anklicken zum Öffnen
                </ListCellExternalLink>
              </ListRow>
            )}
          </RowGroup>
        </List>
      </Page>
    )
  }
}
export default connect(
  state => ({
    scores: state.common.repertoires && filter(
      flatMap(state.common.repertoires, r => r.files.map(f => ({name: r.name, fileName: f.name, path: f.path, type: f.type, teachMe: f.teachMe}))),
      item => item.type === 'application/pdf' &&
        (state.common.authenticated.group === 'none' || includes(item.teachMe, state.common.authenticated.group)) &&
        item.fileName.match(/noten\b/i)
    )
  }),
  dispatch => ({
    loadRepertoires: () => dispatch(common.actions.loadRepertoires())
  })
)(RepertoireList)
