import React, {Component} from 'react'
import {connect} from 'react-redux'
import {common} from 'redux/modules'
import styled from 'styled-components'
import Page from 'components/SimplePage'
import PageHeader from 'components/PageHeader'
import Button from 'components/Button'
import Badge from 'components/Badge'
import keyBy from 'lodash/keyBy'
import {fontColor, gray, internalChoirColor, darkInternalChoirColor} from 'styles/colors'
import Chat from 'components/Chat'
import ChatConnect from 'components/ChatConnect'
import Collapsible from 'react-collapsible'
import {DownIcon, EyeIcon, EyeCrossedIcon} from 'components/Icons'
import {DesktopOnly, MobileOnly} from 'components/MediaSwitch'
import {List} from 'immutable'
import {actions as chatActions} from 'redux/ducks/chat'
import {getMentions} from 'selectors'

const Container = styled(Page)`
  display: flex;
  height: calc(100% - 4rem);
  flex-direction: column;
  > *:last-child {
    flex: 1;
  }
`
const Header = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
`

const ChatContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  > *:first-child {
    margin-bottom: 10px;
  }
`
const ChatSelection = styled.div`
  @media (min-width: 769px) {
    min-width: 20rem;
  }
`
const ChatSelectionHead = styled.div`
  display: flex;
  border-bottom: 1px solid ${fontColor};
  > *:first-child {
   flex: 1;
   font-size: 1.2rem;
   text-transform: capitalize;
  }
`
const ChatSelector = styled.div`
  display: flex;
  align-items: center;
  min-width: inherit;
  @media (max-width: 768px) {
    justify-content: space-between;
  }
`
const ChatButton = styled(Button)`
  margin: 0.5rem 0;
`

const WatchIcon = styled(EyeIcon).attrs({
  fill: fontColor,
  size: '1rem'
})`
  margin-left: 0.5rem;
`

const DontWatchIcon = styled(EyeCrossedIcon).attrs({
  fill: gray,
  size: '1rem'
})`
  margin-left: 0.5rem;
`

const pinnedChatRooms = List(['info', 'chor'])

const getDefaultRoom = chatRooms => {
  const queryRoom = window.location.search.match(/initRoom=(\w+)/)
  const defaultRoomName = (queryRoom && queryRoom[1]) || 'chor'
  return chatRooms.find(r => r.get('name') === defaultRoomName)
}

class ChatOverview extends Component {
  state = {
    mobileChatSelectorOpen: false
  }
  componentDidMount () {
    const {usersById, loadUsers, chatRooms, activeRoomId} = this.props
    if (!usersById) loadUsers()
    const defaultRoom = getDefaultRoom(chatRooms)
    if (defaultRoom && !activeRoomId) this.setActiveChatRoom(defaultRoom.get('_id'))
  }

  componentDidUpdate () {
    const defaultRoom = getDefaultRoom(this.props.chatRooms)
    if (defaultRoom && !this.props.activeRoomId) this.setActiveChatRoom(defaultRoom.get('_id'))
  }
  componentWillUnmount () {
    this.props.setActiveRoomId(null)
  }

  setActiveChatRoom = id => {
    const room = this.props.chatRooms.get(id)
    if (!room) return
    if (this.props.activeRoomId === id) return
    if (!this.props.chatMessages.get(id)) this.props.fetchHistory(id)
    this.props.setActiveRoomId(id)
    this.setState({mobileChatSelectorOpen: false})
  }

  getChatRooms = () => {
    const chatRooms = this.props.chatRooms
    if (!chatRooms.size) return List()
    return pinnedChatRooms
      .map(name => chatRooms.find(r => r.get('name') === name))
      .concat(chatRooms.filter(r => !pinnedChatRooms.includes(r.get('name'))))
  }

  onChatImageUpload = fileOrBlob => {
    const room = this.props.chatRooms.get(this.props.activeRoomId)
    this.props.uploadFile(room.get('_id'), fileOrBlob)
  }

  render () {
    const {activeRoomId, chatRooms, chatMessages, usersById, usersOnline, ownUserId, sendMessage, accessToken, mentions, fetchHistory, historyFetch, notifyOnUpdate, toggleNotifyOnUpdate, connected} = this.props
    const unread = chatRooms.valueSeq().reduce((result, val) => result + (val.unread || 0), 0)
    return <Container>
      <Header>
        <PageHeader color={internalChoirColor}>Chat</PageHeader>
        {unread > 0 && <Badge>{unread}</Badge>}
      </Header>
      {chatRooms.size > 0
        ? <ChatContainer>
          <MobileOnly>
            <Collapsible
              trigger={
                <ChatSelectionHead>
                  <div>{chatRooms.getIn([activeRoomId, 'name']) || ''}</div>
                  <DownIcon fill={fontColor} size='1.5rem' />
                </ChatSelectionHead>
              }
              open={this.state.mobileChatSelectorOpen}
              handleTriggerClick={() => this.setState({mobileChatSelectorOpen: !this.state.mobileChatSelectorOpen})}
            >
              {this.getChatRooms().map(room =>
                <ChatSelector key={room.get('_id')}>
                  <ChatButton
                    onClick={() => this.setActiveChatRoom(room.get('_id'))}
                    bgColor={activeRoomId === room.get('_id') && darkInternalChoirColor}
                  >{room.get('name')}</ChatButton>
                  {room.get('unread') > 0 && <Badge>{room.get('unread')}</Badge>}
                </ChatSelector>
              )}
            </Collapsible>
          </MobileOnly>
          <DesktopOnly>
            <ChatSelection>
              {this.getChatRooms().map(room =>
                <ChatSelector key={room.get('_id')}>
                  <ChatButton
                    onClick={() => this.setActiveChatRoom(room.get('_id'))}
                    bgColor={activeRoomId === room.get('_id') && darkInternalChoirColor}
                  >{room.get('name')}</ChatButton>
                  {notifyOnUpdate.contains(room.get('_id'))
                    ? <WatchIcon onClick={() => toggleNotifyOnUpdate(room.get('_id'))} />
                    : <DontWatchIcon onClick={() => toggleNotifyOnUpdate(room.get('_id'))} />
                  }
                  {room.get('unread') > 0 && <Badge>{room.get('unread')}</Badge>}
                </ChatSelector>
              )}
            </ChatSelection>
          </DesktopOnly>
          <Chat
            messages={chatMessages.get(activeRoomId) || List()}
            hasMore={chatRooms.getIn([activeRoomId, 'hasMore'], false)}
            loadMore={() => fetchHistory(activeRoomId)}
            users={usersById}
            usersOnline={usersOnline}
            ownUserId={ownUserId}
            accessToken={accessToken}
            mentions={mentions}
            historyFetch={historyFetch}
            onSubmit={msg => sendMessage(chatRooms.getIn([activeRoomId, '_id']), msg)}
            onChatImageUpload={this.onChatImageUpload}
            connected={connected}
          />
        </ChatContainer>
        : <ChatConnect />
      }
    </Container>
  }
}

export default connect(
  state => ({
    activeRoomId: state.chat.activeRoomId,
    chatRooms: state.chat.chatRooms,
    chatMessages: state.chat.chatMessages,
    connected: state.chat.connected,
    notifyOnUpdate: state.chat.notifyOnUpdate,
    historyFetch: state.chat.historyFetch,
    usersOnline: state.chat.usersOnline,
    usersById: state.common.users && keyBy(state.common.users, '_id'),
    mentions: getMentions(state),
    ownUserId: state.common.authenticated._id,
    accessToken: state.common.accessToken
  }),
  dispatch => ({
    loadUsers: () => dispatch(common.actions.loadUsers()),
    uploadFile: (roomId, blob) => dispatch(chatActions.uploadFile({roomId, blob})),
    sendMessage: (roomId, message) => dispatch(chatActions.sendMessage({roomId, message})),
    toggleNotifyOnUpdate: roomId => dispatch(chatActions.toggleNotifyOnUpdate({roomId})),
    fetchHistory: roomId => dispatch(chatActions.fetchHistory({roomId})),
    setActiveRoomId: roomId => dispatch(chatActions.setActiveRoomId({roomId}))
  })
)(ChatOverview)
