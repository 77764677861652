/* global Image */
import React from 'react'
import styled from 'styled-components'
import {fontColor} from 'styles/colors'
import {ImageIcon} from 'components/Icons'
import isFunction from 'lodash/isFunction'
const pica = require('pica/dist/pica')()

const Container = styled.div`
  display: flex;
`

const maxSize = 1024

let inputRef

const triggerInputDialog = () => {
  inputRef.click()
}

const changeHandler = (onImage) => {
  if (!isFunction(onImage) || !inputRef.files[0]) return
  const img = new Image()
  img.onload = () => {
    if (img.width <= maxSize && img.height <= maxSize) return onImage(inputRef.files[0])
    const canvas = document.createElement('canvas')
    const portrait = img.height > img.width
    canvas.width = portrait ? img.width * maxSize / img.height : maxSize
    canvas.height = portrait ? maxSize : img.height * maxSize / img.width
    pica.resize(img, canvas)
      .then(result => pica.toBlob(result, 'image/jpeg', 90))
      .then(blob => {
        blob.name = inputRef.files[0].name
        console.log(`Resized image. Filesize: ${blob.size}`)
        onImage(blob)
      })
  }
  img.src = window.URL.createObjectURL(inputRef.files[0])
}

export default ({onImage}) =>
  <Container>
    <input ref={c => { inputRef = c }} type='file' style={{display: 'none'}} accept='image/*' onChange={() => changeHandler(onImage)} />
    <ImageIcon fill={fontColor} size='1.5rem' onClick={triggerInputDialog} />
  </Container>
