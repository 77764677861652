import * as actionTypes from './constants'
import Swagger from 'swagger-client'
import semver from 'semver'
import apiService from 'lib/apiService'

const requiredApiVersion = '>=0.1.0 <0.2.0'

export const init = () => (dispatch) => {
  const api = new Swagger({
    url: '/swagger.yaml',
    swaggerRequestHeaders: 'application/json;charset=utf-8, application/yaml;charset=utf-8',
    usePromise: true
  }).then(swagger => {
    if (!semver.satisfies(swagger.info.version, requiredApiVersion)) {
      dispatch({
        type: actionTypes.SET_APPLICATION_ERROR,
        payload: {
          error: {
            type: 'E_API_VERSION',
            message: `The current backend api version ${swagger.info.version} does not satisfy this client’s requirements (${requiredApiVersion})`
          }
        }
      })
      return Promise.reject(new Error('api error'))
    }
    return swagger
  })
  return dispatch({
    type: actionTypes.INIT,
    payload: {
      api
    }
  })
}

export const startAuth = () => ({
  type: actionTypes.AUTH,
  payload: {
    authenticating: true
  }
})

export const onVisibilityChange = (hidden) => (dispatch, getState) => {
  const authenticated = getState().common.authenticated
  dispatch({
    type: hidden ? actionTypes.BLUR : actionTypes.FOCUS
  })
  if (authenticated && !hidden) dispatch(loadEvents())
}

export const finishAuth = (user, accessToken) => async (dispatch, getState) => {
  await dispatch({
    type: actionTypes.AUTH,
    payload: {
      authenticating: false,
      authenticated: user,
      accessToken
    }
  })
  dispatch({
    type: user ? actionTypes.LOGIN : actionTypes.LOGOUT,
    payload: {user, accessToken}
  })
  if (!user) return
  dispatch(loadUsers())
  dispatch(loadUndecidedEvents(user._id))
}

export const finishAuthWithError = () => ({
  type: actionTypes.AUTH,
  payload: {
    authenticating: false,
    authenticated: false,
    authError: true
  }
})

export const clearAuthError = () => ({
  type: actionTypes.AUTH,
  payload: {
    authError: false
  }
})

export const loadUndecidedEvents = () => async (dispatch, getState) => {
  const api = await getState().common.api
  const userId = await getState().common.authenticated._id
  const res = await api.events.getUndecidedEvents({userId})
  dispatch({
    type: actionTypes.SET_UNDECIDED_EVENTS,
    payload: {undecidedEvents: res.obj}
  })
}

export const toggleMenuBody = id => ({
  type: actionTypes.OPEN_MENU,
  payload: {showMenuBody: id}
})

export const toggleMenu = () => ({
  type: actionTypes.TOGGLE_MENU
})

export const setMenu = internal => ({
  type: actionTypes.SET_MENU,
  payload: {
    internalMenu: internal
  }
})

export const setError = (type, message) => ({
  type: actionTypes.SET_APPLICATION_ERROR,
  payload: {
    error: type || message ? {type, message} : null
  }
})

export const setCurrentLocation = path => ({
  type: actionTypes.SET_CURRENT_LOCATION,
  payload: {
    currentLocation: path
  }
})

export const setUploadProgress = e => {
  const uploadProgress = e.type === 'progress'
    ? e.loaded / e.total
    : null
  return {
    type: actionTypes.SET_UPLOAD_PROGRESS,
    payload: {
      uploadProgress
    }
  }
}

export const updateUser = (id, user) => async (dispatch, getState) => {
  const req = {
    method: 'PUT',
    uri: window.location.origin + `/api/users/${id}`,
    body: user
  }
  const res = user.image
    ? await apiService.reqUploadProgress(req, e => dispatch(setUploadProgress(e)))
    : await apiService.req('users', id === 'self' ? 'updateSelf' : 'updateUser', {id, user})

  return dispatch({
    type: actionTypes.UPDATE_USER,
    payload: {
      user: res.obj
    }
  })
}

export const deleteUser = id => async dispatch => {
  await apiService.req('users', 'deleteUser', {id})
  return dispatch({
    type: actionTypes.DELETE_USER,
    payload: {
      id
    }
  })
}

export const loadUsers = () => async (dispatch, getState) => {
  const api = await getState().common.api
  const res = await api.users.getAllUsers()
  return dispatch({
    type: actionTypes.SET_USER_LIST,
    payload: {
      users: res.obj
    }
  })
}

export const loadTickets = () => async (dispatch, getState) => {
  const api = await getState().common.api
  const res = await api.tickets.getTickets()
  return dispatch({
    type: actionTypes.SET_TICKETS,
    payload: {
      tickets: res.obj
    }
  })
}

export const confirmPayment = ({eventId, ticketId}) => async (dispatch, getState) => {
  const api = await getState().common.api
  await api.tickets.confirmTicketPayment({ticketId})
  return dispatch({
    type: actionTypes.CONFIRM_PAYMENT,
    payload: {
      eventId,
      ticketId
    }
  })
}

export const loadUser = id => async (dispatch, getState) => {
  const api = await getState().common.api
  try {
    const res = await api.users.getUserById({id})
    return dispatch({
      type: actionTypes.UPDATE_USER,
      payload: {
        user: res.obj
      }
    })
  } catch (err) {
    if (err.status === 404) return
    console.error(err)
  }
}

export const loadUserImages = id => async (dispatch, getState) => {
  const api = await getState().common.api
  const res = await api.users.getUserImages()
  return dispatch({
    type: actionTypes.SET_USER_IMAGES,
    payload: {
      groupedUserImages: res.obj
    }
  })
}

export const createUser = user => async (dispatch, getState) => {
  const api = await getState().common.api
  const res = await api.users.createUser({user})
  await dispatch({
    type: actionTypes.UPDATE_USER,
    payload: {
      user: res.obj
    }
  })
  return res.obj
}

export const loadRepertoires = () => async (dispatch, getState) => {
  const api = await getState().common.api
  const res = await api.repertoires.getAllRepertoires()
  return dispatch({
    type: actionTypes.SET_REPERTOIRE_LIST,
    payload: {
      repertoires: res.obj
    }
  })
}

export const archive = id => async (dispatch, getState) => {
  console.log(`archiving ${id}`)
  const api = await getState().common.api
  dispatch({
    type: actionTypes.TOGGLE_ARCHIVE,
    payload: {id}
  })
  await api.repertoires.archiveRepertoire({id})
}

export const unarchive = id => async (dispatch, getState) => {
  const api = await getState().common.api
  dispatch({
    type: actionTypes.TOGGLE_ARCHIVE,
    payload: {id}
  })
  await api.repertoires.unarchiveRepertoire({id})
}

export const toggleShowArchived = () => ({
  type: actionTypes.TOGGLE_SHOW_ARCHIVED
})

export const addRepertoire = name => async (dispatch, getState) => {
  const api = await getState().common.api
  const res = await api.repertoires.createRepertoire({repertoire: {name}})
  return dispatch({
    type: actionTypes.ADD_REPERTOIRE,
    payload: {
      repertoire: res.obj
    }
  })
}

export const uploadRepertoireFile = (id, file) => async (dispatch, getState) => {
  const req = {
    method: 'POST',
    uri: window.location.origin + `/api/repertoires/${id}/files`,
    body: file
  }
  try {
    const res = await apiService.reqUploadProgress(req, e => dispatch(setUploadProgress(e)))
    return dispatch({
      type: actionTypes.UPDATE_REPERTOIRE,
      payload: {
        repertoire: res.obj
      }
    })
  } catch (err) {
    return dispatch({
      type: actionTypes.SET_UPLOAD_PROGRESS,
      payload: {
        uploadError: err,
        uploadProgress: null
      }
    })
  }
}

export const removeRepertoireFile = (id, path) => async (dispatch, getState) => {
  const api = await getState().common.api
  const res = await api.repertoires.removeRepertoireFile({id, file: {path}})
  return dispatch({
    type: actionTypes.UPDATE_REPERTOIRE,
    payload: {
      repertoire: res.obj
    }
  })
}

export const removeRepertoire = id => async (dispatch, getState) => {
  const api = await getState().common.api
  await api.repertoires.removeRepertoire({id})
  return dispatch({
    type: actionTypes.REMOVE_REPERTOIRE,
    payload: {
      id
    }
  })
}

export const resetUploadError = () => ({
  type: actionTypes.SET_UPLOAD_PROGRESS,
  payload: {
    uploadError: null,
    uploadProgress: null
  }
})

export const loadEvents = () => async (dispatch) => {
  const res = await apiService.req('events', 'getAllEvents')
  return dispatch({
    type: actionTypes.SET_EVENTS,
    payload: {
      events: res.obj
    }
  })
}

export const createEvent = event => async (dispatch) => {
  const res = await apiService.req('events', 'createEvent', {event})
  return dispatch({
    type: actionTypes.ADD_EVENT,
    payload: {
      event: res.obj
    }
  })
}

export const updateEvent = (id, update) => async (dispatch) => {
  const res = await apiService.req('events', 'updateEvent', {id, event: update})
  return dispatch({
    type: actionTypes.UPDATE_EVENT,
    payload: {
      event: res.obj
    }
  })
}

export const updateTicketSale = (id, ticketSale) => async (dispatch) => {
  const res = await apiService.req('events', 'setTicketSale', {id, ticketSale})
  return dispatch({
    type: actionTypes.UPDATE_EVENT,
    payload: {
      event: res.obj
    }
  })
}

export const refreshEvent = id => async (dispatch) => {
  const res = await apiService.req('events', 'getEvent', {id})
  return dispatch({
    type: actionTypes.UPDATE_EVENT,
    payload: {
      event: res.obj
    }
  })
}

export const upsertEventEntry = (eventId, entry) => async (dispatch) => {
  try {
    const res = await apiService.req('events', 'upsertEventEntry', {id: eventId, entry})
    await dispatch({
      type: actionTypes.UPDATE_EVENT_ENTRY,
      payload: {
        id: eventId,
        entry: res.obj
      }
    })
    return dispatch(loadUndecidedEvents())
  } catch (err) {
    alert('Fehler! Konnte Änderung nicht speichern.')
    window.location.reload()
  }
}

export const getPublicEvents = () => async (dispatch) => {
  const res = await apiService.req('events', 'getPublicEvents')
  return dispatch({
    type: actionTypes.SET_EVENTS,
    payload: {
      publicEvents: res.obj
    }
  })
}
