import React, {Component} from 'react'
import {connect} from 'react-redux'
import {common} from 'redux/modules'
import PageHeader from 'components/PageHeader'
import Page from 'components/SimplePage'
import {internalRepertoireColor, darkInternalRepertoireColor} from 'styles/colors'
import {List, ListRow, ListCell, ListHeader, RowGroup, ListCellExternalLink} from 'components/List'
import filter from 'lodash/filter'
import flatMap from 'lodash/flatMap'
import apiService from 'lib/apiService'

class RepertoireList extends Component {
  componentWillMount () {
    if (!this.props.repertoires) {
      this.props.loadRepertoires()
    }
  }

  render () {
    const {teachMe} = this.props
    return (
      <Page marginRight>
        <PageHeader color={internalRepertoireColor}>Teach-Me</PageHeader>
        <List>
          <ListHeader>
            <div>Repertoire</div>
            <div>Datei</div>
            <div>Player</div>
          </ListHeader>
          <RowGroup>
            {(teachMe || []).map(item =>
              <ListRow key={item.path}>
                <ListCell title color={darkInternalRepertoireColor}>
                  {item.name}
                </ListCell>
                <ListCell color={darkInternalRepertoireColor} align='center'>
                  {item.fileName}
                </ListCell>
                {item.type === 'audio/mp3'
                  ? <ListCell color={darkInternalRepertoireColor} align='center'>
                    <audio src={`/files/repertoire/${item.path}?access=${apiService.getAuthToken()}`} controls preload='none' />
                  </ListCell>
                  : <ListCellExternalLink href={`/files/repertoire/${item.path}?access=${apiService.getAuthToken()}`} color={darkInternalRepertoireColor} align='center'>
                    Hier klicken zum Download
                  </ListCellExternalLink>
                }
              </ListRow>
            )}
          </RowGroup>
        </List>
      </Page>
    )
  }
}
export default connect(
  state => ({
    teachMe: state.common.repertoires && filter(
      flatMap(state.common.repertoires, r => r.files.map(f => ({name: r.name, fileName: f.name, path: f.path, teachMe: f.teachMe, type: f.type}))),
      item => item.type.match('^audio|^video') && (state.common.authenticated.group === 'none' || item.teachMe.includes(state.common.authenticated.group))
    )
  }),
  dispatch => ({
    loadRepertoires: () => dispatch(common.actions.loadRepertoires())
  })
)(RepertoireList)
