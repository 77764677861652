import React, {Component} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import {common} from 'redux/modules'
import PageHeader from 'components/PageHeader'
import {ensembleColor} from 'styles/colors'
import LabelledTextInput from 'components/LabelledTextInput'
import LabelledNumberInput from 'components/LabelledNumberInput'
import Page from 'components/SimplePage'
import find from 'lodash/find'
import moment from 'moment'
import Button from 'components/Button'
import ReCAPTCHA from 'react-google-recaptcha'
import apiService from 'lib/apiService'
import isEmail from 'validator/lib/isEmail'
import isNumber from 'lodash/isNumber'

const PlaceTime = styled.div`
  font-size: 1rem;
  color: ${ensembleColor};
  font-style: italic;
  > a {
    text-decoration: underline;
  }
`

const InfoText = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`

const TicketInfo = styled.div`
  font-style: italic;
`

const Form = styled.div`
  margin: 2rem 0;
  max-width: 30rem;
`

const ButtonWrap = styled.div`
  display: flex;
  max-width: 30rem;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    display: block;
    margin: 0;
  }
`

class TicketSale extends Component {
  state = {
    name: '',
    email: '',
    numRegular: 0,
    numDiscount: 0,
    pending: false,
    success: false,
    valid: false
  }
  componentWillMount () {
    if (!this.props.publicEvents) this.props.getPublicEvents()
  }

  onCaptcha = async (token) => {
    this.setState({
      pending: true
    })
    apiService.req('events', 'buyTickets', {order: {
      reCaptcha: token,
      eventId: this.props.event._id,
      name: this.state.name.trim(),
      email: this.state.email.trim(),
      numRegular: this.state.numRegular,
      numDiscount: this.state.numDiscount
    }}).then((response) => {
      this.setState({
        success: true,
        pending: false,
        reference: response.obj.reference
      })
    }).catch(err => {
      console.log(err)
      this.setState({
        success: false,
        pending: false,
        error: `Ein Fehler ist aufgetreten! (${err.statusText})`
      })
    })
  }

  onInput = (field, value, max = 0) => {
    if (isNumber(value)) {
      if (max > 0 && value > max) value = max
    } else {
      if (max > 0 && value.length > max) value = value.substr(0, max)
    }
    const newState = {...this.state, ...{[field]: value}}
    const quota = this.props.event.ticketSale.quota
    newState.valid = (newState.numDiscount || newState.numRegular) &&
      (!quota || (newState.numDiscount + newState.numRegular <= quota)) &&
      newState.name.trim() &&
      isEmail(newState.email)
    this.setState(newState)
  }

  renderForm = ticketSale => {
    if (ticketSale.quota === 0) return <TicketInfo>Online sind leider keine Karten mehr verfügbar. Evtl. sind noch Restkarten an der Abendkasse erhältlich.</TicketInfo>
    return <div>
      {ticketSale.deadline && <TicketInfo>Online-Verkauf endet am {moment(ticketSale.deadline).format('Do MMMM YYYY [um] HH:mm [Uhr]')}</TicketInfo>}
      {ticketSale.quota > 0 && <TicketInfo>Online verfügbare Karten: {ticketSale.quota}</TicketInfo>}
      {ticketSale.priceRegular && <TicketInfo>Preis: {ticketSale.priceRegular.toFixed(2).replace('.', ',')} €</TicketInfo>}
      {ticketSale.priceDiscount && <TicketInfo>Ermäßigt: {ticketSale.priceDiscount.toFixed(2).replace('.', ',')} €</TicketInfo>}
      <Form>
        <LabelledTextInput width='100%' label='Name' change={e => this.onInput('name', e.target.value, 40)} value={this.state.name} />
        <LabelledTextInput width='100%' label='E-Mail' change={e => this.onInput('email', e.target.value, 64)} value={this.state.email} />
        {ticketSale.priceRegular != null &&
          <LabelledNumberInput
            value={this.state.numRegular}
            label='Anzahl Normalpreis'
            onChange={val => this.onInput('numRegular', val, 20)}
          />
        }
        {ticketSale.priceDiscount != null &&
          <LabelledNumberInput
            value={this.state.numDiscount}
            label='Anzahl Ermäßigt'
            onChange={val => this.onInput('numDiscount', val, 20)}
          />
        }
      </Form>
      <TicketInfo>Gesamtkosten: {(this.state.numRegular * (ticketSale.priceRegular || 0) + this.state.numDiscount * (ticketSale.priceDiscount || 0)).toFixed(2).replace('.', ',')} €</TicketInfo>
      <ReCAPTCHA
        ref={c => { this.captcha = c }}
        size='invisible'
        sitekey='6LfSZB8UAAAAAHMJA1SVPnV9I4YQ0Oh4L-9ygdj8'
        onChange={this.onCaptcha} />
      <ButtonWrap>
        <Button disabled={this.state.pending || !this.state.valid} onClick={() => this.captcha.execute()}>Karten reservieren</Button>
        {this.state.error && <div>{this.state.error}</div>}
      </ButtonWrap>
    </div>
  }

  renderPending = () => {
    return <InfoText>Deine Bestellung wird verarbeitet...</InfoText>
  }

  renderSuccess = () => {
    return <InfoText>Deine Kartenbestellung ist eingegangen. Du erhälst eine E-Mail mit Details zur Zahlung. Deine Bestellreferenz für
    Rückfragen lautet {this.state.reference}. Bei Problemen wende dich bitte über das Kontakformular an den Vorstand. Vielen Dank, wir freuen uns auf deinen Besuch.</InfoText>
  }

  renderBody = (event) => {
    if (this.state.pending) return this.renderPending()
    if (this.state.success) return this.renderSuccess()
    return <div>
      <InfoText>Hier kannst du Karten für {event.type === 'concert' ? 'das Konzert' : 'die Veranstaltung'} online reservieren. Du erhälst eine E-Mail mit den Rechnungsdetails.
        Nach Zahlungseingang {event.type === 'concert' ? 'werden die Karten unter deinem Namen an der Abendkasse hinterlegt.' : 'wird die Veranstaltung für Dich verbindlich reserviert.'}
      </InfoText>
      {(!event.ticketSale.deadline || moment(event.ticketSale.deadline).isAfter(moment()))
        ? this.renderForm(event.ticketSale)
        : <InfoText>Der Kartenvorverkauf ist beendet. Restkarten sind nur noch an der Abendkasse erhältlich.</InfoText>
      }
    </div>
  }

  render () {
    if (!this.props.event) return null
    const {event} = this.props
    return <Page>
      <PageHeader color={ensembleColor}>Kartenvorverkauf für {event.title}</PageHeader>
      <PlaceTime>{event.place}</PlaceTime>
      <PlaceTime>{moment(event.date).format('dddd, Do MMMM YYYY [um] HH:mm [Uhr]')}</PlaceTime>
      {this.renderBody(event)}
      <PlaceTime>Bitte beachten Sie unsere <a href='/contact/imprint#gdpr'>Datenschutzerklärung</a></PlaceTime>
    </Page>
  }
}

export default connect(
  (state, props) => ({
    event: find(state.common.publicEvents, {_id: props.match.params.eventId})
  }),
  dispatch => ({
    getPublicEvents: () => dispatch(common.actions.getPublicEvents())
  })
)(TicketSale)
