import React, {PureComponent} from 'react'
import Page from 'components/CenteredPage'
import Button from 'components/Button'
import TextInput from 'components/TextInput'
import apiService from 'lib/apiService'
import {red} from 'styles/colors'

import styled from 'styled-components'

const Error = styled.div`
  color: ${red}
`

class SetPassword extends PureComponent {
  state = {
    verified: false,
    error: false,
    password1: '',
    password2: ''
  }

  getError = () => {
    const {password1, password2} = this.state
    if (!password1) return 'Passwort eingeben'
    if (password1.length < 8) return 'zu kurz (min. 8 Zeichen)'
    if (password1.match(/^[A-Za-z]+$/)) return 'zu schwach (Zahlen oder Sonderzeichen verwenden)'
    if (password1.match(/^[0-9]+$/)) return 'zu schwach (auch Buchstaben verwenden)'
    if (password1 !== password2) return 'Passwörter sind nicht gleich'
  }

  submit = () => {
    const {password1} = this.state
    const {email, token} = this.props.match.params
    apiService.setPassword({password: password1, email: decodeURIComponent(email), token}).then(
      () => {
        this.setState({verified: true})
      },
      (resp) => {
        this.setState({
          error: resp.status === 403
            ? 'Der Authorisierungscode ist ungültig. Es wurde keine Änderung durchgeführt.'
            : 'Ein Fehler ist aufgetreten. Bitte versuch es nochmal oder wende dich an den Vorstand.'
        })
      }
    )
  }

  render () {
    const {error, verified, password1, password2} = this.state
    const {match} = this.props
    if (verified) return <p>Das hat geklappt. Du kannst dich ab sofort mit deinem neuen Passwort anmelden.</p>
    if (error) return <p>{error}</p>
    const passwordError = this.getError()
    return (
      <Page>
        <p>Erstelle ein neues Passwort für {decodeURIComponent(match.params.email)}</p>
        <TextInput
          align='center'
          placeholder='Passwort'
          autoCorrect='off'
          autoCapitalize='off'
          value={password1}
          type='password'
          onChange={e => this.setState({password1: e.target.value})}
        />
        <TextInput
          align='center'
          placeholder='Passwort wiederholen'
          autoCorrect='off'
          autoCapitalize='off'
          value={password2}
          type='password'
          onChange={e => this.setState({password2: e.target.value})}
        />
        <Button onClick={this.submit} disabled={passwordError}>Abschicken</Button>
        {passwordError && <Error>{passwordError}</Error>}
      </Page>
    )
  }
}

export default SetPassword
