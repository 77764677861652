import React, {Component} from 'react'
import delay from 'lodash/delay'
import {Motion, spring} from 'react-motion'

class FadeIn extends Component {
  state = {
    show: !this.props.delay
  }

  componentDidMount () {
    if (this.props.delay) delay(() => this.setState({show: true}), this.props.delay)
  }

  render () {
    if (!this.state.show) return <div style={{height: this.props.placeholderHeight || 0}} />
    return <Motion
      defaultStyle={{opacity: 0}}
      style={{opacity: spring(1)}}>
      {is => <div style={{opacity: is.opacity}}>{this.props.children}</div>}
    </Motion>
  }
}

export default FadeIn
