import React from 'react'
import styled from 'styled-components'
import SubHeader from 'components/PageHeader'
import Page from 'components/SimplePage'
import {contactColor} from 'styles/colors'

const P = styled.p`
  margin: 0.5rem 0;
  text-align: justify;
`

export default () => <Page>
  <SubHeader color={contactColor}>Impressum Moving Colours e.V.</SubHeader>
  <P>
    <h3>Anschrift</h3>
    Moving Colours e.V.<br />
    Osloer Str. 17<br />
    13359 Berlin
  </P>
  <SubHeader color={contactColor} small>Probenort</SubHeader>
  <P>
    Zunftwirtschaft in der Arminiusmarkthalle<br />
    Arminiusstraße 2-4<br />
    10551 Berlin
  </P>
  <SubHeader color={contactColor} small>Vertretungsberechtigter</SubHeader>
  <P>
    Alexander Morell
  </P>
  <SubHeader color={contactColor} small>1. Haftungsbeschränkung</SubHeader>
  <P>
    Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt erstellt. Der Anbieter übernimmt jedoch keine Gewähr
    für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Inhalte. Die Nutzung der Inhalte der
    Website erfolgt auf eigene Gefahr des Nutzers. Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen
    Autors und nicht immer die Meinung des Anbieters wieder. Mit der reinen Nutzung der Website des Anbieters kommt
    keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande.</P>
  <SubHeader color={contactColor} small>2. Externe Links</SubHeader>
  <P>
    Diese Website enthält Verknüpfungen zu Websites Dritter (”externe Links”). Diese Websites unterliegen der Haftung
    der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die fremden Inhalte
    daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich.
    Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der verknüpften
    Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis oder Link
    liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle der externen Links ist für den Anbieter ohne konkrete
    Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe Links
    unverzüglich gelöscht.
  </P>
  <SubHeader color={contactColor} small>3. Urheber- und Leistungsschutzrechte</SubHeader>
  <P>
    Die auf dieser Website veröffentlichten Inhalte unterliegen, sofern nicht anders gekennzeichnet dem deutschen
    Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung
    bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere
    für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in
    Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche
    gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht
    gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht
    kommerziellen Gebrauch ist erlaubt.<br />
    Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig.
  </P>
  <SubHeader color={contactColor} small><a id='gdpr'>4. Datenschutz</a></SubHeader>
  <P><strong>Allgemeiner Hinweis und Pflichtinformationen</strong></P>
  <P><strong>Benennung der verantwortlichen Stelle</strong></P>
  <P>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</P>
  <P><span id='s3-t-firma'>Moving Colours e.V.</span><br /><span id='s3-t-strasse'>Osloer Str. 17</span><br /><span id='s3-t-plz'>13359</span> <span id='s3-t-ort'>Berlin</span></P>
  <P>Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).</P>

  <P><strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong></P>
  <P>Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</P>

  <P><strong>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</strong></P>
  <P>Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: <a href='https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html' target='_blank' rel='noopener noreferrer'>https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</P>

  <P><strong>Recht auf Datenübertragbarkeit</strong></P>
  <P>Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</P>

  <P><strong>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</strong></P>
  <P>Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.</P>

  <P><strong>SSL- bzw. TLS-Verschlüsselung</strong></P>
  <P>Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.</P>

  <P><strong>Server-Log-Dateien</strong></P>
  <P>In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch Informationen, die Ihr Browser automatisch an uns übermittelt. Dies sind:</P>
  <ul>
    <li>Besuchte Seite auf unserer Domain</li>
    <li>Datum und Uhrzeit der Serveranfrage</li>
    <li>Browsertyp und Browserversion</li>
    <li>Verwendetes Betriebssystem</li>
    <li>Referrer URL</li>
    <li>Hostname des zugreifenden Rechners</li>
    <li>IP-Adresse</li>
  </ul>
  <P>Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.</P>

  <P><strong>Datenübermittlung bei Vertragsschluss für Warenkauf und Warenversand</strong></P>
  <P>Personenbezogene Daten werden nur an Dritte nur übermittelt, sofern eine Notwendigkeit im Rahmen der Vertragsabwicklung besteht. Dritte können beispielsweise Bezahldienstleister oder Logistikunternehmen sein. Eine weitergehende Übermittlung der Daten findet nicht statt bzw. nur dann, wenn Sie dieser ausdrücklich zugestimmt haben.</P>
  <P>Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.</P>

  <P><strong>Kontaktformular</strong></P>
  <P>Per Kontaktformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt.</P>
  <P>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</P>
  <P>Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unberührt.</P>
  <P><small>Quelle: Datenschutz-Konfigurator von <a href='http://www.mein-datenschutzbeauftragter.de' target='_blank' rel='noopener noreferrer'>mein-datenschutzbeauftragter.de</a></small></P>
  <SubHeader color={contactColor} small>5. Jugendschutzhinweis</SubHeader>
  <P>
    Bei moving-colours.de handelt es sich um ein Informationsportal, bei dem es vorkommen kann, dass auf Internetseiten
    verlinkt wird, die nicht im Verantwortungsbereich der Organisatoren von moving-colours.de liegen.<br />
    Für den Fall, dass auf Webseiten verlinkt und/oder diese nachträglich, ohne Wissen der Betreiber von moving-colours.de
    verändert wurden und nicht mehr den genannten Altersbestimmungen entsprechen, bitten wir um sofortige Rückmeldung
    an den Vorstand. Die Verantwortlichen dieses Projekts übernehmen in einem solchen Fall jedoch keinerlei Haftung.
  </P>
  <SubHeader color={contactColor} small>6. Besondere Nutzungsbedingungen</SubHeader>
  <P>
    Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Nummern 1. bis 4. abweichen,
    wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die
    besonderen Nutzungsbedingungen.<br />
    Erwähnte Markenzeichen/Tradmarks sind Eigentum des jeweiligen Rechteinhabers und unterliegen dessen Bestimmungen.
  </P>
</Page>
