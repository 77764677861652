import React, {Component} from 'react'
import Measure from 'react-measure'
import SubHeader from 'components/PageHeader'
import Gallery from 'react-photo-gallery'
import Page from 'components/SimplePage'
import Lightbox from 'react-images'
import {mediaColor} from 'styles/colors'

const maxImageWidth = 300
const getImageElement = name => {
  return {
    src: require(`img/${name}_320.jpg`),
    srcset: [
      `${require(`img/${name}_1024.jpg`)} 1024w`,
      `${require(`img/${name}_640.jpg`)} 640w`,
      `${require(`img/${name}_320.jpg`)} 320w`
    ],
    sizes: [
      '(min-width: 1424px) 16vw',
      '(min-width: 1124px) 20vw',
      '(min-width: 824px) 26.6vw',
      '(min-width: 524px) 40vw',
      '80vw'
    ],
    width: name.startsWith('p') ? 683 : 1024,
    height: name.startsWith('p') ? 1024 : 683,
    alt: ''
  }
}
const images = [
  getImageElement('7'),
  getImageElement('10'),
  getImageElement('13'),
  getImageElement('16'),
  getImageElement('024'),
  getImageElement('27'),
  getImageElement('37'),
  getImageElement('051'),
  getImageElement('55'),
  getImageElement('58'),
  getImageElement('059'),
  getImageElement('061'),
  getImageElement('p063'),
  getImageElement('065'),
  getImageElement('066'),
  getImageElement('068'),
  getImageElement('072'),
  getImageElement('073'),
  getImageElement('077'),
  getImageElement('087'),
  getImageElement('089'),
  getImageElement('britzer_garten_2'),
  getImageElement('britzer_garten_3'),
  getImageElement('britzer_garten_4'),
  getImageElement('britzer_garten_5'),
  getImageElement('britzer_garten_6'),
  getImageElement('Chor-1-2'),
  getImageElement('Chor-1-22'),
  getImageElement('Chor-1-32'),
  getImageElement('DSC08924'),
  getImageElement('pDSC08942'),
  getImageElement('DSC09005')
]
export default class See extends Component {
  state = {
    showLightbox: false,
    currentIndex: 0
  }

  openLightbox = (index, event) => {
    this.setState({
      currentImage: index,
      showLightbox: true
    })
  }
  render () {
    return <Measure whitelist={['width']}>
      {({width}) => {
        return (
          <Page rightMargin>
            <SubHeader color={mediaColor}>Sehen</SubHeader>
            <Gallery cols={Math.ceil(width / maxImageWidth) || 1} photos={images} onClickPhoto={this.openLightbox} />
            <Lightbox
              images={images}
              isOpen={this.state.showLightbox}
              currentImage={this.state.currentImage}
              onClickNext={() => this.setState({currentImage: this.state.currentImage + 1})}
              onClickPrev={() => this.setState({currentImage: this.state.currentImage - 1})}
              onClose={() => this.setState({showLightbox: false})}
            />
          </Page>
        )
      }}
    </Measure>
  }
}
