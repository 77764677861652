import React from 'react'
import styled from 'styled-components'
import InputLabel from './InputLabel'
import {fontColor} from 'styles/colors'

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  @media (max-width: 768px) {
    display: block;
    > textarea {
      width: 100%;
    }
  }
  > textarea {
    border: 1px solid ${fontColor};
    min-height: ${props => props.height || '10rem'};
    outline: none;
    background-color: transparent;
    color: ${fontColor};
    flex: 1;
    font-size: 0.9rem;
    resize: none;
  }
`

export default ({label, change, value, onFocus, height}) =>
  <Container
    height={height}
    onFocus={onFocus}>
    <InputLabel>{label}</InputLabel>
    <textarea
      onChange={change}
      onPaste={change}
      value={value || ''} />
  </Container>
