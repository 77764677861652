import identity from 'lodash/identity'
import {delay, eventChannel} from 'redux-saga'
import {call, cancel, put, take, takeEvery, takeLatest, fork, race, select} from 'redux-saga/effects'
import {createActions, handleActions} from 'redux-actions'
import SockJS from 'sockjs-client'
import {LOGIN, LOGOUT, BLUR, FOCUS} from '../modules/common/constants'
import {List, Map, fromJS} from 'immutable'
import keyBy from 'lodash/keyBy'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import apiService from 'lib/apiService'
import {messageChannel, waitForConnection, waitForClose} from 'lib/websockets'
import pkg from 'lib/package.json'

let socket

export const actions = createActions({
  chat: {
    CHAT_ROOMS_RECEIVED: identity,
    CHAT_HISTORY_RECEIVED: identity,
    CHAT_MESSAGE_RECEIVED: identity,
    CHAT_NOTIFICATIONS_RECEIVED: identity,
    CHAT_USERS_ONLINE_RECEIVED: identity,
    SEND_MESSAGE: identity,
    SET_ACTIVE_ROOM_ID: identity,
    TOGGLE_NOTIFY_ON_UPDATE: identity,
    UPLOAD_FILE: identity,
    UPLOAD_PROGRESS: identity,
    UPLOAD_ERROR: identity,
    CONNECTED: identity,
    DISCONNECTED: identity,
    MESSAGE_RECEIVED: identity,
    FETCH_HISTORY: identity,
    RESET_UNREAD_MESSAGES: identity,
    VERSION_RECEIVED: identity
  }
}).chat

export default handleActions({
  [actions.chatRoomsReceived]: (state, {payload}) => ({
    ...state,
    connected: true,
    chatRooms: fromJS(keyBy(payload, '_id'))
  }),
  [actions.chatMessageReceived]: (state, {payload}) => {
    const messages = (state.chatMessages.get(payload.roomId) || List())
      .push(fromJS(payload.message))
    const ownMessage = state.userId === payload.message.from
    return {
      ...state,
      connected: true,
      chatMessages: state.chatMessages.set(payload.roomId, messages),
      chatRooms: ownMessage ? state.chatRooms : state.chatRooms.setIn([payload.roomId, 'unread'], state.chatRooms.getIn([payload.roomId, 'unread']) + 1),
      historyFetch: false
    }
  },
  [actions.chatHistoryReceived]: (state, {payload}) => {
    const messages = (state.chatMessages.get(payload.roomId) || List())
      .concat(fromJS(payload.messages))
      .groupBy(msg => msg.get('_id'))
      .map(msg => msg.first())
      .toList()
      .sortBy(m => m.get('created'))
    return {
      ...state,
      connected: true,
      chatMessages: state.chatMessages.set(payload.roomId, messages),
      chatRooms: payload.historyUpdate
        ? state.chatRooms
        : state.chatRooms.setIn([payload.roomId, 'hasMore'], payload.hasMore),
      historyFetch: !payload.initial && !payload.historyUpdate
    }
  },
  [actions.chatNotificationsReceived]: (state, {payload}) => ({
    ...state,
    notifyOnUpdate: fromJS(payload.notifyOnUpdate) || List()
  }),
  [actions.chatUsersOnlineReceived]: (state, {payload}) => ({
    ...state,
    usersOnline: fromJS(payload)
  }),
  [actions.connected]: (state, {payload}) => ({
    ...state,
    connected: true,
    userId: payload
  }),
  [actions.disconnected]: (state) => ({
    ...state,
    connected: false,
    userId: false
  }),
  [actions.setActiveRoomId]: (state, {payload}) => ({
    ...state,
    activeRoomId: payload.roomId
  }),
  [actions.resetUnreadMessages]: (state, {payload: {roomId}}) => ({
    ...state,
    chatRooms: state.chatRooms.setIn([roomId, 'unread'], 0)
  }),
  default: identity
}, {
  activeRoomId: null,
  connected: false,
  chatRooms: Map(),
  chatMessages: Map(),
  notifyOnUpdate: List(),
  usersOnline: List(),
  userId: null,
  historyFetch: false
})

const messageHandlers = {
  chatRooms: (message) => actions.chatRoomsReceived(message.rooms),
  usersOnline: (message) => actions.chatUsersOnlineReceived(message.userIds),
  chatHistory: (message) => actions.chatHistoryReceived({roomId: message.roomId, messages: message.messages, hasMore: message.hasMore, initial: message.initial, historyUpdate: message.historyUpdate}),
  chatNotifications: (message) => actions.chatNotificationsReceived({notifyOnUpdate: message.notifications.roomUpdate}),
  newMessage: (message) => actions.chatMessageReceived({roomId: message.roomId, message: message.message}),
  version: message => actions.versionReceived({version: message.version})
}

const sendInfoMessage = (socket, message) => socket.send(JSON.stringify({type: 'info', ...message}))
const sendRequest = (socket, message) => socket.send(JSON.stringify({type: 'request', ...message}))

export function * watchForMessages (socket) {
  const channel = messageChannel(socket)
  while (true) {
    const message = yield take(channel)
    // console.log('ChatClient: message', message)
    if (message.type === 'error' && message.msg === 'authentication') {
      yield call(apiService.refresh)
      socket.close()
    }
    if (messageHandlers[message.msg]) {
      yield put(messageHandlers[message.msg](message))
    } else {
      yield put(actions.messageReceived(message))
    }
  }
}

export function * connectionSaga () {
  let errorCount = 0
  let lastError = 0
  while (true) {
    try {
      const userId = yield select(state => state.common.authenticated._id)
      const accessToken = yield select(state => state.common.accessToken)
      const url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/chat?token=${accessToken}`
      console.log(`ChatClient: connecting to ${url.split('=')[0]}=[...]`)
      socket = new SockJS(url, null, {transports: [
        'xhr-streaming',
        'xdr-streaming',
        'iframe-eventsource',
        'iframe-xhr-polling',
        'xdr-polling',
        'jsonp-polling'
      ]})
      const {timeout} = yield race({
        openConnection: call(waitForConnection, socket),
        timeout: call(delay, 10000)
      })
      if (timeout) {
        socket.close()
        console.log('ChatClient: connection failed, retrying in 30s...')
        yield call(delay, 30000)
      } else {
        yield put(actions.connected(userId))
        console.log(`ChatClient: connected`)
        const watchMessage = yield fork(watchForMessages, socket)
        sendRequest(socket, {msg: 'init', version: pkg.version})
        const {close, blur} = yield race({
          close: call(waitForClose, socket),
          blur: take(BLUR)
        })
        yield put(actions.disconnected(close || 'blur'))
        yield cancel(watchMessage)
        if (blur) {
          socket.close()
          socket = null
          console.log('ChatClient: disconnected (background)')
          yield take(FOCUS)
        } else {
          errorCount++
          lastError = new Date().getTime()
        }
      }
    } catch (e) {
      console.error('ChatClient: an error occurred')
      console.error(e)
      errorCount++
      lastError = new Date().getTime()
    } finally {
      if (socket) {
        console.log('ChatClient: closing socket.')
        socket.close()
        yield put(actions.disconnected())
        socket = null
      }
      if (new Date().getTime() - lastError > 3600000) errorCount = 0
      yield call(delay, 1000 * errorCount)
    }
  }
}

export function * watchLoginStateSaga () {
  while (true) {
    yield take(LOGIN)
    const connection = yield fork(connectionSaga)
    yield take(LOGOUT)
    yield cancel(connection)
    yield put(actions.disconnected())
  }
}

export function * fetchHistorySaga ({payload}) {
  if (!socket) return
  const last = yield select(state => state.chat.chatMessages.getIn([payload.roomId, 0, 'created']))
  sendRequest(socket, pickBy({msg: 'getHistory', roomId: payload.roomId, last}))
}

export function * updateHistorySaga ({payload}) {
  if (!socket) return
  const chatMessages = yield select(state => state.chat.chatMessages)
  payload.forEach(room => {
    if (room.unread && chatMessages.get(room._id)) {
      sendRequest(socket, {msg: 'getHistory', roomId: room._id, limit: room.unread})
    }
  })
}

export function * toggleNotifyOnUpdateSaga ({payload}) {
  const notifications = yield select(state => state.chat.notifyOnUpdate)
  sendRequest(socket, {msg: 'setNotification', roomId: payload.roomId, state: !notifications.contains(payload.roomId)})
}

export function * sendReadMessagesSaga ({payload}) {
  if (!socket) return
  yield put(actions.resetUnreadMessages(payload))
  sendInfoMessage(socket, {msg: 'readMessages', roomId: payload.roomId})
}

export function * sendMessageSaga ({payload}) {
  if (!socket) return
  yield put(actions.resetUnreadMessages(payload))
  sendInfoMessage(socket, {msg: 'sendMessage', roomId: payload.roomId, message: payload.message})
}

export function * uploadWithProgressSaga (req) {
  const progressRequestChannel = eventChannel(emit => {
    let subscribed = true
    const progressHandler = event => {
      if (!subscribed) return
      const progress = event.total ? event.loaded / event.total : 0
      emit({type: 'progress', progress})
    }
    const finishedHandler = () => {
      if (subscribed) emit({type: 'finished'})
    }
    const errorHandler = err => {
      if (subscribed) emit({type: 'error', error: err})
    }
    try {
      apiService.reqUploadProgress(req, progressHandler).then(finishedHandler)
    } catch (err) {
      errorHandler(err)
    }
    return () => {
      subscribed = false
    }
  })
  while (true) {
    const payload = yield take(progressRequestChannel)
    switch (payload.type) {
      case 'progress':
        yield put(actions.uploadProgress(payload.progress))
        break
      case 'error':
        yield put(actions.uploadError(payload.error))
        return
      case 'finished':
        yield put(actions.uploadProgress(1))
        return
      default:
    }
  }
}

export function * uploadFileSaga ({payload: {roomId, blob}}) {
  const connected = yield select(state => state.chat.connected)
  if (!connected) yield take(actions.connected)
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  const waitForData = () => new Promise(resolve => {
    reader.onload = (e) => {
      resolve(e.currentTarget.result)
    }
  })
  const data = yield call(waitForData)
  const req = {
    method: 'POST',
    uri: window.location.origin + `/api/chat/files`,
    body: {
      roomId,
      ...pick(blob, ['name', 'size', 'type']),
      data: data.substring(data.indexOf(',') + 1)
    }
  }
  yield call(uploadWithProgressSaga, req)
}

export function * setActiveRoomIdSaga ({payload}) {
  if (!payload.roomId) return
  yield call(delay, 3000)
  yield call(sendReadMessagesSaga, {payload})
}

export function * saga () {
  yield fork(watchLoginStateSaga)
  yield takeEvery(actions.fetchHistory, fetchHistorySaga)
  yield takeEvery(actions.chatRoomsReceived, updateHistorySaga)
  yield takeEvery(actions.sendMessage, sendMessageSaga)
  yield takeEvery(actions.toggleNotifyOnUpdate, toggleNotifyOnUpdateSaga)
  yield takeLatest(actions.uploadFile, uploadFileSaga)
  yield takeLatest(actions.setActiveRoomId, setActiveRoomIdSaga)
}
