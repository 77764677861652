import React from 'react'
import Page, {LeftCol, RightCol} from 'components/TwoColPage'

export default () =>
  <Page>
    <LeftCol>
      <p>
        Seit Herbst 2023 ist Sven Ratzel Chorleiter der Moving Colours. Seine musikalische
        Laufbahn begann im Kindesalter beim gemeinsamen Singen, schnell kam auch das Klavier
        als zweite Leidenschaft dazu. Nach einem Schulmusikstudium an der UdK Berlin ist
        er mittlerweile fester Bestandteil der Berliner Chorszene und leitet mehrere Chöre. Sein
        Wissen über die Feinheiten des gemeinsamen Singens konnte er mit dem Studiengang
        „Rhythmical Choir Conducting/Vocal Leadership“ an der Royal Academy of Music in
        Aarhus/Aalborg sowie diversen Meister- und Arrangementkursen vertiefen.
      </p>
      <p>
        Neben seiner Tätigkeit als Chorleiter ist Sven Ratzel als Dozent für Workshops im Bereich
        Chorleitung, Leadership und Performance aktiv und auch als Arrangeur tätig.
      </p>
      <p>
        Er begeistert den Chor regelmäßig durch seine humorvolle und enthusiastische Art und wir
        sind froh ihn mit an Bord zu haben.
      </p>
    </LeftCol>
    <RightCol>
      <img
        width='100%'
        src={require('img/sven_1024.jpg')}
        srcSet={`${require('img/sven_1024.jpg')} 1024w,${require('img/sven_640.jpg')} 640w,${require('img/sven_320.jpg')} 320w`}
        sizes='(min-width: 769px) 45vw,90vw'
        alt='Sven Ratzel' />
    </RightCol>
  </Page>
