import identity from 'lodash/identity'
import {createActions, handleActions} from 'redux-actions'
import {takeEvery, put, call, select} from 'redux-saga/effects'
import * as commonActionTypes from '../modules/common/constants'
import {List, fromJS} from 'immutable'
import apiService from 'lib/apiService'

export const actions = createActions({
  accounts: {
    ADD_USER_TRANSACTION: identity,
    ASSIGN_TRANSACTION: identity,
    CREATE_TRANSACTION: identity,
    DELETE_TRANSACTION: identity,
    DELETE_USER_TRANSACTION: identity,
    FETCH_OVERVIEW: identity,
    FETCH_USER_TRANSACTIONS: identity,
    SET_OVERVIEW: identity,
    SET_USER_FEE: identity,
    SET_USER_TRANSACTIONS: identity,
    SET_USER_SELECTION: identity,
    UPDATE_FEE: identity,
    IMPORT_TRANSACTIONS: identity
  }
}).accounts

export default handleActions({
  [actions.fetchOverview]: state => ({
    ...state,
    overview: List(),
    unknownTransactions: List()
  }),
  [actions.addUserTransaction]: (state, {payload: {transaction}}) => ({
    ...state,
    userTransactions: state.userTransactions.push(transaction).sortBy(t => t.get('date')).reverse(),
    userBalance: state.userBalance + transaction.get('amount')
  }),
  [actions.deleteUserTransaction]: (state, {payload: {id}}) => {
    const index = state.userTransactions.findIndex(t => t.get('_id') === id)
    if (index < 0) return state
    const userTransactions = state.userTransactions.remove(index)
    return {
      ...state,
      userTransactions,
      userBalance: userTransactions.reduce((sum, value) => sum + value.get('amount'), 0)
    }
  },
  [actions.setOverview]: (state, {payload: {overview, unknownTransactions}}) => ({
    ...state,
    overview,
    unknownTransactions
  }),
  [actions.setUserTransactions]: (state, {payload: {userTransactions, userName, userMid, userFee}}) => ({
    ...state,
    userTransactions,
    userBalance: userTransactions.reduce((sum, value) => sum + value.get('amount'), 0),
    userName,
    userMid,
    userFee
  }),
  [actions.setUserFee]: (state, {payload: {userFee}}) => ({
    ...state,
    userFee
  }),
  [actions.setUserSelection]: (state, {payload: {userSelection}}) => ({
    ...state,
    userSelection
  }),
  [actions.assignTransaction]: (state, {payload: {transactionId, mid}}) => {
    const index = state.userTransactions.findIndex(t => t.get('_id') === transactionId)
    const userTransactions = (index > -1 && mid === 0) ? state.userTransactions.remove(index) : state.userTransactions
    const userBalance = index > -1 ? userTransactions.reduce((sum, value) => sum + value.get('amount'), 0) : state.userBalance
    return {
      ...state,
      unknownTransactions: state.unknownTransactions.remove(state.unknownTransactions.findIndex(t => t.get('_id') === transactionId)),
      userTransactions,
      userBalance
    }
  },
  default: identity
}, {
  overview: List(),
  userTransactions: List(),
  userBalance: 0,
  userName: '',
  userMid: 0,
  userFee: 0,
  unknownTransactions: List(),
  userSelection: [],
  importState: 'ready'
})

export function * fetchOverviewSaga () {
  const res = yield call(apiService.req, 'accounts', 'getAccountOverview')
  yield put(actions.setOverview({
    overview: fromJS(res.obj.accounts),
    unknownTransactions: fromJS(res.obj.unknownTransactions)
  }))
}

export function * fetchUserTransactionsSaga ({payload: {mid} = {}} = {}) {
  const {userMid} = yield select(state => state.accounts)
  if (userMid && !mid) return
  const res = yield call(apiService.req, 'accounts', 'getTransactions', {mid})
  yield put(actions.setUserTransactions({
    userTransactions: fromJS(res.obj.transactions),
    userName: res.obj.name,
    userMid: mid,
    userFee: res.obj.fee || 0
  }))
}

export function * mapUserSelectionSaga ({payload}) {
  const userSelection = [
    {id: '-1', text: '<Auswählen>'},
    {id: '0', text: 'Ignorieren'}
  ].concat(payload.users.map(u => ({id: u.mid.toString(), text: `${u.profile.prename} ${u.profile.name}`})))
  yield put(actions.setUserSelection({userSelection}))
}

export function * importTransactionsSaga ({payload: {data}}) {
  try {
    const req = {
      method: 'POST',
      uri: window.location.origin + `/api/accounts/pdf`,
      body: data
    }
    const res = yield call(apiService.reqUploadProgress, req, null, 'application/pdf')
    const {overview, totalTransactions, newTransactions} = res.obj
    yield put(actions.setOverview({overview: fromJS(overview.accounts), unknownTransactions: fromJS(overview.unknownTransactions)}))
    window.alert(`${
      totalTransactions === 1 ? '1 Umsatz' : totalTransactions + ' Umsätze'
    } wurden erfolgreich gelesen.\n${
      newTransactions === 1 ? '1 neuer Umsatz' : newTransactions + ' neue Umsätze'
    } wurden importiert.`)
  } catch (err) {
    window.alert(`FEHLER: Umsätze konnten nicht importiert werden!\n(${err.errObj.response.text})`)
  }
}

export function * assignTransactionSaga ({payload}) {
  const res = yield call(apiService.req, 'accounts', 'assignTransaction', payload)
  yield put(actions.setOverview({
    overview: fromJS(res.obj.accounts),
    unknownTransactions: fromJS(res.obj.unknownTransactions)
  }))
}

export function * updateFeeSaga ({payload}) {
  const {mid} = yield select(state => state.common.authenticated)
  yield call(apiService.req, 'users', 'updateUserFee', {mid: payload.mid || mid, payload})
  yield put(actions.setUserFee({userFee: payload.fee}))
}

export function * createTransactionSaga ({payload}) {
  const res = yield call(apiService.req, 'accounts', 'createTransaction', payload)
  yield put(actions.addUserTransaction({transaction: fromJS(res.obj)}))
}

export function * deleteTransactionSaga ({payload}) {
  yield call(apiService.req, 'accounts', 'deleteTransaction', {transactionId: payload.id})
  yield put(actions.deleteUserTransaction({id: payload.id}))
}

export function * saga () {
  yield takeEvery(actions.fetchOverview, fetchOverviewSaga)
  yield takeEvery(actions.fetchUserTransactions, fetchUserTransactionsSaga)
  yield takeEvery(actions.importTransactions, importTransactionsSaga)
  yield takeEvery(commonActionTypes.SET_USER_LIST, mapUserSelectionSaga)
  yield takeEvery(actions.assignTransaction, assignTransactionSaga)
  yield takeEvery(actions.updateFee, updateFeeSaga)
  yield takeEvery(actions.createTransaction, createTransactionSaga)
  yield takeEvery(actions.deleteTransaction, deleteTransactionSaga)
}
