import React, {Component} from 'react'
import {connect} from 'react-redux'
import {common} from 'redux/modules'
import {darkInternalEventsColor, internalEventsColor, fontColor} from 'styles/colors'
import moment from 'moment'
import {List, ListRow, ListCell, RowGroup} from 'components/List'
import {CheckedIcon, UncheckedIcon} from 'components/Icons'

class TicketManagement extends Component {
  componentWillMount () {
    this.props.loadTickets()
  }

  confirmPayment = (eventId, ticketId) => {
    if (window.confirm('Achtung: Hierdurch sendest du eine Bestätigungs-E-Mail über den Zahlungseingang an den Besteller. Kann nicht rückgängig gemacht werden!')) this.props.confirmPayment(eventId, ticketId)
  }

  render () {
    const {tickets} = this.props
    if (!tickets) return null
    return (
      <List>
        {tickets.map(event => <RowGroup key={event._id}>
          <ListRow>
            <ListCell title color={internalEventsColor}>
              {event.type === 'trip'
                ? `${moment(event.date).format('DD. MM. YYYY')} – ${moment(event.endDate).format('DD. MM. YYYY')}`
                : moment(event.date).format('DD. MM. YYYY HH:mm [Uhr]')
              }
            </ListCell>
            <ListCell color={internalEventsColor}>
              {event.title}
            </ListCell>
            <ListCell color={internalEventsColor} />
            <ListCell color={internalEventsColor} align='center'>Verkäufe: {event.tickets.length}</ListCell>
            <ListCell color={internalEventsColor} align='center'>Bezahlt: {event.tickets.filter(ticket => ticket.payed).length}</ListCell>
          </ListRow>
          {event.tickets.map((ticket, index) => <ListRow key={index}>
            <ListCell color={darkInternalEventsColor}>
              {ticket.reference} ({moment(ticket.created).format('DD. MM. YYYY')})
            </ListCell>
            <ListCell color={darkInternalEventsColor}>
              {ticket.name}
            </ListCell>
            <ListCell color={darkInternalEventsColor}>
              {ticket.email}
            </ListCell>
            <ListCell color={darkInternalEventsColor} align='center'>
              {ticket.numRegular} / {ticket.numDiscount} ({((ticket.numRegular || 0) * (event.ticketSale.priceRegular || 0) + (ticket.numDiscount || 0) * (event.ticketSale.priceDiscount || 0)).toFixed(2)} €)
            </ListCell>
            <ListCell color={darkInternalEventsColor} align='center'>
              {ticket.payed
                ? <CheckedIcon
                  size='1rem'
                  fill={fontColor}
                  label='bezahlt'
                >bezahlt</CheckedIcon>
                : <UncheckedIcon
                  size='1rem'
                  fill={fontColor}
                  onClick={() => this.confirmPayment(event._id, ticket._id)}
                >bezahlt</UncheckedIcon>
              }
            </ListCell>
          </ListRow>)}
        </RowGroup>)}
      </List>
    )
  }
}
export default connect(
  state => ({
    tickets: state.common.tickets
  }),
  dispatch => ({
    loadTickets: () => dispatch(common.actions.loadTickets()),
    confirmPayment: (eventId, ticketId) => dispatch(common.actions.confirmPayment({eventId, ticketId}))
  })
)(TicketManagement)
