import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {common} from 'redux/modules'
import PageHeader from 'components/PageHeader'
import Page from 'components/SimplePage'
import {internalChoirColor, white} from 'styles/colors'
import styled from 'styled-components'
import {USER_MANAGER, hasPermission} from 'lib/roles'
import groups from 'lib/groups'
import {EditIcon, KeyIcon, UserIcon} from 'components/Icons'
import groupBy from 'lodash/groupBy'

const List = styled.div`
  display: table;
  width: 100%;
`

const ListRow = styled.div`
  display: table-row;
  @media (max-width: 768px) {
    display: block;
    text-align: center;
  }
  > * {
    display: table-cell;
    padding: 0.5rem;
    vertical-align: middle;
    @media (max-width: 768px) {
      display: block;
    }
    > a {
      margin: 0 0.5rem;
    }
  }
`

const RowGroup = styled.div`
  display: table-row-group;
`

const ListHeader = styled.div`
  display: table-row;
  height: 2.5rem;
  > div { 
    position: absolute;
    margin: 1rem 0 0 2rem;
    font-variant: small-caps;
    font-size: 1.2rem;
    color: ${internalChoirColor};
  }
`

const ProfileImage = styled.img`
  height: 3rem;
  @media (max-width: 768px) {
    height: 8rem;
  }
`

class ChoirList extends Component {
  componentWillMount () {
    if (!this.props.users) {
      this.props.loadUsers()
    }
  }

  render () {
    if (!this.props.users) return null
    const isManager = hasPermission([USER_MANAGER], this.props.permissions)
    return (
      <Page marginRight>
        <PageHeader color={internalChoirColor}>Chorliste</PageHeader>
        <List>
          {groups.map(group =>
            <RowGroup key={group.id}>
              <ListHeader><div>{group.text}</div></ListHeader>
              {(this.props.users[group.id] || []).map(user => {
                const profile = user.profile || {}
                return <ListRow key={user._id}>
                  <div>{user.image ? <ProfileImage src={`/api/pub/user_image/${user._id}`} /> : <UserIcon color={white} />}</div>
                  <div>{profile.prename}<br />{profile.name}</div>
                  <div>{profile.street}<br />{profile.zip} {profile.city}</div>
                  <div>{profile.phone}{profile.phone && <br />}{profile.mobile}</div>
                  <div>{user.email}</div>
                  {isManager && <div>
                    <Link to={`/internal/user/profile/${user._id}`}><EditIcon color={white} size='1.3rem' /></Link>
                    <Link to={`/internal/user/account/${user._id}`}><KeyIcon color={white} size='1.3rem' /></Link>
                  </div>}
                </ListRow>
              })}
            </RowGroup>
          )}
        </List>
      </Page>
    )
  }
}

export default connect(
  state => ({
    users: state.common.users && groupBy(state.common.users, 'group'),
    permissions: state.common.authenticated.roles
  }),
  dispatch => ({
    loadUsers: () => dispatch(common.actions.loadUsers())
  })
)(ChoirList)
