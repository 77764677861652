export const NAME = 'common'
export const AUTH = `${NAME}/AUTH`
export const LOGIN = `${NAME}/LOGIN`
export const LOGOUT = `${NAME}/LOGOUT`
export const ADD_EVENT = `${NAME}/ADD_EVENT`
export const ADD_REPERTOIRE = `${NAME}/ADD_REPERTOIRE`
export const CONFIRM_PAYMENT = `${NAME}/CONFIRM_PAYMENT`
export const INIT = `${NAME}/INIT`
export const OPEN_MENU = `${NAME}/OPEN_MENU`
export const REMOVE_REPERTOIRE = `${NAME}/REMOVE_REPERTOIRE`
export const SET_CURRENT_LOCATION = `${NAME}/SET_CURRENT_LOCATION`
export const SET_MENU = `${NAME}/SET_MENU`
export const SET_APPLICATION_ERROR = `${NAME}/SET_APPLICATION_ERROR`
export const SET_EVENTS = `${NAME}/SET_EVENTS`
export const SET_UNDECIDED_EVENTS = `${NAME}/SET_UNDECIDED_EVENTS`
export const SET_TICKETS = `${NAME}/SET_EVENTS`
export const SET_UPLOAD_PROGRESS = `${NAME}/SET_UPLOAD_PROGRESS`
export const SET_REPERTOIRE_LIST = `${NAME}/SET_REPERTOIRE_LIST`
export const SET_USER_LIST = `${NAME}/SET_USER_LIST`
export const SET_USER_IMAGES = `${NAME}/SET_USER_IMAGES`
export const TOGGLE_SHOW_ARCHIVED = `${NAME}/TOGGLE_SHOW_ARCHIVED`
export const TOGGLE_ARCHIVE = `${NAME}/TOGGLE_ARCHIVE`
export const TOGGLE_MENU = `${NAME}/TOGGLE_MENU`
export const UPDATE_EVENT = `${NAME}/UPDATE_EVENT`
export const UPDATE_EVENT_ENTRY = `${NAME}/UPDATE_EVENT_ENTRY`
export const UPDATE_REPERTOIRE = `${NAME}/UPDATE_REPERTOIRE`
export const UPDATE_USER = `${NAME}/UPDATE_USER`
export const DELETE_USER = `${NAME}/DELETE_USER`
export const FOCUS = `${NAME}/FOCUS`
export const BLUR = `${NAME}/BLUR`
