import React, {Component} from 'react'
import styled from 'styled-components'
import Color from 'color'
import {fontColor, red, gray} from 'styles/colors'
import omit from 'lodash/omit'
const inputWarnColor = Color(red).fade(0.5).string()

const Container = styled.div`
  display: inline-block;
  margin: 0.2rem 0rem;
  width: 12rem;
  > :last-child {
    color: ${props => props.disabled ? gray : fontColor};
    outline: none;
    line-height: 2rem;
    height: 2rem;
    border: none;
    margin: 0;
    ${props =>
    props.plain
      ? `border-bottom: 1px solid ${props.disabled ? 'transparent' : fontColor};
           font-size: 1rem;
           padding: 0 0.3rem;
           width: 100%;`
      : `border: 1px solid ${props.disabled ? gray : fontColor};
           padding: 0rem 0.7rem;
           border-radius: 0.5rem;
           box-sizing: border-box;
           width: 100%;`}
    text-align: ${props => props.align || 'left'};
    background-color: ${props => props.warn ? inputWarnColor : props.bgColor || 'transparent'};
  }
`

const RegularInput = styled.input`
  outline: none;
  border: none;
  width: 100%;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  padding: 0 0.2rem;
  color: ${fontColor};
`
const Label = styled.div`
  border: none;
  width: 100%;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  padding: 0 0.2rem;
  color: ${fontColor};
`
const FakeInput = styled.input`
  display: none;
`
const asNumber = str => str !== '' ? parseFloat(str.replace(/,/g, '.')) : null
const formatNumber = (num, precision = 0) => num != null ? num.toFixed(precision).replace('.', ',') : ''
const checkInput = (str, precision = 0) => !str || str.match(new RegExp('^\\d+' + (precision > 0 ? `\\,?\\d{0,${precision}}` : '') + '$'))

const setCursor = (el, start, end) => {
  if (el.setSelectionRange !== undefined) {
    el.setSelectionRange(start, end)
  } else {
    el.value = el.value + ''
  }
}

export default class NumberInput extends Component {
  constructor (props) {
    super(props)
    this.state = {
      focus: false,
      value: formatNumber(props.value, props.precision)
    }
  }

  componentWillReceiveProps (newProps) {
    this.setState({value: formatNumber(newProps.value, newProps.precision)})
  }

  onBlur = () => {
    if (this.state.dirty) this.props.onChange(asNumber(this.state.value))
    this.setState({focus: false, dirty: false})
  }

  renderInput = () => {
    const {warn, placeholder, precision, unit} = this.props
    if (this.state.focus) {
      return <RegularInput
        warn={warn}
        placeholder={placeholder}
        label
        autoFocus
        onFocus={e => setCursor(e.target, 0, e.target.value.length)}
        value={this.state.value}
        onChange={e => checkInput(e.target.value, precision) && this.setState({value: e.target.value, dirty: true})}
        onBlur={this.onBlur}
      />
    }
    return <Label onClick={() => this.setState({focus: true})}>{placeholder && placeholder + ': ' }{formatNumber(this.props.value, precision) || '--'} {unit || ''}</Label>
  }

  render () {
    const {noAutoFill} = this.props
    return <Container {...omit(this.props, ['onChange', 'onFocus', 'onBlur'])}>
      {noAutoFill && <FakeInput size='0' {...omit(this.props, ['plain', 'align'])} />}
      {this.renderInput()}
    </Container>
  }
}
