import styled from 'styled-components'

export const LeftCol = styled.div`
  flex: 1;
  text-align: justify;
  @media (min-width: 769px) {
    padding: 0 2rem 0 3rem;
  }
  font-size: 0.85rem;
  line-height: 1.2rem;
  > p {
    margin-bottom: 0.5rem;
  }
`

export const RightCol = styled.div`
  flex: 1.2;
`

export default styled.div`
  @media (min-width: 769px) {
    flex-direction: row;
    display: flex;
  }
`
