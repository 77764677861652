import identity from 'lodash/identity'
import {createActions, handleActions} from 'redux-actions'
import {takeEvery, put} from 'redux-saga/effects'
import {Map} from 'immutable'
import {actions as chatActions} from './chat'
import pkg from '../../../package.json'

export const actions = createActions({
  dialog: {
    SET_REQUEST_ERROR: identity,
    SET_DIALOG: identity,
    DISMISS: identity
  }
}).dialog

export default handleActions({
  [actions.setDialog]: (state, {payload}) => ({
    data: Map({
      message: payload.message,
      reload: payload.reload || false,
      dismiss: !!payload.dismiss
    })
  }),
  [actions.setRequestError]: (state, {payload}) => ({
    data: Map({
      message: `${payload.status}: ${payload.message}`,
      reload: true,
      dismiss: true
    })
  }),
  [actions.dismiss]: (state) => ({
    data: null,
    bup: state.data
  }),
  default: identity
}, {})

export function * checkVersionSaga ({payload}) {
  if (pkg.version !== payload.version) {
    yield put(actions.setDialog({
      message: 'Ein Update ist vorhanden. Bitte die Seite neu laden...',
      dismiss: false,
      reload: true
    }))
  }
}

export function * saga () {
  yield takeEvery(chatActions.versionReceived, checkVersionSaga)
}
