import React from 'react'
import styled from 'styled-components'
import pick from 'lodash/pick'
import ChatHistory from './ChatHistory'
import ChatInput from './ChatInput'

const Container = styled.div`
  flex: 1;
    max-width: 40rem;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    > *:first-child {
      flex: 1;
  }
`

export default (props) => <Container>
  <ChatHistory {...pick(props, ['messages', 'hasMore', 'loadMore', 'historyFetch', 'users', 'usersOnline', 'ownUserId', 'accessToken'])} />
  <ChatInput {...pick(props, ['onSubmit', 'onChatImageUpload', 'mentions', 'connected'])} />
</Container>
