import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import {fontColor, internalChoirColor, gray, blue} from 'styles/colors'
import get from 'lodash/get'
import moment from 'moment'
const bender = require('img/bender.gif')
const Container = styled.div`
  overflow-y: scroll;
`
const Item = styled.div`
  margin-bottom: 0.8rem;
`
const Message = styled.div`
  display: flex;
  align-items: flex-end;
`
const Avatar = styled.div`
  text-align: center;
  width: 3rem;
  height: 3rem;
  border: 2px solid ${props => props.bot ? gray : (props.own ? fontColor : internalChoirColor)};
  border-radius: 5rem;
  overflow: hidden;
  margin-right: 1rem;
  > img {
    width: 3rem;
  }
`
const OnlineIndicator = styled.div`
  display: inline-blocK;
  height: 8px;
  width: 8px;
  background-color: green;
  border-radius: 5px;
  margin-right: 2px;
`
const Nickname = styled.div`
  width: 3rem;
  text-align: center;
  font-size: 0.8rem;
  font-variant: small-caps;
  color: ${props => props.bot ? gray : (props.own ? fontColor : internalChoirColor)};
`
const MessageBubble = styled.div`
  background-color: ${fontColor};
  border-radius: 1rem 1rem 1rem 0;
  padding: 0.1rem 0.6rem;
  color: black;
  box-shadow: 3px 3px 7px rgba(255, 255, 255, 0.4);
  margin-bottom: 0.8rem;
  max-width: calc(100% - 6rem);
  white-space: pre-wrap;
`
const StatusText = styled.div`
  text-align: right;
  padding-left: 2rem;
  font-size: 0.6rem;
  line-height: 0.7rem;
  color: ${gray};
`
const ChatImage = styled.img`
  padding-top: 0.5rem;
  width: 100%;
`

const LoadMore = styled.div`
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 15px;
`

const linkRegex = /(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*/ig

const Link = styled.a`
  color: ${blue};
  text-decoration: underline;
`

const MessageContent = styled.div`
  word-break: break-word;
`

class ChatHistory extends Component {
  scrollToBottom = () => {
    if (this.props.historyFetch) return
    const node = ReactDOM.findDOMNode(this.refs['container'])
    if (node) node.scrollTop = node.scrollHeight
  }

  componentDidMount () {
    this.scrollToBottom()
    this.updateMoments = setInterval(() => this.forceUpdate(), 30000)
    this.messageCount = 0
  }

  componentWillUnmount () {
    clearInterval(this.updateMoments)
  }

  componentDidUpdate () {
    if (this.messageCount !== this.props.messages.size) this.scrollToBottom()
    this.messageCount = this.props.messages.size
  }

  autoCreateLinks = msg => {
    const elements = []
    let currentPos = 0
    let match = linkRegex.exec(msg)
    while (match !== null) {
      elements.push(msg.substring(currentPos, match.index))
      elements.push(<Link key={match[0]} href={match[0]}>{match[0]}</Link>)
      currentPos += match.index + match[0].length
      match = linkRegex.exec(msg)
    }
    if (currentPos < msg.length) elements.push(msg.substring(currentPos))
    return elements
  }

  render () {
    const {messages, hasMore, ownUserId, users, usersOnline, accessToken, loadMore} = this.props
    return <Container ref='container'>
      {hasMore && <LoadMore onClick={loadMore}>ältere Nachrichten laden</LoadMore>}
      {messages.map((msg) => {
        const from = msg.get('from')
        return <Item key={msg.get('_id')}>
          <Message>
            <Avatar own={from === ownUserId} bot={from === 'bot'} >
              {from === 'bot'
                ? <img src={bender} alt='bender' />
                : get(users, [from, 'image']) && <img src={`/api/pub/user_image/${from}`} alt='?' />
              }
            </Avatar>
            <MessageBubble>
              {msg.get('type') === 'image'
                ? <ChatImage src={`/files/chat/${msg.get('path')}?access=${accessToken}`} alt={msg.get('name')} onLoad={this.scrollToBottom} />
                : <MessageContent>{this.autoCreateLinks(msg.get('message'))}</MessageContent>
              }
              <StatusText>{moment(msg.get('created')).isAfter(moment()) ? 'gerade eben' : moment(msg.get('created')).fromNow()}</StatusText>
            </MessageBubble>
          </Message>
          <Nickname own={from === ownUserId} bot={from === 'bot'}>
            {usersOnline.includes(from) && <OnlineIndicator />}
            {get(users, [from, 'chatName']) || from}
          </Nickname>
        </Item>
      })}
    </Container>
  }
}

export default ChatHistory
