import React, {Component} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import {common} from 'redux/modules'
import PageHeader from 'components/PageHeader'
import {ensembleColor} from 'styles/colors'
import Page from 'components/SimplePage'
import Event from 'components/EventAnnouncement'

const InfoText = styled.div`
  font-style: italic;
`

class Events extends Component {
  componentWillMount () {
    if (!this.props.publicEvents) this.props.getPublicEvents()
  }

  render () {
    return <Page>
      <PageHeader color={ensembleColor}>Unsere nächsten Konzerte</PageHeader>
      {this.props.publicEvents && !this.props.publicEvents.length && <InfoText>neue Konzerttermine folgen</InfoText>}
      {this.props.publicEvents && this.props.publicEvents.map(event => <Event key={event._id} event={event} />)}
    </Page>
  }
}

export default connect(
  state => ({
    publicEvents: state.common.publicEvents
  }),
  dispatch => ({
    getPublicEvents: () => dispatch(common.actions.getPublicEvents())
  })
)(Events)
