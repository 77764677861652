import {eventChannel, buffers} from 'redux-saga'

export const messageChannel = socket => eventChannel(emit => {
  socket.onmessage = e => {
    let message
    try {
      message = JSON.parse(e.data)
    } catch (err) {
      message = {type: 'ERROR', message: 'invalid json data', payload: e.data}
    }
    emit(message)
  }
  return () => {
    delete socket.onmessage
  }
}, buffers.expanding(10))

export const waitForConnection = socket => new Promise(resolve => {
  socket.onopen = () => {
    resolve()
    delete socket.onopen
  }
})

export const waitForClose = socket => new Promise(resolve => {
  socket.onclose = () => {
    resolve()
    delete socket.onclose
  }
})
