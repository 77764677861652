import React from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'
import Page from 'components/SimplePage'
import PageHeader from 'components/PageHeader'
import {internalChoirColor, darkRed, darkGreen, green, darkGray} from 'styles/colors'
import {List, ListRow, ListCell, ListCellLink, ListHeader, RowGroup} from 'components/List'
import {MobileOnly} from 'components/MediaSwitch'
import Dropzone from 'react-dropzone'
import Button from 'components/Button'
import moment from 'moment'
import {actions as accountsActions} from 'redux/ducks/accounts'
import {RIESelect} from 'riek'

const HeadArea = styled.div`
  display: flex;
  justify-content: space-between;
`

const Controls = styled(Dropzone)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > * {
    margin-right: 1rem;
  }
`

const accountColor = balance => {
  if (balance < 0) return darkRed
  if (balance > 0) return green
  return darkGreen
}

class AccountOverview extends React.PureComponent {
  componentWillMount () {
    this.props.fetchOverview()
  }

  importTransactions = acceptedFiles => {
    if (acceptedFiles[0]) {
      const file = acceptedFiles[0]
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = (e) => {
        this.props.importTransactions(e.currentTarget.result)
      }
    }
  }

  onAssignTransaction = (tid, mid) => {
    this.props.assignTransaction(tid, mid)
  }

  render () {
    const {accounts, unknownTransactions, userSelection} = this.props
    if (!accounts) return null
    const lastPayment = accounts.reduce((val, acc) => val && val.get('lastPayment') > acc ? val.get('lastPayment') : acc, '')
    const balance = accounts.reduce((acc, val) => acc + val.get('balance', 0), 0)
    return <Page marginRight>
      <HeadArea>
        <PageHeader color={internalChoirColor}>Mitgliedskonten | Saldo {balance.toFixed(2)} € (Stand {moment(lastPayment).format('DD.MM.YYYY')})</PageHeader>
        <Controls accept='application/pdf' multiple={false} onDrop={this.importTransactions}>
          <Button bgColor={darkGray}>Kontoauszug hochladen</Button>
        </Controls>
      </HeadArea>
      {unknownTransactions.size > 0 &&
        <div>
          <PageHeader color={internalChoirColor}>Nicht-zugeordnete Einzahlungen</PageHeader>
          <List>
            <ListHeader>
              <div>Datum</div>
              <div>Name</div>
              <div>Verwendungszweck</div>
              <div>Betrag</div>
              <div>Zuordnen</div>
            </ListHeader>
            <RowGroup>
              {unknownTransactions.map(transaction => <ListRow key={transaction.get('_id')}>
                <ListCell color={darkGray}><MobileOnly>Datum:</MobileOnly>{moment(transaction.get('date')).format('DD.MM.YYYY')}</ListCell>
                <ListCell color={darkGray}><MobileOnly>Name:</MobileOnly>{transaction.get('name')}</ListCell>
                <ListCell color={darkGray}><MobileOnly>Verwendungszweck:</MobileOnly>{transaction.get('text')}</ListCell>
                <ListCell color={darkGray} nowrap align='center'><MobileOnly>Betrag:</MobileOnly>{transaction.get('amount').toFixed(2)} €</ListCell>
                <ListCell color={darkGray} align='center'>
                  {userSelection.length > 0 &&
                    <RIESelect
                      options={userSelection}
                      propName='user'
                      change={({user}) => this.onAssignTransaction(transaction.get('_id'), parseInt(user.id, 10))}
                      value={userSelection[0]} />
                  }
                </ListCell>
              </ListRow>)}
            </RowGroup>
          </List>
        </div>
      }
      <List>
        <ListHeader>
          <div>Name</div>
          <div>Beitrags-ID</div>
          <div>Kontostand</div>
          <div>Letzte Gutschrift</div>
          <div>Beitragssatz</div>
        </ListHeader>
        <RowGroup>
          {accounts.map(account => {
            const color = accountColor(account.get('balance', 0))
            return <ListRow key={account.get('_id')}>
              <ListCellLink to={`/internal/user/contribution_account/${account.get('mid')}`} color={color}><MobileOnly>Name:</MobileOnly>{account.get('prename')} {account.get('name')}</ListCellLink>
              <ListCellLink to={`/internal/user/contribution_account/${account.get('mid')}`} color={color} align='center'><MobileOnly>Beitrags-ID:</MobileOnly>{account.get('code')}</ListCellLink>
              <ListCellLink to={`/internal/user/contribution_account/${account.get('mid')}`} color={color} align='center'><MobileOnly>Kontostand:</MobileOnly>{account.get('balance', 0).toFixed(2)} €</ListCellLink>
              <ListCellLink to={`/internal/user/contribution_account/${account.get('mid')}`} color={color} align='center'>
                <MobileOnly>Letzte Einzahlung:</MobileOnly>
                {account.get('lastPayment') ? moment(account.get('lastPayment')).format('DD.MM.YYYY') : '---'}
              </ListCellLink>
              <ListCellLink to={`/internal/user/contribution_account/${account.get('mid')}`} color={color} align='center'><MobileOnly>Beitragssatz:</MobileOnly>{account.get('fee', 0).toFixed(2)} € mntl.</ListCellLink>
            </ListRow>
          })}
        </RowGroup>
      </List>
    </Page>
  }
}

export default connect(
  state => ({
    accounts: state.accounts.overview,
    unknownTransactions: state.accounts.unknownTransactions,
    userSelection: state.accounts.userSelection
  }),
  dispatch => ({
    fetchOverview: () => dispatch(accountsActions.fetchOverview()),
    importTransactions: data => dispatch(accountsActions.importTransactions({ data })),
    assignTransaction: (transactionId, mid) => dispatch(accountsActions.assignTransaction({ transactionId, mid }))
  })
)(AccountOverview)
