const includes = require('lodash/includes')
const difference = require('lodash/difference')

export const ADMIN = 'ADMIN'
export const AUTH_USER = 'AUTH_USER'
export const USER_MANAGER = 'USER_MANAGER'
export const EVENT_MANAGER = 'EVENT_MANAGER'
export const EVENT_SUPERVISOR = 'EVENT_SUPERVISOR'
export const FINANCE_MANAGER = 'FINANCE_MANAGER'
export const TICKET_MANAGER = 'TICKET_MANAGER'
export const REPERTOIRE_UPLOADER = 'REPERTOIRE_UPLOADER'
export const hasPermission = (requiredRoles, roles) => includes(roles, 'ADMIN') || !difference(requiredRoles, roles).length
