import React from 'react'
import Page, {LeftCol, RightCol} from 'components/TwoColPage'
import SubHeader from 'components/PageHeader'
import {managementColor} from 'styles/colors'
import styled from 'styled-components'

const ContentRight = styled.div`
  text-align: center;
  font-size: 0.9rem;
`

export default () =>
  <Page>
    <LeftCol>
      <p>
        Die Moving Colours sind ein eingetragener Verein aus Berlin. Bei allgemeinen Anfragen rund um Konzerte,
        Workshops oder andere Events steht Ihnen das Vorstandsteam gerne Rede und Antwort. Bitte nutzen Sie
        hierfür unser <a href='/contact/board'>Kontaktformular.</a>
      </p>
      <SubHeader color={managementColor}>Dabei sein ist alles?</SubHeader>
      <p>
        Wer Interesse an einer Mitgliedschaft im Ensemble hat, wendet sich bitte über das Kontaktformular direkt an den
        Chor.
      </p>
      <SubHeader color={managementColor}>Rent a Choir</SubHeader>
      <p>
        Moving Colours untermalen Firmenjubiläen, bereichern „runde“ Geburtstage oder bilden den Klangteppich für den
        schönsten Heiratsantrag – „con cuore“ – a cappella.
        Gelegenheiten gibt es viele, eine individuelle Gestaltung des Abends ist möglich.<br />
        Und wenn es ein bisschen mehr sein soll:<br />
        Auf Wunsch gibt es Moving Colours auch mit Instrumentalbegleitung.
        Individuelle Repertoirevorstellungen können nach entsprechender Vorlaufzeit sehr gerne berücksichtigt werden.
      </p>
      <SubHeader color={managementColor}>Sponsorship</SubHeader>
      <p>
        Sponsoring ist aktive Kulturförderung und per se schon lobenswert!
        Moving Colours bieten individuelle Pakete für Sponsoren.
        Setzen Sie sich mit uns in Verbindung, wir finden mit Sicherheit das passende
        Konzept.
      </p>
    </LeftCol>
    <RightCol>
      <ContentRight />
    </RightCol>
  </Page>
