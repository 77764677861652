import React from 'react'
import styled from 'styled-components'
import {fontColor} from 'styles/colors'
import includes from 'lodash/includes'
import filter from 'lodash/filter'
import map from 'lodash/map'

const Container = styled.div`
  padding: 0 0.2rem;
  display: inline-flex;
  border-radius: 1rem;
  border: 1px solid ${fontColor};
  overflow: hidden;
`

const Element = styled.div`
  padding: 0.1rem 0.3rem;
  cursor: pointer;
  border-left: 1px solid ${fontColor};
  &:first-child {
    border: none;
  }
  color: ${props => (props.active && props.activeColor) || 'inherit'};
  background-color: ${props => (props.active && props.activeBackgroundColor) || 'inherit'};
`

export default ({options, selection, activeColor, activeBackgroundColor, onChange}) => {
  const onClickOption = clickedOption => {
    const newSelection = map(filter(options, option => {
      const selected = includes(selection, option.key)
      return (option.key !== clickedOption.key) ? selected : !selected
    }), 'key')
    if (onChange) onChange(newSelection)
  }
  return (
    <Container>
      {options.map(option =>
        <Element
          key={option.key}
          activeBackgroundColor={activeBackgroundColor}
          activeColor={activeColor}
          active={includes(selection, option.key)}
          onClick={() => onClickOption(option)}
        >
          {option.value}
        </Element>)
      }
    </Container>
  )
}
