import React, {Component} from 'react'
import Page from 'components/SimplePage'
import PageHeader from 'components/PageHeader'
import EventList from './EventList'
import {connect} from 'react-redux'
import {common} from 'redux/modules'
import reject from 'lodash/reject'
import {internalEventsColor} from 'styles/colors'

class ConcertList extends Component {
  componentWillMount () {
    this.props.loadEvents()
  }

  render () {
    return <Page marginRight>
      <PageHeader color={internalEventsColor}>Chorfahrten / Ausflüge</PageHeader>
      <EventList events={this.props.events} type='trip' />
    </Page>
  }
}

export default connect(
  state => ({
    events: state.common.events && reject(state.common.events, e => e.deleted || e.type !== 'trip')
  }),
  dispatch => ({
    loadEvents: () => dispatch(common.actions.loadEvents())
  })
)(ConcertList)
