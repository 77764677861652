import {common} from './modules'
import {combineReducers} from 'redux'
import {all} from 'redux-saga/effects'
import accounts, {saga as accountsSaga} from 'redux/ducks/accounts'
import chat, {saga as chatSaga} from 'redux/ducks/chat'
import dialog, {saga as dialogSaga} from 'redux/ducks/dialog'

export const reducer = combineReducers({
  [common.NAME]: common.reducer,
  accounts,
  chat,
  dialog
})

export const saga = function * () {
  try {
    yield all([
      accountsSaga(),
      chatSaga(),
      dialogSaga()
    ])
  } catch (err) {
    if (err.sagaStack) err.stack = err.sagaStack
  }
}
