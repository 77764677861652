import styled from 'styled-components'

export const MobileOnly = styled.div`
  @media (min-width: 769px) {
    display: none !important;
  }
  flex-shrink: 0;
`

export const DesktopOnly = styled.div`
  @media (max-width: 768px) {
    display: none !important;
  }
`
