import React, {Component} from 'react'
import styled from 'styled-components'
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {common} from 'redux/modules'
import {red, green} from 'styles/colors'

import Page from 'components/CenteredPage'
import Button from 'components/Button'
import TextInput from 'components/TextInput'
import apiService from 'lib/apiService'
import GoogleLoginButton from 'components/GoogleLoginButton'

const Error = styled.div`
  color: ${red};
`

const ResetPasswordLink = styled.div`
  font-size: 0.7rem;
  text-decoration: underline;
  color: ${green};
  cursor: pointer;
`

class Login extends Component {
  state = {
    username: '',
    password: '',
    error: false,
    resetPassword: false
  }

  login = () => {
    const {resetPassword, username, password} = this.state
    if (resetPassword) {
      return apiService.resetPassword(username)
        .then(() => {
          this.setState({
            resetPassword: false,
            password: '',
            error: 'Wir haben deine Anfrage erhalten. Weitere Anweisungen erhältst du per Mail.'
          })
        })
        .catch(() => {
          this.setState({
            resetPassword: false,
            error: 'Unbekannter Fehler.'
          })
        })
    }
    apiService.login(username, password)
      .then(this.props.setInternalMenu)
      .catch(err => {
        if (err.status === 401) {
          this.setState({
            error: 'Falsche E-Mail oder Passwort',
            username: '',
            password: ''
          })
        } else {
          this.setState({
            error: 'Unbekannter Fehler.'
          })
        }
      })
  }

  googleLogin = (resp) => {
    apiService.googleLogin(resp.tokenId)
      .then(this.props.setInternalMenu)
      .catch(err => {
        if (err.status === 401) {
          this.setState({
            error: 'Anmeldung fehlgeschlagen'
          })
        } else {
          this.setState({
            error: 'Unbekannter Fehler.'
          })
        }
      })
  }

  googleError = (err) => {
    console.error(err)
    if (err.error === 'popup_closed_by_user') return
    this.setState({
      error: 'Unbekannter Fehler.'
    })
  }

  render () {
    if (this.props.authenticated) return <Redirect to='/internal' />
    const {resetPassword, username, password, error} = this.state
    return (
      <Page>
        {resetPassword && <p>
          Kein Problem. Du kannst ein neues Passwort anfordern. Bitte gib deine hinterlegte E-Mail Adresse ein und klicke auf 'Abschicken'.
        </p>}
        <TextInput
          align='center'
          placeholder='E-Mail'
          autoCorrect='off'
          autoCapitalize='off'
          value={username}
          onChange={e => this.setState({username: e.target.value, error: false})}
        />
        {!resetPassword &&
          <TextInput
            align='center'
            placeholder='Passwort'
            type='password'
            autoCorrect='off'
            autoCapitalize='off'
            value={password}
            onChange={e => this.setState({password: e.target.value, error: false})}
          />
        }
        {!resetPassword && <ResetPasswordLink onClick={() => this.setState({resetPassword: true})}>Passwort vergessen?</ResetPasswordLink>}
        <Button onClick={this.login} disabled={!username || (!resetPassword && !password)}>{resetPassword ? 'Abschicken' : 'Anmelden'}</Button>
        {error && <Error>{this.state.error}</Error>}
        {!resetPassword && <GoogleLoginButton
          clientId='920054843799-lbi030fqq4t162q9vteti3i63i0ciehk.apps.googleusercontent.com'
          scope='email'
          style={{marginTop: '1.5rem'}}
          onSuccess={this.googleLogin}
          onFailure={this.googleError} />
        }
      </Page>
    )
  }
}

export default connect(
  state => ({
    authenticated: state.common.authenticated
  }),
  dispatch => ({
    login: (username, password) => dispatch(common.actions.login(username, password)),
    setInternalMenu: () => dispatch(common.actions.setMenu(true))
  })
)(Login)
